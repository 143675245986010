<template>
	<teleport to="body">
		<div class="modal" :id="`config-printers-${type}`" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md modal-dialog-centered">
				<div class="modal-content shadow-3">
					<div class="modal-header d-block">
						<h4 class="modal-title">Configuración de caja</h4>
						<small class="d-block text-muted">Seleccioná el tipo de impresión</small>
					</div>
					<div class="modal-body py-0">
						<config-printers-list :type="type" :method="form.method" :value="form.value" :status="status" :printers="printers" :printpoints="printpoints" :printpoint="printpoint" @select="select" />
					</div>
					<div class="modal-footer d-flex justify-content-between">
						<div>
							<button type="button" class="btn btn-sm btn-danger" :disabled="submitting" @click="deletePrinter" v-if="form.method">Eliminar</button>
						</div>
						<div class="hstack gap-3">
							<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal" :disabled="submitting">Cancelar</button>
							<button type="button" class="btn btn-sm btn-primary" :disabled="submitting" @click="submit">Guardar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, reactive, computed, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';

	export default {
		props: {
			status: String,
			printers: Array,
			printpoints: Array
		},
		setup() {
			const type = 'cashbox';
			const { current_store } = composableStore();
			const { submitting, catchError } = composableForm();
			const printpoint = computed(() => store.getters['config/printpoint'](current_store.value.id));
			const printers_store = computed(() => store.getters['config/printers'](current_store.value.id));

			const modalEdit = ref(null);
			onMounted(() => {
				modalEdit.value = new Modal(document.getElementById(`config-printers-${type}`));

				document.getElementById(`config-printers-${type}`).addEventListener('show.bs.modal', () => {
					if(type in printers_store.value) {
						form.method = printers_store.value.cashbox.method;
						form.value = printers_store.value.cashbox.value;
					}else{
						form.method = null;
						form.value = null;
					}
				});
			});

			const form = reactive({
				method: null,
				value: null
			});

			const select = (method, value) => {
				form.method = method;
				form.value = value;
			}

			const submit = async () => {
				submitting.value = true;
				await store.dispatch('config/setPrinter', { store_id: current_store.value.id, type: type, config: form }).then(() => {
					modalEdit.value.hide();
				}).catch(catchError);
				submitting.value = false;
			}

			const deletePrinter = async () => {
				submitting.value = true;
				await store.dispatch('config/deletePrinter', { store_id: current_store.value.id, type: type }).then(() => {
					modalEdit.value.hide();
				}).catch(catchError);
				submitting.value = false;
			}

			return { type, form, select, printpoint, submitting, submit, deletePrinter, current_store };
		}
	}
</script>