<template>
	<aside class="h-100 overflow-y-xl-auto p-6 vstack gap-4">
		<div>
			<div class="d-flex align-items-center mb-3">
				<h5 class="me-auto">Estado de la aplicación</h5>
			</div>
			<!-- Image -->
			<div class="mb-5">
				<img class="rounded" :src="require(`@/assets/img/banners/app-status.jpg`)" alt="app">
			</div>
			<!-- Title -->
			<h6 class="text-muted fw-semibold mb-3">Novedades</h6>
			<!-- Text -->
			<p class="text-sm mb-5">
				Se finalizó la funcionalidad de toma de pedidos desde la aplicación, haciéndola mucho más veloz y amigable. La aplicación está pensadad para ser utilizada tanto en PCs como en teléfonos móbiles.
			</p>
		</div>
		<!-- Activity -->
		<div>
			<!-- Title -->
			<h6 class="text-muted fw-semibold mb-5">Progreso del desarrollo</h6>
			<!-- List group -->
			<div class="list-group list-group-flush list-group-borderless">
				<div class="list-group-item px-2 py-0">
					<div class="border-start">
						<div class="d-flex ms-n2 pb-5">
							<div class="flex-none me-3">
								<div class="icon icon-shape text-sm w-rem-4 h-rem-4 bg-success rounded-circle"></div>
							</div>
							<div>
								<div class="h6 fw-semibold lh-1 text-heading d-inline-block mb-1">Mapas y códigos de acceso</div>
								<div class="text-xs text-muted">Terminado</div>
							</div>
						</div>
					</div>
				</div>
				<div class="list-group-item px-2 py-0">
					<div class="border-start">
						<div class="d-flex ms-n2 pb-5">
							<div class="flex-none me-3">
								<div class="icon icon-shape text-sm w-rem-4 h-rem-4 bg-success rounded-circle"></div>
							</div>
							<div>
								<div class="h6 fw-semibold lh-1 text-heading d-inline-block mb-1">Toma de pedidos</div>
								<div class="text-xs text-muted">Terminado</div>
							</div>
						</div>
					</div>
				</div>
				<div class="list-group-item px-2 py-0">
					<div class="border-start">
						<div class="d-flex ms-n2 pb-5">
							<div class="flex-none me-3">
								<div class="icon icon-shape text-sm w-rem-4 h-rem-4 bg-success rounded-circle"></div>
							</div>
							<div>
								<div class="h6 fw-semibold lh-1 text-heading d-inline-block mb-1">Listado de pedidos</div>
								<div class="text-xs text-muted">Terminado</div>
							</div>
						</div>
					</div>
				</div>
				<div class="list-group-item px-2 py-0">
					<div class="border-start">
						<div class="d-flex ms-n2 pb-5">
							<div class="flex-none me-3">
								<div class="icon icon-shape text-sm w-rem-4 h-rem-4 bg-success rounded-circle"></div>
							</div>
							<div>
								<div class="h6 fw-semibold lh-1 text-heading d-inline-block mb-1">Tablero en tiempo real</div>
								<div class="text-xs text-muted">Terminado</div>
							</div>
						</div>
					</div>
				</div>
				<div class="list-group-item px-2 py-0">
					<div class="border-start">
						<div class="d-flex ms-n2 pb-5">
							<div class="flex-none me-3">
								<div class="icon icon-shape text-sm w-rem-4 h-rem-4 bg-success rounded-circle"></div>
							</div>
							<div>
								<div class="h6 fw-semibold lh-1 text-heading d-inline-block mb-1">Caja</div>
								<div class="text-xs text-muted">Terminado</div>
							</div>
						</div>
					</div>
				</div>
				<div class="list-group-item px-2 py-0">
					<div class="border-start">
						<div class="d-flex ms-n2 pb-5">
							<div class="flex-none me-3">
								<div class="icon icon-shape text-sm w-rem-4 h-rem-4 bg-success rounded-circle"></div>
							</div>
							<div>
								<div class="h6 fw-semibold lh-1 text-heading d-inline-block mb-1">Impresoras</div>
								<div class="text-xs text-muted">Terminado</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</aside>
</template>