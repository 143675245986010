<template>
	<div :id="`section-${section.id}`" :section-id="section.id" class="section-content">
		<h3 class="progress-text mb-4 d-block">{{ section.name }}</h3>
		<div class="row g-6">
			<catalog-item-card v-for="item in items" v-bind:key="item.id" :item="item" :submitting="submitting" @select="selectItem"></catalog-item-card>
		</div>
	</div>
</template>

<script>
	import { computed, onMounted } from 'vue';
	import _ from 'lodash';
	import composableStore from '@/composables/store';

	export default {
		emits: ['selectItem', 'observe'],
		props: {
			section: Object,
			submitting: Boolean
		},
		setup(props, { emit }) {
			const { catalog } = composableStore();

			const items = computed(() => _.filter(catalog.value.items, (row) => { return row.section_id === props.section.id; }));

			const selectItem = (data) => emit('selectItem', data);

			onMounted(() => {
				emit('observe', document.getElementById(`section-${props.section.id}`));
			});

			return { items, selectItem };
		}
	}
</script>