<template>
	<div class="list-group-item px-6 py-3 align-items-center">
		<p>Rendición de <b>{{ vueNumberFormat(payout.amount, $personalizer.currency.value) }}</b>, una vez que compruebe que el monto sea correcto, debe brindarle el código de confirmación al rider.</p>
		<div class="input-group input-group-inline mt-3">
			<button type="button" class="btn btn-outline-secondary px-5 w-50 d-block text-center" @click="codeSwitch">{{ (codeShow) ? 'Ocultar código' : 'Mostrar código' }}</button>
			<input :type="(codeShow) ? 'text' : 'password'" class="form-control text-center font-code border-secondary" readonly :value="payout.code" />
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue';

	export default {
		props: {
			payout: Object
		},
		setup() {
			const codeShow = ref(false);
			const codeSwitch = () => codeShow.value = !codeShow.value;

			return { codeShow, codeSwitch };
		}
	}
</script>