import { ref, computed } from 'vue';
import moment from 'moment';
import store from '@/store';
import _ from 'lodash';

export default function(data = null) {
	const ticket = ref(data);
	const reload = (data) => ticket.value = data;

	const order = computed(() => _.find(store.getters['orders/list'], { id: ticket.value?.order_id }));

	const newMessages = computed(() => {
		var count = 0;
		_.forEach(ticket.value.messages, (message) => { if(message.from != 'store' && _.indexOf(message.read_by, 'store') == -1) count++; });

		return count;
	});

	const STATUSES = {
		'open': {
			title: 'Abierto',
			class: 'success'
		},
		'closed': {
			title: 'Cerrado',
			class: 'danger'
		},
	}

	const REASONS = {
		rider_need: 'Solicito repartidor para el pedido',
		order_not_delivered: 'El pedido fue retirado pero aún no fue entregado',
		order_took_incomplete: 'El repartidor se llevó el pedido incompleto',
		order_took_wrong: 'El repartidor se llevó un pedido equivocado',
		order_cancel: 'Quiero cancelar el pedido',
		order_canceled: 'Wantti canceló el pedido',
		app_problems: 'Tengo inconvenientes con la APP',
		other: 'Otro motivo'
	}

	const statusInfo = computed(() => {
		return (_.has(STATUSES, ticket.value.status))
			? STATUSES[ticket.value.status]
			: { title: '-', class: 'dark' };
	});

	const getCreatedAtFormatted = computed(() => moment(ticket.value.created_at).format('D [de] MMMM, HH:mm[Hs]'));
	const getUpdatedAtForHummans = computed(() => moment(ticket.value.updated_at).format('hh:mm A'));

	return { REASONS, reload, order, newMessages, statusInfo, getCreatedAtFormatted, getUpdatedAtForHummans };
}