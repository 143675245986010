<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4 d-flex align-items-center">
			<div class="row g-0">
				<div class="col">
					<div class="d-flex align-items-center gap-3">
						<h1 class="h4 ls-tight">Estado de <u>{{ code?.description }}</u></h1>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	export default {
		props: {
			code: Object
		}
	}
</script>