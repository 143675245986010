<template>
	<div class="row">
		<div guide="catalog-index" class="col-12 vstack gap-10" v-if="sections.length > 0">
			<catalog-section v-for="section in sections" v-bind:key="section.id" :section="section" :submitting="submitting" @selectItem="selectItem" @observe="observe"></catalog-section>
		</div>
		<div class="col-12" v-if="sections.length == 0">
			<common-message-error class="col-12" text="El comercio no cargó su catálogo." />
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['selectItem', 'observe'],
		props: {
			sections: Array,
			submitting: Boolean
		},
		setup(props, { emit }) {
			const selectItem = (data) => emit('selectItem', data);
			const observe = (element) => emit('observe', element);

			return { selectItem, observe };
		}
	}
</script>