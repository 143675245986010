<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4 d-flex align-items-center">
			<div class="row g-0">
				<div class="col-12 col-md">
					<div class="d-flex align-items-center gap-3">
						<button type="button" class="btn btn-link p-0 text-reset text-md" @click="$emit('reload', false)"><i class="bi bi-arrow-clockwise"></i></button>
						<div class="vr opacity-20 my-1"></div>
						<h1 class="h4 ls-tight">Detalles de la caja</h1>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	export default {
		emits: ['reload']
	}
</script>