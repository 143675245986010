import { ref, computed, watch } from 'vue';
import store from '@/store';
import router from '@/router';
import Pusher from 'pusher-js';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import _ from 'lodash';
import storeAutocloseQuestion from '@/components/common/notification/store/autoclose/question';
import composableRedirect from '@/composables/redirect';
import composableStore from '@/composables/store';
import StoreRepository from '@/repositories/StoreRepository';
import OrderGroupsRepository from '@/repositories/OrderGroupsRepository';
import { printpoint, print } from '@/helpers/Printpoint';

export default function() {
	const { current_store, status, isPlanPremium, isAdmin, gateways } = composableStore();
	const { redirectBack } = composableRedirect();
	const route = useRoute();
	const toast = useToast();
	const token = computed(() => store.getters['auth/token']);
	const pusher = ref(null);
	const privateChannel = ref(null);
	const publicChannel = ref(null);
	const toastAutocloseQuestionId = ref(null);
	const audio = new Audio(require(`@/assets/sound/alert.mp3`));

	const init = async (store_id) => {
		try {
			//Get data
			var profile = await StoreRepository.getProfile(store_id);

			//Set data
			store.dispatch('store/setInfo', profile.data.store);
			store.dispatch('store/setStatus', profile.data.status);
			store.dispatch('store/setGateways', profile.data.gateways);
			store.dispatch('store/setCodes', profile.data.codes);
			store.dispatch('store/setMaps', profile.data.maps);
			store.dispatch('store/setDiscounts', profile.data.discounts);
			store.dispatch('store/setSellers', profile.data.sellers);
			store.dispatch('store/setSections', profile.data.catalog.sections);
			store.dispatch('store/setItems', profile.data.catalog.items);
			store.dispatch('store/setExpires');

			store.dispatch('orders/setStore', profile.data.store.id);
			store.dispatch('orders/setList', profile.data.orders);

			store.dispatch('orderGroups/setStore', profile.data.store.id);
			store.dispatch('orderGroups/setList', profile.data.orderGroups);

			store.dispatch('tickets/setStore', profile.data.store.id);
			store.dispatch('tickets/setList', profile.data.tickets);

			store.dispatch('payouts/setStore', profile.data.store.id);
			store.dispatch('payouts/setList', profile.data.payouts);

			pusherConnect();

			return Promise.resolve();
		}catch(error) {
			router.push({name: 'select'});
			return Promise.reject();
		}
	}

	const destroy = () => {
		store.dispatch('cart/destroy');
		store.dispatch('store/destroy');

		if(pusher.value) pusher.value.disconnect();
		if(toastAutocloseQuestionId.value !== null) toast.dismiss(toastAutocloseQuestionId.value);
	}

	const setup = () => {
		pusher.value = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
			cluster: process.env.VUE_APP_PUSHER_CLUSTER,
			forceTLS: true,
			channelAuthorization: {
				endpoint: `${process.env.VUE_APP_URL}/broadcasting/auth`,
				headers: {
					'Authorization': `${token.value.token_type} ${token.value.access_token}`
				},
			}
		});
	}

	const pusherConnect = () => {
		setup();
		privateChannel.value = pusher.value.subscribe(`private-store.${current_store.value.id}`);

		privateChannel.value.bind('message', (data) => {
			toast.info(data.data.message, { timeout: 20000 });
			audio.play();
		});

		privateChannel.value.bind('store-status-updated', (data) => store.dispatch('store/setStatus', data.data));
		privateChannel.value.bind('catalog-updated', async () => {
			setTimeout(async () => {
				var catalog = await StoreRepository.getCatalog(current_store.value.id);
				store.dispatch('store/setSections', catalog.data.sections);
				store.dispatch('store/setItems', catalog.data.items);
			}, 1500);
		});

		privateChannel.value.bind('order-group-created', async (data) => {
			setTimeout(async () => {
				var orderGroup = await OrderGroupsRepository.get(current_store.value.id, data.data.id);
				store.dispatch('orderGroups/sync', orderGroup.data);
			}, 1500);
		});
		privateChannel.value.bind('order-group-updated', async (data) => {
			setTimeout(async () => {
				var orderGroup = await OrderGroupsRepository.get(current_store.value.id, data.data.id);
				store.dispatch('orderGroups/sync', orderGroup.data);
			}, 1500);
		});

		privateChannel.value.bind('discount-created', (data) => store.dispatch('store/syncDiscount', data.data));
		privateChannel.value.bind('discount-updated', (data) => store.dispatch('store/syncDiscount', data.data));
		privateChannel.value.bind('discount-removed', (data) => store.dispatch('store/removeDiscount', data.data));

		privateChannel.value.bind('code-created', (data) => store.dispatch('store/syncCode', data.data));
		privateChannel.value.bind('code-updated', (data) => store.dispatch('store/syncCode', data.data));
		privateChannel.value.bind('code-removed', (data) => store.dispatch('store/removeCode', data.data));

		privateChannel.value.bind('map-created', async (data) => {
			setTimeout(async () => {
				var map = await StoreRepository.getMap(current_store.value.id, data.data.id);
				store.dispatch('store/syncMap', map.data);
			}, 1500);
		});

		privateChannel.value.bind('map-updated', async (data) => {
			setTimeout(async () => {
				var map = await StoreRepository.getMap(current_store.value.id, data.data.id);
				store.dispatch('store/syncMap', map.data);
			}, 1500);
		});

		privateChannel.value.bind('map-removed', (data) => store.dispatch('store/removeMap', data.data));

		privateChannel.value.bind('payment-pending-resolved', (data) => {
			if(route.name == 'paying' && route.params?.id == data.data.payment.payable.id) {
				switch(data.data.payment.status) {
					case 'APPROVED':
						toast.success('Pago aprobado');
						break;
					case 'REJECTED':
						toast.error('Pago rechazado');
						break;
				}

				if(data.data.payment.status == 'APPROVED') {
					var hasBillingGateway = _.find(gateways.value, { type: 'billing' });

					switch(route.params.type) {
						case 'order':
							var order = _.find(store.getters['orders/list'], { id: Number(route.params.id) });

							if(isPlanPremium.value && hasBillingGateway && order.billings_count == 0) router.push({ name: 'store.order.billing.invoice', params: { store_id: current_store.value.id, id: order.id } });
							else redirectBack();
							break;
						case 'orderGroup':
							var orderGroup = _.find(store.getters['orderGroups/list'], { id: Number(route.params.id) });

							if(isPlanPremium.value && hasBillingGateway && orderGroup.billings_count == 0) router.push({ name: 'store.orderGroup.billing.invoice', params: { store_id: current_store.value.id, id: orderGroup.id } });
							else redirectBack();
							break;
					}
				}else redirectBack();
			}
		});

		if(isAdmin.value) {
			privateChannel.value.bind('order-created', (data) => store.dispatch('orders/sync', data.data.order));
			privateChannel.value.bind('order-updated', (data) => store.dispatch('orders/sync', data.data.order));
			privateChannel.value.bind('order-removed', (data) => store.dispatch('orders/sync', data.data.order));

			privateChannel.value.bind('ticket-updated', (data) => store.dispatch('tickets/sync', data.data));

			privateChannel.value.bind('payout-updated', (data) => store.dispatch('payouts/sync', data.data));

			privateChannel.value.bind('store-autoclose-question', (data) => {
				if(toastAutocloseQuestionId.value == null) {
					toastAutocloseQuestionId.value = toast.warning({
						component: storeAutocloseQuestion,
						props: {
							data: data.data
						}
					}, { position: 'top-center', transition: 'Vue-Toastification__fade', draggable: false, timeout: false, closeOnClick: false, onClose: () => { toastAutocloseQuestionId.value = null; } });
					audio.play();
				}
			});
			privateChannel.value.bind('store-autoclose-close', () => {
				if(toastAutocloseQuestionId.value !== null) toast.dismiss(toastAutocloseQuestionId.value);
			});
		}

		if(printpoint.value) {
			printpointBind(printpoint.value.id);
		}

		publicChannel.value = pusher.value.subscribe('stores');
		publicChannel.value.bind('gateway-updated', (data) => store.dispatch('store/syncGateway', data.data));
	}

	watch(status, (newStatus) => {
		if(toastAutocloseQuestionId.value !== null && !newStatus?.autoclose) toast.dismiss(toastAutocloseQuestionId.value);
	});

	const printpointBinded = ref(null);
	const printpointBind = (id) => {
		if(printpointBinded.value != id) {
			privateChannel.value.bind(`printpoint-${id}`, print);
			printpointBinded.value = id;
		}
	}

	watch(printpoint, (newPrintpoint) => {
		if(newPrintpoint) printpointBind(newPrintpoint.id);
	});

	return { init, destroy };
}
