<template>
	<config-header />
	<main class="py-5 flex-grow-1">
		<div class="container-fluid mw-screen-sm vstack gap-3 min-h-full">
			<div guide="views-config-printpoint-form-connection" class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-12">
							<div class="d-flex align-items-center">
								<span class="avatar rounded-circle">
									<img src="@/assets/img/icons/qz-tray.jpg" alt="Qz Tray">
								</span>
								<div class="ps-3">
									<span class="h5 d-block fw-semibold mb-0">QZ Tray</span>
									<span class="text-sm d-block text-muted" v-if="status == 'pending'">Conectando...</span>
									<span class="text-sm d-block text-success" v-if="status == 'connected'">Conectado</span>
									<span class="text-sm d-block text-danger" v-if="status == 'disconnected'">Desconectado<span class="ps-2 text-muted">(<a href="javascript:void(0);" class="text-muted" @click="connect">reconectar</a>)</span></span>
								</div>
							</div>
						</div>
						<div class="col-12 mt-6" v-if="status == 'disconnected'">
							<div class="alert text-danger border-danger" role="alert">
								<strong>Importante:</strong> En caso de que quieras utilizar impresoras que estén conectadas a éste dispositivo, tenés que descargar e instalar el software <b>QZ Tray</b>. Podés descargarlo haciendo <a href="https://qz.io/download" class="text-danger fw-bolder" target="_blank">click aquí</a>.
							</div>
						</div>
					</div>
				</div>
			</div>
			<form class="card" @submit.prevent="submit" v-if="status == 'connected'">
				<div class="card-header">
					<h4>{{ (printpoint) ? 'Editar punto de impresión' : 'Crear punto de impresión' }}</h4>
					<p class="text-sm text-muted">Conectá las impresoras de éste dispositivo</p>
				</div>
				<div class="card-body">
					<div class="row">
						<div guide="views-config-printpoint-form-input-name" class="col-12 mb-5">
							<label class="form-label" for="name">Nombre <span class="text-muted">(Ej: Punto mostrador, Punto cocina)</span></label>
							<input type="text" id="name" v-model="name" v-focus class="form-control" :class="{'is-invalid': validation.name.$error}" :disabled="submitting" placeholder="Ingresá un nombre">
							<span class="mt-1 invalid-feedback" v-for="error of validation.name.$errors" :key="error.$uid">{{ error.$message }}</span>
						</div>
						<div guide="views-config-printpoint-form-input-shared" class="col-12 mb-5">
							<label class="form-label" for="name">Tipo de punto de impresión</label>
							<div class="vstack gap-4">
								<div class="d-flex gap-3">
									<input type="radio" id="shared_true" v-model="shared" value="1" class="form-check-input flex-shrink-0 text-lg">
									<label class="pt-1 form-checked-content" for="shared_true">
										<h6 class="mb-1 lh-lg">Público</h6>
										<span class="d-block text-muted text-sm">
											<i class="bi bi-people-fill me-1"></i>Otros usuarios de <b>{{ current_store.name }}</b> podrán imprimir
										</span>
									</label>
								</div>
								<div class="d-flex gap-3">
									<input type="radio" id="shared_false" v-model="shared" value="0" class="form-check-input flex-shrink-0 text-lg">
									<label class="pt-1 form-checked-content" for="shared_false">
										<h6 class="mb-1 lh-lg">Privado</h6>
										<span class="d-block text-muted text-sm">
											<i class="bi bi-lock-fill me-1"></i>Sólo se enviarán impresiones desde éste dispositivo
										</span>
									</label>
								</div>
							</div>
						</div>
						<div guide="views-config-printpoint-form-printers" class="col-12">
							<label class="form-label">Listado de impresoras conectadas a éste dispositivo</label>
							<div class="list-group list-group-flush">
								<div class="list-group-item px-0 py-3" v-for="(name, index) in printers" v-bind:key="index">
									<i class="bi bi-printer pe-2"></i>{{ name }}
								</div>
								<div class="list-group-item px-0 py-3" v-if="printers.length == 0">
									No se encontraron impresoras
								</div>
							</div>
							<div class="alert bg-warning text-white d-flex align-items-center rounded px-2 py-1 mt-3" role="alert">
								<i class="bi bi-info-circle me-2"></i>
								<div class="text-sm">
									<b>Wantti</b> utiliza impresoras de comandos <b>ESC/POS</b> de <b>48 líneas</b>.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<router-link :to="{ name: 'store.config.index', params: { store_id: current_store.id } }" class="btn btn-secondary">Cancelar</router-link>
					<button type="submit" guide="views-config-printpoint-button-submit" class="btn btn-primary" :disabled="submitting">Guardar</button>
				</div>
			</form>
		</div>
	</main>
</template>

<script>
	import { reactive, toRefs, computed } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import { connect, status, printers } from '@/helpers/QZTray';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { current_store } = composableStore();
			const { submitting, validationExternal, catchError } = composableForm();
			const printpoint = computed(() => store.getters['config/printpoint'](current_store.value.id));

			const form = reactive({
				id: printpoint.value?.id ?? null,
				name: printpoint.value?.name ?? null,
				shared: Number(printpoint.value?.shared ?? 0),
				types: printpoint.value?.types ?? [],
			});

			const validation = useVuelidate({
				name: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(150)
				}
			}, form);

			const submit = async () => {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await store.dispatch('config/setPrintpoint', form).then(() => router.push({ name: 'store.config.index', params: { store_id: current_store.value.id } })).catch(catchError);
				submitting.value = false;
			}

			return { ...toRefs(form), validation, validationExternal, current_store, printpoint, connect, status, printers, submitting, submit };
		}
	}
</script>