<template>
	<div class="d-flex border-bottom bg-warning text-white" v-if="showAlert">
		<div class="container-fluid">
			<div class="py-2 d-flex">
				<div class="pe-3 text-lg">
					<i class="bi bi-alarm"></i>
				</div>
				<div>
					<span class="d-block fw-semibold">¡Abrir la tienda!</span>
					<p class="text-sm">La tienda <b>está cerrada</b>, aunque se encuentra en horario de apertura.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';
	import moment from 'moment';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { current_store, status, isAdmin } = composableStore();

			const showAlert = computed(() => {
				if(isAdmin.value && !status.value.open) {
					var data = _.find(current_store.value.hours, { day_idx: new Date().getDay() });
					if(data) return !!_.find(data.hours, (value) => {
						var from = moment(value.from, 'hh:mm:ss'),
							to = moment(value.to, 'hh:mm:ss');

						return moment().isBetween(from, to);
					});
				}

				return false;
			});

			return { current_store, showAlert };
		}
	}
</script>