<template>
	<div class="container-fluid h-100 py-6">
		<!-- Emoji -->
		<div class="display-6 mb-4">🚀</div>
		<!-- Heading -->
		<h1 class="ls-tight fw-bolder display-5 mb-5">Convierte <u>{{ current_store.name }}</u><br />en una cuenta Premium.</h1>
		<!-- Text -->
		<p class="lead mb-10">Con una cuenta premium podrás cargar pedidos de forma manual, podrás realizar facturas electrónicas, imprimir comandas y resúmen de pedidos, administrar los pedidos desde las mesas, y más!</p>
		<!-- Buttons -->
		<div>
			<a href="https://wantti.com/web" target="_blank" class="btn btn-lg btn-neutral">Conocer más</a>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { current_store, status, codes, codeDefault, maps } = composableStore();
			const { selectCodeDefault, selectTakeaway } = composableCart();

			const services = computed(() => (status.value?.type?.inmediate?.services ?? []));
			const typeLocal = computed(() => ('LOCAL' in services.value));
			const typeTakeaway = computed(() => ('TAKEAWAY' in services.value));
			const typeDelivery = computed(() => ('DELIVERY' in services.value));

			return { current_store, status, codes, maps, codeDefault, typeLocal, typeTakeaway, typeDelivery, selectCodeDefault, selectTakeaway };
		}
	}
</script>