<template>
	<div id="common-order-promocode" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header container container-offcanvas">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">Usar código promocional</h5>
			<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body container container-offcanvas pt-0">
			<form @submit.prevent="submit">
				<div class="mb-7">
					<input type="text" id="code" class="form-control font-code" :class="{'is-invalid': validation.code.$error}" v-model="code" placeholder="Ingrese aquí el código" maxlength="20">
					<span class="mt-1 invalid-feedback" v-for="error of validation.code.$errors" :key="error.$uid">{{ error.$message }}</span>
				</div>
				<div class="d-grid gap-4 d-md-flex justify-content-md-end">
					<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="offcanvas">Cancelar</button>
					<button type="submit" class="btn btn-sm btn-dark" :disabled="submitting">Aplicar código</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { ref, reactive, toRefs, onMounted, watch } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import { Offcanvas } from 'bootstrap';

	export default {
		emits: ['setPromocode'],
		setup(props, { emit }) {
			const { current_store } = composableStore();
			const { submitting, catchError } = composableForm();

			const modalOrderPromocode = ref(null);
			onMounted(() => {
				modalOrderPromocode.value = new Offcanvas(document.getElementById('common-order-promocode'));

				document.getElementById('common-order-promocode').addEventListener('hidden.bs.offcanvas', () => {
					form.code = '';
				});

				document.getElementById('common-order-promocode').addEventListener('shown.bs.offcanvas', () => {
					document.querySelector('#common-order-promocode #code').focus();
				});
			});

			const form = reactive({
				code: null
			});

			watch(() => form.code, (newValue) => form.code = newValue.replace(/\s/, '').toUpperCase());

			const validation = useVuelidate({
				code: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(20)
				}
			}, form);

			const submit = async () => {
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await StoreRepository.promocodeCheck(current_store.value.id, { code: form.code })
					.then((response) => {
						emit('setPromocode', response.data);
						modalOrderPromocode.value.hide();
					})
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { ...toRefs(form), validation, submitting, submit };
		}
	}
</script>