import { ref } from 'vue';
import { useToast } from 'vue-toastification';

export default function(setCard) {
	const toast = useToast();
	const submitting = ref(false);
	var instance = null;
	var cardForm = null;

	const ERRORS_MAP = {
		'205': 'Ingresa el número de tu tarjeta.',
		'208': 'Elige el mes de vencimiento de tu tarjeta.',
		'209': 'Elige el año de vencimiento de tu tarjeta.',
		'212': 'Ingresa tu tipo de documento.',
		'213': 'Ingresa tu documento de identidad.',
		'214': 'Ingresa tu documento de identidad.',
		'220': 'Ingresa tu banco.',
		'221': 'Ingresa el titular de la tarjeta.',
		'224': 'Ingresa el código de seguridad.',
		'E301': 'Ingresa un número de tarjeta válido.',
		'E302': 'Revisa el código de seguridad.',
		'316': 'Ingresa un titular válido.',
		'322': 'El tipo de documento es inválido.',
		'323': 'Revisa tu documento de identidad.',
		'324': 'El documento de identidad es inválido.',
		'325': 'El mes de vencimiento es inválido.',
		'326': 'El año de vencimiento es inválido.'
	}

	const init = async (config, amount) => {
		if(!instance) {
			new Promise((resolve) => {
				const script = document.createElement('script');
				script.onload = () => {
					instance = new window.MercadoPago(config.public_key, { locale: 'es-AR' });
					initForm(amount);
				}
				script.onerror = resolve;
				script.src = 'https://sdk.mercadopago.com/js/v2';
				document.head.appendChild(script);
			});
		}else initForm(amount);
	}

	const initForm = (amount) => {
		if(cardForm) cardForm.unmount();

		cardForm = instance.cardForm({
			amount: amount.toFixed(2),
			autoMount: true,
			processingMode: 'aggregator',
			form: {
				id: 'form-card',
				cardholderName: { id: 'cardholder-name' },
				cardNumber: { id: 'card-number' },
				cardExpirationMonth: { id: 'card-expiration-month' },
				cardExpirationYear: { id: 'card-expiration-year' },
				securityCode: { id: 'security-code' },
				identificationNumber: { id: 'identification-number' },
				identificationType: { id: 'identification-type' },
				installments: { id: 'installments' },
				issuer: { id: 'issuer' }
			},
			callbacks: {
				onFormMounted: error => {
					if(error) return console.warn('Form Mounted handling error: ', error)
					console.log('Form mounted');
				},
				onFormUnmounted: error => {
					if(error) return console.warn('Form Unmounted handling error: ', error)
					console.log('Form unmounted');
				},
				onIdentificationTypesReceived: (error, identificationTypes) => {
					if(error) return console.warn('identificationTypes handling error: ', error)
					console.log('Identification types available: ', identificationTypes);
				},
				onPaymentMethodsReceived: (error, paymentMethods) => {
					if(error && 'message' in error) return toast.error(error.message);
					else if(error) return toast.error('Encontramos un error en los datos ingresados, por favor verifíquelos.');
					console.log('Payment Methods available: ', paymentMethods);
				},
				onIssuersReceived: (error, issuers) => {
					if(error) return console.warn('issuers handling error: ', error)
					console.log('Issuers available: ', issuers);
				},
				onInstallmentsReceived: (error, installments) => {
					if(error) return console.warn('installments handling error: ', error)
					console.log('Installments available: ', installments);
				},
				onCardTokenReceived: (errors, token) => {
					if(errors) errors.map(function(error) {
						if(error.code in ERRORS_MAP) toast.error(ERRORS_MAP[error.code]);
					});

					console.log('Token available: ', token);
				},
				onSubmit: (event) => {
					event.preventDefault();

					submitting.value = true;
					const cardData = cardForm.getCardFormData();
					setCard({ id: cardData.paymentMethodId, installments: Number(cardData.installments), token: cardData.token });
					submitting.value = false;
					console.log('CardForm data available: ', cardData);
				},
				onFetching:(resource) => {
					console.log('Fetching resource: ', resource);
				},
			}
		});
	}

	return { init, submitting };
}