<template>
	<teleport to="body">
		<div class="modal" id="guide-select" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md modal-dialog-centered">
				<div class="modal-content shadow-3">
					<div class="modal-header">
						<div class="lh-xs">
							<h4 class="modal-title">Guías en pantalla</h4>
							<small class="d-block">Te ayudarán a comprender el punto de venta</small>
						</div>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body p-0">
						<div class="row">
							<div class="col-12">
								<div class="list-group list-group-flush">
									<div class="list-group-item px-6 py-3" v-for="(guide, index) in guidesFiltereds" v-bind:key="index">
										<a href="javascript:void(0);" class="d-block h6 fw-semibold stretched-link" @click="guideSelect(guide);">{{ guide.title }}</a>
										<p class="text-sm text-muted lh-xs mt-1">{{ guide.description }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';
	import composableStore from '@/composables/store';

	export default {
		emits: ['guideSelect'],
		props: {
			guides: Object
		},
		setup(props, { emit }) {
			const { codes, isPlanFree, isAdmin } = composableStore();
			const orderGroups = computed(() => !!_.find(codes.value, { group: true }));
			const modal = ref(null);

			const guidesFiltereds = computed(() => {
				return _.filter(props.guides, function(guide) {
					if(_.indexOf((guide?.filters ?? []), 'orderGroups') !== -1 && !orderGroups.value) return false;
					if(_.indexOf((guide?.filters ?? []), 'isntPlanFree') !== -1 && isPlanFree.value) return false;
					if(_.indexOf((guide?.filters ?? []), 'isAdmin') !== -1 && !isAdmin.value) return false;
					if(_.indexOf((guide?.filters ?? []), 'guideInit') !== -1) return false;

					return true;
				});
			});

			const guideSelect = (guide) => {
				emit('guideSelect', guide);
			}

			onMounted(() => {
				modal.value = new Modal(document.getElementById('guide-select'));
			});

			return { modal, guidesFiltereds, guideSelect };
		}
	}
</script>