<template>
	<label class="form-label">Tiempo de entrega</label>
	<div class="card shadow-1 border rounded-2">
		<div class="checkout-option d-flex justify-content-between position-relative" v-if="immediateAllowed">
			<div class="form-check">
				<input type="radio" v-model="option" value="immediate" class="form-check-input" :disabled="submitting">
				<div class="form-check-label text-dark d-block ms-1">
					<a href="javascript:void(0)" class="stretched-link text-dark" @click="selectWhen({ option: 'immediate'})">Lo antes posible</a>
					<small class="d-block" v-if="option == 'immediate'" v-html="immediateDelay"></small>
				</div>
			</div>
		</div>
		<div class="checkout-option d-flex justify-content-between position-relative" v-if="scheduleAllowed">
			<div class="form-check">
				<input type="radio" v-model="option" value="scheduled" class="form-check-input" :disabled="submitting">
				<div class="form-check-label text-dark d-block ms-1">
					<a href="javascript:void(0)" class="stretched-link text-dark" @click="menuScheduleShow">Programado</a>
					<small class="d-block" v-if="option == 'scheduled'">Entrega el <b>{{ when.description }}</b>.</small>
				</div>
			</div>
			<a href="javascript:void(0)" class="text-dark stretched-link position-relative" v-if="option == 'scheduled'" @click="menuScheduleShow">Editar</a>
		</div>
		<div class="checkout-option d-flex justify-content-between position-relative text-muted" v-if="!immediateAllowed && !scheduleAllowed">
			No hay opciones disponibles.
		</div>
	</div>
	<cart-checkout-menu-schedule :scheduleOptions="scheduleOptions" @setSchedule="setSchedule" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';
	import composableForm from '@/composables/form';
	import moment from 'moment';
	import { Offcanvas } from 'bootstrap';
	import _ from 'lodash';

	export default {
		props: {
			submitting: Boolean
		},
		setup(props) {
			const { current_store, status } = composableStore();
			const { type, when, selectWhen, address } = composableCart();
			const { catchError } = composableForm();

			const option = computed(() => {
				if(!when.value) return null;
				else return when.value.option;
			});

			const immediateAllowed = computed(() => status.value?.type?.inmediate?.services[type.value.option]);
			const immediateDelay = computed(() => {
				var delay = Number(status.value?.delay);

				if(type.value.option == 'DELIVERY') {
					var ready_from = moment().add((delay + Number(process.env.VUE_APP_DELIVERY_FROM)), 'minutes').format('HH:mm');
					var ready_to = moment().add((delay + Number(process.env.VUE_APP_DELIVERY_TO)), 'minutes').format('HH:mm');

					return `Entrega entre las <b class="text-dark-75">${ready_from} hs</b> y <b class="text-dark-75">${ready_to} hs</b>.`;
				}else{
					var ready_by = moment().add(delay, 'minutes').format('HH:mm');

					return `Entrega a partir de las <b class="text-dark-75">${ready_by} hs</b>.`;
				}
			});

			const scheduleOptions = ref([]);

			const scheduleAllowed = computed(() => (status.value?.type?.preorder && _.indexOf(['DELIVERY', 'TAKEAWAY'], type.value.option) !== -1));
			const menuSchedule = ref(null);
			onMounted(() => menuSchedule.value = new Offcanvas(document.getElementById('menu-schedule')));
			const menuScheduleShow = async () => {
				if(props.submitting) return;
				await StoreRepository.scheduleOptions(current_store.value.id, { type: type.value, geolocation: address.value?.geolocation })
					.then((data) => {
						scheduleOptions.value = data.data;
						menuSchedule.value.show();
					})
					.catch(catchError);
			}

			const setSchedule = (option) => {
				selectWhen(option);
				menuSchedule.value.hide();
			}

			return { when, option, selectWhen, immediateAllowed, immediateDelay, scheduleOptions, scheduleAllowed, menuScheduleShow, setSchedule };
		}
	}
</script>