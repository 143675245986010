<template>
	<table class="table table-sm table-hover">
		<thead>
			<tr>
				<th scope="col">Formas de pago</th>
				<th class="text-end" scope="col">Monto</th>
			</tr>
		</thead>
		<tbody>
			<tr v-if="cashbox.balance.payment_methods_in_out['in'].length == 0 && cashbox.balance.payment_methods_in_out['out'].length == 0">
				<td colspan="2">No hay elementos.</td>
			</tr>
			<tr v-for="(amount, method) in cashbox.balance.payment_methods_in_out['in']" v-bind:key="method">
				<td>{{ methodDescription(method) }}</td>
				<td class="text-end">{{ vueNumberFormat(amount, $personalizer.currency.value) }}</td>
			</tr>
			<tr v-for="(amount, method) in cashbox.balance.payment_methods_in_out['out']" v-bind:key="method">
				<td>{{ methodDescription(method) }} cobrado por <b>empresa de delivery</b></td>
				<td class="text-end">{{ vueNumberFormat(amount, $personalizer.currency.value) }}</td>
			</tr>
		</tbody>
		<tfoot>
			<tr>
				<th>Total</th>
				<th class="text-end">{{ vueNumberFormat(cashbox.balance.payment_totals, $personalizer.currency.value) }}</th>
			</tr>
		</tfoot>
	</table>
</template>

<script>
	import _ from 'lodash';

	export default {
		props: {
			cashbox: Object
		},
		setup(props) {
			const methodDescription = (method) => {
				return (_.has(props.cashbox.payment_methods, method))
					? props.cashbox.payment_methods[method].title
					: '-';
			}

			return { methodDescription };
		}
	}
</script>