import { ref, watch } from 'vue';
import router from '@/router';
import { VueCookieNext } from 'vue-cookie-next';

const cookie_name = 'code';
const code = ref(null);
export const duplicated = ref(false);

export const install = () => {
}

export const init = () => {
	generateCode();
	duplicated.value = false;
}

export const check = () => {
	if(VueCookieNext.isCookieAvailable(cookie_name) && VueCookieNext.getCookie(cookie_name) != code.value) duplicated.value = true;
}

const generateCode = () => {
	let uid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		let r = Math.random() * 16 | 0,
		v = c == 'x' ? r : (r & 0x3 | 0x8);

		return v.toString(16);
	});

	code.value = uid;
	VueCookieNext.setCookie(cookie_name, code.value, { expire: Infinity });
}

watch(duplicated, (newValue) => {
	if(newValue) router.push({name: 'duplicated'});
});

init();
setInterval(check, 1000);