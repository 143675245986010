<template>
	<div class="d-flex justify-content-between align-items-center">
		<div>No se pudo conectar con QZ Tray.</div>
		<a href="javascript:void(0);" class="btn btn-sm btn-dark text-uppercase px-2 py-1 ms-3" @click="config">Configurar</a>
	</div>
</template>

<script>
	import router from '@/router';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { current_store } = composableStore();
			const config = () => router.push({ name: 'store.config.printpoint.form', params: { store_id: current_store.value.id } });

			return { config };
		}
	}
</script>