<template>
	<orders-kanban-complete @setdrag="setdrag" v-if="dragging" />
	<orders-kanban-header :orders="orders" :filters="filters" @setFilters="setFilters" @emptyFilters="emptyFilters" />
	<main class="py-5 flex-grow-1 overflow-auto">
		<div class="container-fluid h-100">
			<div guide="orders-kanban-index-main" class="d-flex h-100 mx-n3">
				<orders-kanban-column :orders="orders" :filters="filters" status="PENDING" theme="secondary" title="Pendientes" @setdrag="setdrag" @select="select" />
				<orders-kanban-column :orders="orders" :filters="filters" status="ACCEPTED" theme="warning" title="Aceptados" @setdrag="setdrag" @select="select" />
				<orders-kanban-column :orders="orders" :filters="filters" status="DELIVER" theme="success" title="Por entregar" @setdrag="setdrag" @select="select" />
			</div>
		</div>
	</main>

	<orders-index :orders="orders" @select="select" />
	<orders-modal-massive :orders="orders" />
	<orders-modal-view :order="order" @select="select" @selectReject="selectReject" @selectTicketNew="selectTicketNew" @selectTicket="selectTicket" />
	<orders-modal-reject :order="orderReject" @selectReject="selectReject" />

	<tickets-modal-new :order="ticketOrderNew" @selectTicket="selectTicket" @selectTicketNew="selectTicketNew" />
	<tickets-modal-view :id="ticket_id" @select="selectTicket" />
	<tickets-index @select="selectTicket" />

	<payouts-modal-index />
</template>

<script>
	import { ref, computed } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const orders = computed(() => store.getters['orders/list']);
			const dragging = ref(false);

			const setdrag = (data) => dragging.value = data;

			const order = ref(null);
			const select = (data) => order.value = data;

			const orderReject = ref(null);
			const selectReject = (data) => orderReject.value = data;

			const ticket_id = ref(null);
			const selectTicket = (data) => ticket_id.value = data?.id ?? null;

			const ticketOrderNew = ref(null);
			const selectTicketNew = (data) => ticketOrderNew.value = data;

			const filters = ref({ type: null });
			const setFilters = (type, value) => filters.value[type] = value;
			const emptyFilters = () => filters.value = { type: null };

			return { orders, dragging, setdrag, order, select, orderReject, selectReject, ticket_id, selectTicket, ticketOrderNew, selectTicketNew, filters, setFilters, emptyFilters };
		}
	}
</script>