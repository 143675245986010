<template>
	<layouts-loading :text="'cargando tienda'" v-if="!isDefined" />
	<component :is="viewMain" v-if="isDefined">
		<store-alert-close />
		<router-view />
	</component>
	<common-modal-screensaver />
</template>

<script>
	import { computed, onMounted, onUnmounted } from 'vue';
	import { useRoute } from 'vue-router';
	import helperSynchronizer from '@/helpers/Synchronizer';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const route = useRoute();
			const { init, destroy } = helperSynchronizer();
			const { isDefined } = composableStore();

			onMounted(() => init(route.params?.store_id));
			onUnmounted(() => destroy());

			const viewMain = computed(() => {
				if(route.meta?.layout) return route.meta.layout;
				else return 'layouts-store-main';
			});

			return { isDefined, viewMain };
		}
	}
</script>