<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4">
			<div class="row g-0 align-items-center">
				<div class="col">
					<div class="d-flex align-items-center gap-3">
						<h1 class="h4 ls-tight">Emitir factura</h1>
					</div>
				</div>
				<div class="col-auto">
					<button type="button" guide="billing-header-button-summary" class="btn btn-sm btn-warning" @click="printSummary"><i class="bi bi-printer me-2"></i>Imprimir resumen</button>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	export default {
		emits: ['printSummary'],
		setup(props, { emit }) {
			const printSummary = () => emit('printSummary');

			return { printSummary };
		}
	}
</script>