<template>
	<div class="d-flex flex-column flex-xl-row h-100">
		<div class="flex-grow-1 overflow-y-xl-auto d-flex flex-column main-slot">
			<orders-kanban-index v-if="isAdmin" />
			<store-shortcuts v-else-if="!isPlanFree" />
			<store-plan v-else />
		</div>
		<div class="flex-xl-none overflow-y-xl-auto col-xl-4 border-start-xl d-flex flex-column">
			<map-index v-if="!isPlanFree && maps.length > 0" />
			<code-index cardClass="col-12 col-md-6 col-lg-4 col-xl-12 col-xxl-6" v-else-if="!isPlanFree && codes.length > 0" />
			<common-app-status v-else />
		</div>
	</div>
</template>

<script>
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { isPlanFree, isAdmin, maps, codes } = composableStore();

			return { isPlanFree, isAdmin, maps, codes };
		}
	}
</script>
