<template>
	<div class="container">
		<div class="justify-content-center">
			<div class="d-flex flex-column position-relative">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		setup() {
			document.body.className = 'layouts-cart-minimum';
		}
	}
</script>

<style>
	.layouts-cart-minimum .container {
		max-width: 650px !important;
	}
</style>