<template>
	<hr class="my-0" v-if="index" />
	<div class="d-flex justify-content-between p-4">
		<div class="form-check position-relative">
			<input type="radio" v-model="option" value="mercadopago-online" class="form-check-input" :disabled="loading">
			<div class="form-check-label text-dark d-block ms-1">
				<a href="javascript:void(0)" class="stretched-link text-dark" @click="menuShow">Con tarjeta</a>
				<small class="d-block" v-if="option == 'mercadopago-online'" v-html="description"></small>
			</div>
		</div>
		<a href="javascript:void(0)" class="text-dark" v-if="option == 'mercadopago-online'" @click="menuShow">Editar</a>
	</div>

	<cart-checkout-menu-mercadopago-online @setCard="setCard" :constraints="payment_method.constraints" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import { Offcanvas } from 'bootstrap';

	export default {
		emits: ['select'],
		props: {
			loading: Boolean,
			payment_method: Object,
			index: Number
		},
		setup(props, { emit }) {
			const payment = computed(() => store.getters['cart/payment']);
			const option = computed(() => {
				if(!payment.value) return null;
				else return payment.value.option;
			});

			const description = computed(() => {
				var description = null;

				if(payment.value.cardNumber) {
					description = `Pagando con tarjeta terminada en <b class="font-code">${payment.value.cardNumber}</b>`;
				}else{
					description = `Datos cargados correctamente`;
				}

				return description;
			});

			const setCard = (option) => {
				emit('select', option);
				menu.value.hide();
			}

			const menu = ref(null);
			onMounted(() => {
				menu.value = new Offcanvas(document.getElementById('menu-mercadopago-online'));

				document.getElementById('menu-mercadopago-online').addEventListener('shown.bs.offcanvas', () => {
					document.querySelector('#card-number').focus();
				});
			});
			const menuShow = () => {
				if(!props.loading) menu.value.show();
			}

			return { payment, description, option, setCard, menuShow };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>