<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4">
			<div class="row g-0 align-items-center">
				<div class="col">
					<div class="d-flex align-items-center gap-3">
						<button type="button" class="btn btn-link p-0 text-reset text-md" @click="destroy"><i class="bi bi-trash"></i></button>
						<div class="vr opacity-20 my-1"></div>
						<h1 class="h4 ls-tight">Establecer dirección</h1>
					</div>
				</div>
				<div class="col-auto">
					<div class="hstack gap-2 justify-content-end">
						<button guide="cart-header-address-button-next" type="button" class="btn btn-sm btn-primary" :disabled="disabled" @click="next">
							Siguiente<i class="bi bi-chevron-right ps-1"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import composableCart from '@/composables/cart';

	export default {
		emits: ['next'],
		props: {
			disabled: Boolean
		},
		setup(props, { emit }) {
			const { destroy } = composableCart();

			const next = () => emit('next');

			return { destroy, next };
		}
	}
</script>