<template>
	<teleport to="body">
		<div class="modal" :id="`invitation-modal-response-${invitation.id}`" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md">
				<div class="modal-content shadow-3">
					<div class="modal-header border-bottom-0">
						<store-cardsmall :store="invitation.store" />
					</div>
					<div class="modal-body pt-3">
						Te invitaron a unirte a la cuenta de <b>{{ invitation.store.name }}</b> con el perfil <b>{{ role_description }}</b>.
					</div>
					<div class="modal-footer d-flex justify-content-between">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal" :disabled="submitting">Cancelar</button>
						<div class="hstack gap-2">
							<button type="button" class="btn btn-sm btn-danger" :disabled="submitting" @click="submit(false)">Rechazar</button>
							<button type="button" class="btn btn-sm btn-success" :disabled="submitting" @click="submit(true)">Aceptar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import { Modal } from 'bootstrap';
	import { useToast } from 'vue-toastification';
	import StoreInviteRepository from '@/repositories/StoreInviteRepository';
	import composableForm from '@/composables/form';

	export default {
		emits: ['loadStores'],
		props: {
			invitation: Object
		},
		setup(props, { emit }) {
			const { submitting, catchError } = composableForm();
			const toast = useToast();

			const modalResponse = ref(null);
			onMounted(() => {
				modalResponse.value = new Modal(document.getElementById(`invitation-modal-response-${props.invitation.id}`));
			});

			const role_description = computed(() => {
				var description = null;

				switch(props.invitation.role) {
					case 'owner':
						description = 'Propietario';
						break;
					case 'manager':
						description = 'Encargado';
						break;
					case 'consultant':
						description = 'Consultor';
						break;
					case 'seller':
						description = 'Vendedor';
						break;
					case 'operator':
						description = 'Operador';
						break;
				}

				return description;
			});

			const submit = async (answer = false) => {
				if(submitting.value) return false;

				submitting.value = true;
				await StoreInviteRepository.answer(props.invitation.id, { answer: answer })
					.then(() => {
						emit('loadStores');
						modalResponse.value.hide();
						toast.success('Invitación respondida correctamente.');
					})
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { role_description, submitting, submit };
		}
	}
</script>