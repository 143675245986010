<template>
	<div class="h-100">
		<layouts-loading :text="'iniciando sesión'" />
	</div>
</template>

<script>
	import { onMounted } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import router from '@/router';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { params: { provider } } = useRoute();
			const { catchError } = composableForm();

			onMounted(() => {
				store.dispatch('auth/exchange', {provider: provider}).then(onSuccess).catch(onError);
			});

			const onSuccess = () => {
				store.dispatch('auth/me').then(() => router.push({name: 'select'})).catch(onError);
			}

			const onError = (e) => {
				catchError(e);
				router.push({ name: 'login' });
			}

			return { provider };
		}
	}
</script>