import Repository from "./Repository";

const resource = "/tokens";

export default {
	store(payload) {
		return Repository.post(`${resource}`, payload);
	},
	exchange(provider, token) {
		return Repository.post(`${resource}/social/${provider}`, {
			access_token: token
		});
	},
	otpExchange(payload) {
		return Repository.post(`${resource}/otp`, payload);
	}
}