<template>
	<teleport to="body">
		<div class="modal" id="cart-modal-detail" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content shadow-3">
					<div class="modal-header">
						<h4 class="modal-title">Detalle del pedido</h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body list-group list-group-flush pt-0">
						<div class="list-group list-group-flush">
							<cart-detail-item v-for="(item, index) in items" v-bind:key="item.id" :item="item" :index="index" :canDelete="canDelete" class="list-group-item px-0" />
							<div class="list-group-item px-0" v-if="items.length == 0">No hay productos.</div>
							<div class="list-group-item px-0">
								<div class="d-flex justify-content-between mb-2" v-if="subtotal != total">
									<h6 class="h6">Subtotal</h6>
									<h6 class="h6 text-nowrap">{{ vueNumberFormat(subtotal, $personalizer.currency.value) }}</h6>
								</div>
								<div class="d-flex justify-content-between mb-2" v-if="discount > 0">
									<h6 class="h6 text-danger">Descuento</h6>
									<h6 class="h6 text-danger text-nowrap">{{ vueNumberFormat(discount, $personalizer.currency.value) }}</h6>
								</div>
								<div class="d-flex justify-content-between mb-2" v-if="(type && type.option == 'DELIVERY')">
									<h6 class="h6">Envío</h6>
									<h6 class="h6 text-nowrap">{{ vueNumberFormat(deliveryAmount, $personalizer.currency.value) }}</h6>
								</div>
								<div class="d-flex justify-content-between">
									<h4 class="h4">Total</h4>
									<h4 class="h4 text-nowrap">{{ vueNumberFormat(total, $personalizer.currency.value) }}</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import composableCart from '@/composables/cart';

	export default {
		props: {
			canDelete: Boolean
		},
		setup() {
			const { type, items, subtotal, discount, deliveryAmount, total } = composableCart();

			return { type, items, subtotal, discount, deliveryAmount, total };
		}
	}
</script>