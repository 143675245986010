<template>
	<teleport to="body">
		<div class="modal" id="cashbox-flow-create" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content shadow-3">
					<div class="modal-header">
						<h4 class="modal-title">Registrar movimiento de caja</h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 mb-5">
								<label class="form-label">Encargado</label>
								<user-card-main :user="user" />
							</div>
							<div class="col-12 mb-5">
								<label class="form-label">Motivo</label>
								<select class="form-select" :disabled="submitting" v-model="type">
									<option value="null" selected>Seleccionar</option>
									<option v-for="(type, index) in flow_types" v-bind:key="index" v-bind:value="index">{{ type.description }}</option>
								</select>
							</div>
							<div class="col-12 mb-5">
								<label class="form-label">Descripción <span class="text-muted">(Opcional)</span></label>
								<input type="text" class="form-control" :disabled="submitting" v-model="description" placeholder="Ingrese una descripción">
							</div>
							<div class="col-12 mb-5">
								<label for="amount" class="form-label">Monto</label>
								<VueNumberFormat id="amount" :options="Object.assign({ acceptNegative: true }, $personalizer.currency.value)" class="form-control" :disabled="submitting" v-model:value="amount"></VueNumberFormat>
							</div>
							<div class="col-12">
								<div class="alert border-warning text-warning d-flex align-items-center rounded px-2 py-1" role="alert">
									<i class="bi bi-info-circle me-2"></i>
									<div class="text-sm">Positivo para ingreso de dinero, negativo para egreso.</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal" :disabled="submitting">Cancelar</button>
						<button type="button" class="btn btn-sm btn-primary" :disabled="submitting" @click="submit">Guardar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, reactive, toRefs, computed, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import CashboxFlowRepository from '@/repositories/CashboxFlowRepository';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';

	export default {
		emits: ['reload'],
		props: {
			flow_types: Object
		},
		setup(props, { emit }) {
			const { current_store } = composableStore();
			const { submitting, catchError } = composableForm();
			const user = computed(() => store.getters['auth/user']);
			const modalFlowCreate = ref(null);

			const form = reactive({
				type: null,
				description: null,
				amount: 0
			});

			onMounted(() => {
				modalFlowCreate.value = new Modal(document.getElementById('cashbox-flow-create'));

				document.getElementById('cashbox-flow-create').addEventListener('hidden.bs.modal', () => {
					form.type = null;
					form.description = null;
					form.amount = 0;
				});
			});

			const submit = async () => {
				if(submitting.value) return false;

				submitting.value = true;
				await CashboxFlowRepository.store(current_store.value.id, form)
					.then(() => {
						modalFlowCreate.value.hide();
						emit('reload');
					})
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { ...toRefs(form), user, submitting, submit };
		}
	}
</script>