<template>
	<billing-header :ordergroup="ordergroup" @printSummary="printSummary('ordergroup', ordergroup.id)" />
	<!-- Main -->
	<main class="py-5 flex-grow-1">
		<!-- Container -->
		<component :is="viewMain" :billing="billing" :total="ordergroup.total" :submitting="submitting" @submit="submit" v-if="viewMain" />
	</main>
</template>

<script>
	import { computed } from 'vue';
	import { useRoute } from 'vue-router';
	import store from '@/store';
	import _ from 'lodash';
	import composableRedirect from '@/composables/redirect';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import OrderGroupsRepository from '@/repositories/OrderGroupsRepository';
	import { printSummary, printInvoice } from '@/helpers/Printpoint';

	export default {
		setup() {
			const { redirectBack } = composableRedirect();
			const { params: { id } } = useRoute();
			const { current_store, gateways } = composableStore();
			const { submitting, catchError } = composableForm();
			const printers = computed(() => store.getters['config/printers'](current_store.value.id));
			const billing = computed(() => _.find(gateways.value, { type: 'billing' }));
			const ordergroup = computed(() => _.find(store.getters['orderGroups/list'], (o) => { return o.id == id; }));

			const viewMain = computed(() => {
				if(ordergroup.value && billing.value) return `gateways-${billing.value.service}-invoice`;
				else return null;
			});

			const submit = async (data) => {
				submitting.value = true;
				await OrderGroupsRepository.invoice(current_store.value.id, ordergroup.value.id, data)
					.then(() => {
						if('billing' in printers.value && printers.value['billing']?.automatic) printInvoice('ordergroup', ordergroup.value.id);
						redirectBack();
					})
					.catch(catchError);
				submitting.value = false;
			}

			return { billing, viewMain, ordergroup, submitting, submit, printSummary };
		}
	}
</script>