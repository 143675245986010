<template>
	<div class="container-fluid h-100 py-6">
		<div class="pb-6">
			<div class="row align-items-center">
				<div class="col-sm-12 col-12">
					<!-- Title -->
					<h3 class="ls-tight">Pantalla principal</h3>
					<p class="text-muted">Accesos directos a funciones del sistema</p>
				</div>
			</div>
		</div>
		<div class="row row-cols-1 row-cols-md-2 row-cols-xl-1 row-cols-xxl-2 g-5">
			<div class="col" v-if="codeDefault && typeLocal">
				<store-shortcuts-code-default />
			</div>
			<div class="col" v-if="typeTakeaway">
				<store-shortcuts-takeaway />
			</div>
			<div class="col" v-if="typeDelivery">
				<store-shortcuts-delivery />
			</div>
			<div class="col" v-if="maps.length > 0">
				<store-shortcuts-map />
			</div>
			<div class="col" v-if="codes.length > 0">
				<store-shortcuts-code />
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { current_store, status, codes, codeDefault, maps } = composableStore();
			const { selectCodeDefault, selectTakeaway } = composableCart();

			const services = computed(() => (status.value?.type?.inmediate?.services ?? []));
			const typeLocal = computed(() => ('LOCAL' in services.value));
			const typeTakeaway = computed(() => ('TAKEAWAY' in services.value));
			const typeDelivery = computed(() => ('DELIVERY' in services.value));

			return { current_store, status, codes, maps, codeDefault, typeLocal, typeTakeaway, typeDelivery, selectCodeDefault, selectTakeaway };
		}
	}
</script>