import moment from 'moment';
import _ from 'lodash';

const DEFAULT = {
	info: null,
	status: null,
	gateways: [],
	codes: [],
	maps: [],
	discounts: [],
	sellers: [],
	catalog: {
		sections: [],
		items: []
	},
	expires_in: null
};

export default {
	namespaced: true,
	state: DEFAULT,
	getters: {
		isDefined(state) {
			return !!state.info;
		},
		isAdmin(state) {
			if(!state.info) return false;
			else return (_.indexOf(['owner', 'manager', 'operator'], state.info.pivot.role) !== -1);
		},
		info(state) {
			return state.info;
		},
		status(state) {
			return state.status;
		},
		gateways(state) {
			return state.gateways;
		},
		findServices: (state) => (type) => {
			return _.find(state.services, (row) => { return row.type == type });
		},
		discounts(state) {
			return state.discounts;
		},
		sellers(state) {
			return state.sellers;
		},
		codes(state) {
			return state.codes;
		},
		maps(state) {
			return state.maps;
		},
		catalog(state) {
			return state.catalog;
		},
		allowOrderRequests(state) {
			var available_services = _.find(state.services, (service) => { return service.status == 'available'; });
			return (available_services != undefined);
		},
		allowImmediateRequests(state) {
			var available_immediate = _.find(state.services, (service) => {
				if(service.status == 'unavailable') return false;
				return _.find(service.options, (option) => { return (option.type == 'immediate' && option.status == 'available'); });
			});

			return (available_immediate != undefined);
		},
		allowScheduledRequests(state) {
			var available_scheduled = _.find(state.services, (service) => {
				if(service.status == 'unavailable') return false;
				return _.find(service.options, (option) => { return (option.type == 'scheduled' && option.status == 'available'); });
			});

			return (available_scheduled != undefined);
		},
		allowTakeAway(state, getters) {
			var service = getters.findServices('TAKEAWAY');
			return (service != undefined && service.status == 'available');
		},
		expires_in(state) {
			return state.expires_in;
		}
	},
	actions: {
		destroy({ commit }) {
			commit('destroy');
		},
		setInfo({ commit }, info) {
			commit('setInfo', info);
		},
		setStatus({ commit }, status) {
			commit('setStatus', status);
		},
		setGateways({ commit }, gateways) {
			commit('setGateways', gateways);
		},
		syncGateway({ dispatch, state }, gateway) {
			var list = _.clone(state.gateways);

			_.forEach(list, (o, key) => {
				if(o.id == gateway.id) {
					list[key].config = gateway.config;
				}
			});

			dispatch('setGateways', list);
		},
		setDiscounts({ commit }, discounts) {
			commit('setDiscounts', discounts);
		},
		syncDiscount({ dispatch, state }, discount) {
			var list = _.clone(state.discounts);
			var hasDiscount = false;

			_.forEach(list, (o, key) => {
				if(o.id == discount.id) {
					hasDiscount = true;
					list[key] = discount;
				}
			});

			if(!hasDiscount) list.push(discount);

			dispatch('setDiscounts', list);
		},
		removeDiscount({ dispatch, state }, discount) {
			var list = _.filter(state.discounts, (data) => { return data.id !== discount.id; });

			dispatch('setDiscounts', list);
		},
		setSellers({ commit }, sellers) {
			commit('setSellers', sellers);
		},
		setCodes({ commit }, codes) {
			commit('setCodes', codes);
		},
		syncCode({ dispatch, state }, code) {
			var list = _.clone(state.codes);
			var hasCode = false;

			_.forEach(list, (o, key) => {
				if(o.id == code.id) {
					hasCode = true;
					list[key] = code;
				}
			});

			if(!hasCode) list.push(code);

			dispatch('setCodes', list);
		},
		removeCode({ dispatch, state }, code) {
			var list = _.filter(state.codes, (data) => { return data.id !== code.id; });

			dispatch('setCodes', list);
		},
		setMaps({ commit }, maps) {
			commit('setMaps', maps);
		},
		syncMap({ dispatch, state }, map) {
			var list = _.clone(state.maps);
			var hasMap = false;

			_.forEach(list, (o, key) => {
				if(o.id == map.id) {
					hasMap = true;
					list[key] = map;
				}
			});

			if(!hasMap) list.push(map);

			dispatch('setMaps', list);
		},
		removeMap({ dispatch, state }, map) {
			var list = _.filter(state.maps, (data) => { return data.id !== map.id; });

			dispatch('setMaps', list);
		},
		setSections({ commit }, sections) {
			commit('setSections', sections);
		},
		setItems({ commit }, items) {
			commit('setItems', items);
		},
		setExpires({ commit }) {
			commit('setExpires',  moment().add(15, 'minutes').format());
		}
	},
	mutations: {
		destroy(state) {
			Object.assign(state, DEFAULT);
		},
		setInfo(state, info) {
			state.info = info;
		},
		setStatus(state, status) {
			state.status = status;
		},
		setGateways(state, gateways) {
			state.gateways = gateways;
		},
		setDiscounts(state, discounts) {
			state.discounts = discounts;
		},
		setSellers(state, sellers) {
			state.sellers = sellers;
		},
		setCodes(state, codes) {
			state.codes = codes;
		},
		setMaps(state, maps) {
			state.maps = maps;
		},
		setSections(state, sections) {
			state.catalog.sections = sections;
		},
		setItems(state, items) {
			state.catalog.items = items;
		},
		setExpires(state, expires_in) {
			state.expires_in = expires_in;
		}
	}
}