<template>
	<div class="container">
		<div class="min-h-screen d-flex flex-column justify-content-center position-relative">
			<div class="text-center">
				<img src="@/assets/img/logo/big-white.png" alt="Logo" class="logo-big-white" />
			</div>
			<slot />
		</div>
	</div>
</template>

<script>
	export default {
		setup() {
			document.body.className = 'layouts-cart-complete';
		}
	}
</script>

<style>
	.layouts-cart-complete {
		background-color: #00ff00 !important;
	}

	.layouts-cart-complete .container {
		max-width: 450px !important;
	}

	.logo-big-white {
		width: 100px;
	}
</style>