import moment from 'moment';
import _ from 'lodash';

const DEFAULT = {
	store: null,
	submitting: false,
	catalogType: null,
	code: null,
	type: null,
	when: null,
	payments: [],
	seller_id: null,
	description: null,
	phone: null,
	comments: null,
	shippingCost: null,
	address: null,
	promocode: null,
	items: [],
	subtotal: 0,
	discount: 0,
	delivery_amount: 0,
	total: 0
};

export default {
	namespaced: true,
	state: DEFAULT,
	getters: {
		isDefined(state) {
			return !!state.store;
		},
		isEmpty(state) {
			return (state.items.length === 0);
		},
		store(state) {
			return state.store;
		},
		submitting(state) {
			return state.submitting;
		},
		catalogType(state) {
			return state.catalogType;
		},
		code(state) {
			return state.code;
		},
		type(state) {
			return state.type;
		},
		when(state) {
			return state.when;
		},
		payments(state) {
			return state.payments;
		},
		seller_id(state) {
			return state.seller_id;
		},
		description(state) {
			return state.description;
		},
		phone(state) {
			return state.phone;
		},
		comments(state) {
			return state.comments;
		},
		shippingCost(state) {
			return state.shippingCost;
		},
		address(state) {
			return state.address;
		},
		promocode(state) {
			return state.promocode;
		},
		hasPromocode(state) {
			return (state.promocode != null);
		},
		items(state) {
			return state.items;
		},
		itemTotalByIndex: (state) => (index) => {
			var total = 0,
				item = state.items[index];

			if(!item) return 0;

			total = item.unit_price;
			_.forEach(item.additionals, (additional) => { total+= additional.unit_price * additional.quantity; });

			return (total * item.quantity) - ((item.discount) ? item.discount : 0);
		},
		subtotal(state) {
			return state.subtotal;
		},
		discount(state) {
			return state.discount;
		},
		deliveryAmount(state) {
			return state.delivery_amount;
		},
		total(state) {
			return state.total;
		}
	},
	actions: {
		destroy({ commit }) {
			commit('destroy');
		},
		setStore({ commit }, store) {
			commit('setStore', store);
		},
		setItems({ state, dispatch, commit, rootGetters }, new_items = []) {
			var discounts = rootGetters['store/discounts'];
			var items_original = _.clone(new_items);
			var items = _.clone(new_items);
			var items_remaining = [];

			//Establece el índice
			_.forEach(items, (item, index) => { item.index = index; });
			items = _.sortBy(items, [function(item) {
				var total = item.unit_price;
				_.forEach(item.additionals, (additional) => { total+= additional.unit_price * additional.quantity; });
				return total * -1;
			}]);

			_.forEach(items, (item) => {
				var discount = _.find(discounts, (data) => {
					if(_.indexOf(data.catalogs, state.catalogType) === -1) return false;
					if(data.from && moment(data.from).isAfter(moment())) return false;
					if(data.to && moment(data.to).isBefore(moment())) return false;
					if(data.days && _.indexOf(data.days, moment().day()) === -1) return false;

					return (data.target == null || _.indexOf(data.target, item.id) != -1);
				});
				items_original[item.index].discount = 0;

				if(discount) {
					var unit_total = item.unit_price;
					_.forEach(item.additionals, (additional) => { unit_total+= additional.unit_price * additional.quantity; });

					switch(discount.type) {
						case 'PERCENTAGE':
							items_original[item.index].discount = Math.round(((unit_total * item.quantity) * (discount.value / 100)) * 100) / 100;
							break;
						case 'PRODUCTS':
							var combination = null;
							switch(discount.products_combination) {
								case 'SAME': combination = `${discount.id}-${item.id}`; break;
								case 'VALUE': combination = `${discount.id}-${item.unit_price}`; break;
								case 'ANY': combination = `${discount.id}`; break;
							}

							if(!(combination in items_remaining)) items_remaining[combination] = 0;

							var quantity_computed = item.quantity + items_remaining[combination];
							var factor = Math.trunc(quantity_computed / discount.products_from);
							var free = factor * (discount.products_from - discount.products_to);

							if(free) items_original[item.index].discount = free * unit_total;
							items_remaining[combination] = quantity_computed - (discount.products_from * factor);
							break;
					}
				}
			});

			commit('setItems', items_original);
			dispatch('calculateSubtotal');
		},
		setSubmitting({ commit }, submitting) {
			commit('setSubmitting', submitting);
		},
		setCatalogType({ commit }, catalogType) {
			commit('setCatalogType', catalogType);
		},
		setCode({ commit }, code) {
			commit('setCode', code);
		},
		setType({ dispatch, commit }, type) {
			commit('setType', type);
			dispatch('calculateSubtotal');
		},
		setWhen({ commit }, when) {
			commit('setWhen', when);
		},
		setPayments({ dispatch, commit }, payments) {
			commit('setPayments', payments);
			dispatch('calculateSubtotal');
		},
		setSellerId({ commit }, seller_id) {
			commit('setSellerId', seller_id);
		},
		setDescription({ commit }, description) {
			commit('setDescription', description);
		},
		setPhone({ commit }, phone) {
			commit('setPhone', phone);
		},
		setComments({ commit }, comments) {
			commit('setComments', comments);
		},
		setShippingCost({ commit }, shippingCost) {
			commit('setShippingCost', shippingCost);
		},
		setAddress({ commit }, address) {
			commit('setAddress', address);
		},
		setPromocode({ commit, dispatch }, promocode) {
			commit('setPromocode', promocode);
			if(!promocode) commit('setDiscount', 0);
			dispatch('calculateDiscount');
		},
		setDiscount({ commit, dispatch }, discount) {
			commit('setDiscount', discount);
			dispatch('calculateTotal');
		},
		addItem({ dispatch, state }, item) {
			var items = _.clone(state.items);

			//Busca un item igual
			var has_item = false;
			if(!item.comments) _.forEach(items, (current) => {
				if(!has_item && !current.comments && _.isEqual(_.pick(item, ['id', 'additionals']), _.pick(current, ['id', 'additionals']))) {
					current.quantity+= item.quantity;
					has_item = true;
				}
			});

			if(!has_item) items.push(item);

			dispatch('setItems', items);
		},
		subItem({ dispatch, state }, id) {
			var items = _.clone(state.items);
			var index = _.findIndex(items, function(data) { return (data.id == id); });

			if(items[index].quantity > 1) items[index].quantity-= 1;
			else items.splice(index, 1);

			dispatch('setItems', items);
		},
		deleteItem({ dispatch, state }, index) {
			var items = _.clone(state.items);
			items.splice(index, 1);
			dispatch('setItems', items);
		},
		calculateSubtotal({ state, dispatch, commit, getters }) {
			var subtotal = 0;
			_.forEach(state.items, (item, key) => { subtotal+= getters.itemTotalByIndex(key); });

			commit('setSubtotal', subtotal);
			dispatch('calculateDiscount');
		},
		calculateDiscount({ state, dispatch, commit }) {
			var discount = 0;

			if(state.promocode) {
				switch(state.promocode.type) {
					case 'PERCENTAGE':
						discount = Math.round((state.subtotal * (state.promocode.value / 100)) * 100) / 100;
						break;
					case 'AMOUNT':
						discount = (state.subtotal < state.promocode.value) ? state.subtotal : state.promocode.value;
						break;
				}

				commit('setDiscount', discount);
			}else if(state.discount > state.subtotal) commit('setDiscount', state.subtotal);

			dispatch('calculateDeliveryAmount');
		},
		calculateDeliveryAmount({ state, dispatch, commit }) {
			var delivery_amount = 0;

			if(state.type?.option == 'DELIVERY' && state.shippingCost) {
				if(!state.shippingCost?.fee_free || state.subtotal < state.shippingCost?.fee_free) delivery_amount = state.shippingCost?.fee_base;
			}

			commit('setDeliveryAmount', delivery_amount);
			dispatch('calculateTotal');
		},
		calculateTotal({ state, commit }) {
			var total = state.subtotal - state.discount + state.delivery_amount;

			commit('setTotal', total);
		}
	},
	mutations: {
		destroy(state) {
			Object.assign(state, DEFAULT);
		},
		setStore(state, store) {
			state.store = store;
		},
		setSubmitting(state, submitting) {
			state.submitting = submitting;
		},
		setCatalogType(state, catalogType) {
			state.catalogType = catalogType;
		},
		setCode(state, code) {
			state.code = code;
		},
		setType(state, type) {
			state.type = type;
		},
		setWhen(state, when) {
			state.when = when;
		},
		setPayments(state, payments) {
			state.payments = payments;
		},
		setSellerId(state, seller_id) {
			state.seller_id = seller_id;
		},
		setDescription(state, description) {
			state.description = description;
		},
		setPhone(state, phone) {
			state.phone = phone;
		},
		setComments(state, comments) {
			state.comments = comments;
		},
		setShippingCost(state, shippingCost) {
			state.shippingCost = shippingCost;
		},
		setAddress(state, address) {
			state.address = address;
		},
		setPromocode(state, promocode) {
			state.promocode = promocode;
		},
		setItems(state, items) {
			state.items = items;
		},
		setSubtotal(state, subtotal) {
			state.subtotal = subtotal;
		},
		setDiscount(state, discount) {
			state.discount = discount;
		},
		setDeliveryAmount(state, delivery_amount) {
			state.delivery_amount = delivery_amount;
		},
		setTotal(state, total) {
			state.total = total;
		}
	}
}