<template>
	<div class="card border">
		<div class="card-header border-bottom">
			<h4 class="modal-title">Cargar pedido</h4>
			<small class="d-block text-muted">Seleccioná el tipo de pedido</small>
		</div>
		<ul class="list-group list-group-flush">
			<button type="button" class="list-group-item fw-bold" @click="selectCodeDefault" v-if="codeDefault && typeLocal">En local: <b>{{ codeDefault.description }}</b></button>
			<button type="button" class="list-group-item fw-bold" @click="selectTakeaway" v-if="typeTakeaway">Take away</button>
			<button type="button" class="list-group-item fw-bold" @click="redirectDelivery" v-if="typeDelivery">Delivery</button>
			<button type="button" class="list-group-item fw-bold text-warning" @click="modalShow">Más opciones</button>
		</ul>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { status, codes, codeDefault, maps } = composableStore();
			const { selectCodeDefault, selectTakeaway, redirectDelivery, redirectCamera, redirectCode, redirectMap, modalShow } = composableCart();

			const services = computed(() => (status.value?.type?.inmediate?.services ?? []));
			const typeLocal = computed(() => ('LOCAL' in services.value));
			const typeTakeaway = computed(() => ('TAKEAWAY' in services.value));
			const typeDelivery = computed(() => ('DELIVERY' in services.value));

			return { status, codes, maps, codeDefault, typeLocal, typeTakeaway, typeDelivery, selectCodeDefault, selectTakeaway, redirectDelivery, redirectCamera, redirectCode, redirectMap, modalShow };
		}
	}
</script>