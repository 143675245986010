<template>
	<form class="card" @submit.prevent="modalConfirm">
		<div class="card-header h4 fw-bolder">Cerrar caja</div>
		<div class="card-body">
			<div class="row">
				<div guide="cashbox-close-user" class="col-12 mb-5">
					<label class="form-label">Encargado</label>
					<user-card-main :user="cashbox.user" />
				</div>
				<div guide="cashbox-close-balance" class="col-12 mb-5">
					<label class="form-label">Saldo sugerido</label>
					<div class="h4 fw-bolder d-block">{{ vueNumberFormat(cashbox.balance.calculated, $personalizer.currency.value) }}</div>
				</div>
				<div guide="cashbox-close-input-amount" class="col-12 mb-5">
					<label for="amount" class="form-label">Saldo final</label>
					<VueNumberFormat id="amount" :options="Object.assign({ acceptNegative: false }, $personalizer.currency.value)" class="form-control" v-model:value="amount" :class="{'is-invalid': validation.amount.$error}" :disabled="submitting"></VueNumberFormat>
					<span class="mt-1 invalid-feedback" v-for="error of validation.amount.$errors" :key="error.$uid">{{ error.$message }}</span>
				</div>
				<div guide="cashbox-close-input-observations" class="col-12">
					<label class="form-label" for="observations">Observaciones <span class="text-muted">(Opcional)</span></label>
					<textarea id="observations" v-model="observations" class="form-control" placeholder="Ingresá una observación" :disabled="submitting" style="height: 80px"></textarea>
				</div>
				<div class="col-12 mt-5 text-center" v-if="validationExternal.length > 0">
					<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
				</div>
			</div>
		</div>
		<div class="card-footer d-flex justify-content-between">
			<button type="button" guide="cashbox-close-button-flow" class="btn btn-warning" :disabled="submitting" @click="modalFlowCreateShow">Movimiento</button>
			<button type="submit" guide="cashbox-close-button-submit" class="btn btn-danger" :disabled="submitting">Cerrar caja</button>
		</div>
	</form>

	<common-modal-confirm title="Cerrar caja" :description="description" @confirm="submit" />
	<cashbox-flow-create :flow_types="cashbox.flow_types" @reload="$emit('reload')" />
</template>

<script>
	import { reactive, toRefs, computed } from 'vue';
	import store from '@/store';
	import useVuelidate from '@vuelidate/core';
	import { required, minValue } from '@/helpers/i18n/validators';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';
	import composableRedirect from '@/composables/redirect';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import CashboxRepository from '@/repositories/CashboxRepository';
	import { printCashbox } from '@/helpers/Printpoint';

	export default {
		emits: ['reload'],
		props: {
			cashbox: Object
		},
		setup(props) {
			const orders = computed(() => store.getters['orders/list']);
			const { redirectBack } = composableRedirect();
			const { current_store } = composableStore();
			const { submitting, validationExternal, catchError } = composableForm();
			const printers = computed(() => store.getters['config/printers'](current_store.value.id));

			const form = reactive({
				amount: 0,
				observations: null
			});

			const validation = useVuelidate({
				amount: {
					required,
					minValue: minValue(0)
				}
			}, form);

			const submit = async () => {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await CashboxRepository.update(current_store.value.id, props.cashbox.id, form)
					.then((response) => {
						submitting.value = false;
						var printer = printers.value?.cashbox;
						if(printer) printCashbox(response.data.id);

						redirectBack();
					})
					.catch(catchError);
				submitting.value = false;
			}

			const modalConfirm = () => {
				Modal.getInstance(document.getElementById('common-modals-confirm')).show();
			}

			const modalFlowCreateShow = () => {
				var modalFlowCreate = Modal.getInstance(document.getElementById('cashbox-flow-create'));
				modalFlowCreate.show();
			}

			const description = computed(() => {
				var filtered = _.filter(orders.value, (order) => { return (_.indexOf(['PENDING', 'ACCEPTED', 'DELIVER'], order.status) !== -1); }),
					extra = '';

				if(filtered.length == 1) extra = `<br /><span class="text-danger fw-bold">Hay 1 pedido pendiente de finalización.</span>`;
				else if(filtered.length >= 2) extra = `<br /><span class="text-danger fw-bold">Hay ${filtered.length} pedidos pendientes de finalización.</span>`;

				return `¿Realmente desea cerrar la caja?${extra}`;
			});

			return { ...toRefs(form), validation, validationExternal, submitting, submit, modalConfirm, modalFlowCreateShow, description };
		}
	}
</script>