<template>
	<div class="card border-1 border-warning shadow shadow-3-hover">
		<!-- Card Body -->
		<div class="card-body p-3">
			<div class="d-flex align-items-center">
				<div class="pe-3">
					<div class="avatar avatar-lg rounded">
						<img :src="logo_default" alt="logo">
					</div>
				</div>
				<div>
					<a href="javascript:void(0);" class="h4 fw-semibold link-dark stretched-link d-block mb-1" @click="modalShow">{{ invitation.store.name }}</a>
					<div class="text-sm text-warning d-block info-extra">Invitación pendiente.</div>
				</div>
			</div>
		</div>
	</div>

	<invitation-modal-response :invitation="invitation" @loadStores="loadStores" />
</template>

<script>
	import { computed } from 'vue';
	import { Modal } from 'bootstrap';

	export default {
		emits: ['loadStores'],
		props: {
			invitation: Object
		},
		setup(props, { emit }) {
			const logo_default = computed(() => {
				if(props.invitation.store.logo) return props.invitation.store.logo_url;
				else return require('@/assets/img/icons/no-image.jpg');
			});

			const modalShow = () => {
				var modalResponse = Modal.getInstance(document.getElementById(`invitation-modal-response-${props.invitation.id}`));
				modalResponse.show();
			}

			const loadStores = () => emit('loadStores');

			return { logo_default, modalShow, loadStores };
		}
	}
</script>