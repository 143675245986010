<template>
	<div class="row">
		<div class="col-12 mb-6">
			<div class="alert border-warning text-warning d-flex align-items-center rounded px-2 py-1" role="alert">
				<i class="bi bi-info-circle me-2"></i>
				<div class="text-sm">
					<span class="fw-bold">Importante:</span> La caja contabilizará hasta un máximo de <b>{{ cashbox.time_limit }} horas</b>.
				</div>
			</div>
		</div>
		<div class="col-12 mb-6">
			<div class="d-block d-md-flex align-items-center justify-content-between">
				<div class="d-flex align-items-center">
					<div class="icon icon-shape bg-secondary text-white"><i class="bi bi-unlock text-lg"></i></div>
					<div class="ps-3">
						<h5 class="fw-semibold">{{ cashbox.user.fullname }} abrió la caja.</h5>
						<p class="text-muted">El día {{ createdAtFormatted }}.</p>
					</div>
				</div>
				<div class="w-100 w-md-auto text-center mt-3 mt-md-0">
					<span class="badge rounded-pill" :class="{ 'bg-success': (cashbox.balance.open >= 0), 'bg-danger': (cashbox.balance.open < 0) }">{{ vueNumberFormat(cashbox.balance.open, $personalizer.currency.value) }}</span>
				</div>
			</div>
		</div>
		<div class="col-12 mb-6">
			<div class="d-block d-md-flex align-items-center justify-content-between">
				<div class="d-flex align-items-center">
					<div class="icon icon-shape bg-success text-white"><i class="bi bi-cash text-lg"></i></div>
					<div class="ps-3">
						<h5 class="fw-semibold" v-if="cashbox.balance.payments_with_cash_in == 0">No se registraron pagos en efectivo.</h5>
						<h5 class="fw-semibold" v-if="cashbox.balance.payments_with_cash_in == 1">Se registró <b>{{ cashbox.balance.payments_with_cash_in }} pago</b> en efectivo.</h5>
						<h5 class="fw-semibold" v-if="cashbox.balance.payments_with_cash_in >= 2">Se registraron <b>{{ cashbox.balance.payments_with_cash_in }} pagos</b> en efectivo.</h5>
						<p class="text-muted" v-if="cashbox.balance.orders_rejecteds == 0">No se registraron pedidos rechazados.</p>
						<p class="text-muted" v-if="cashbox.balance.orders_rejecteds == 1">{{ cashbox.balance.orders_rejecteds }} pedido fue rechazado.</p>
						<p class="text-muted" v-if="cashbox.balance.orders_rejecteds >= 2">{{ cashbox.balance.orders_rejecteds }} pedidos fueron rechazados.</p>
					</div>
				</div>
				<div class="w-100 w-md-auto text-center mt-3 mt-md-0">
					<span class="badge rounded-pill" :class="{ 'bg-success': (cashbox.balance.payment_cash_in >= 0), 'bg-danger': (cashbox.balance.payment_cash_in < 0) }">{{ vueNumberFormat(cashbox.balance.payment_cash_in, $personalizer.currency.value) }}</span>
				</div>
			</div>
		</div>
		<div class="col-12 mb-6" v-if="cashbox.balance.payouts_total != 0">
			<div class="d-block d-md-flex align-items-center justify-content-between">
				<div class="d-flex align-items-center">
					<div class="icon icon-shape bg-dark text-white"><i class="bi bi-envelope-paper text-lg"></i></div>
					<div class="ps-3">
						<h5 class="fw-semibold">Rendiciones de dinero.</h5>
						<p class="text-muted">Durante la apertura de la caja.</p>
					</div>
				</div>
				<div class="w-100 w-md-auto text-center mt-3 mt-md-0">
					<span class="badge rounded-pill" :class="{ 'bg-success': (cashbox.balance.payouts_total >= 0), 'bg-danger': (cashbox.balance.payouts_total < 0) }">{{ vueNumberFormat(cashbox.balance.payouts_total, $personalizer.currency.value) }}</span>
				</div>
			</div>
		</div>
		<div class="col-12 mb-6">
			<div class="d-block d-md-flex align-items-center justify-content-between">
				<div class="d-flex align-items-center">
					<div class="icon icon-shape bg-warning text-white"><i class="bi bi-arrow-down-up text-lg"></i></div>
					<div class="ps-3">
						<h5 class="fw-semibold" v-if="cashbox.balance.cashbox_flows.length == 0">No se registraron movimientos de caja.</h5>
						<h5 class="fw-semibold" v-if="cashbox.balance.cashbox_flows.length == 1">Se registró <b>{{ cashbox.balance.cashbox_flows.length }} movimiento de caja</b>.</h5>
						<h5 class="fw-semibold" v-if="cashbox.balance.cashbox_flows.length >= 2">Se registraron <b>{{ cashbox.balance.cashbox_flows.length }} movimientos de caja</b>.</h5>
						<p class="text-muted">Durante la apertura de la caja.</p>
					</div>
				</div>
				<div class="w-100 w-md-auto text-center mt-3 mt-md-0">
					<span class="badge rounded-pill" :class="{ 'bg-success': (cashboxFlowsTotals >= 0), 'bg-danger': (cashboxFlowsTotals < 0) }">{{ vueNumberFormat(cashboxFlowsTotals, $personalizer.currency.value) }}</span>
				</div>
			</div>
		</div>
		<div class="col-12">
			<div class="d-block d-md-flex align-items-center justify-content-between">
				<div class="d-flex align-items-center">
					<div class="icon icon-shape bg-info text-white"><i class="bi bi-info-circle text-lg"></i></div>
					<div class="ps-3">
						<h5 class="fw-semibold">Saldo sugerido de cierre.</h5>
						<p class="text-muted">Calculado automáticamente.</p>
					</div>
				</div>
				<div class="w-100 w-md-auto text-center mt-3 mt-md-0">
					<span class="badge rounded-pill" :class="{ 'bg-success': (cashbox.balance.calculated >= 0), 'bg-danger': (cashbox.balance.calculated < 0) }">{{ vueNumberFormat(cashbox.balance.calculated, $personalizer.currency.value) }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';
	import _ from 'lodash';

	export default {
		props: {
			cashbox: Object
		},
		setup(props) {
			const createdAtFormatted = computed(() => moment(props.cashbox.created_at).format('D [de] MMMM, HH:mm[Hs]'));
			const cashboxFlowsTotals = computed(() => _.sumBy(props.cashbox.balance.cashbox_flows, 'amount'));

			return { createdAtFormatted, cashboxFlowsTotals };
		}
	}
</script>