<template>
	<div class="modal" id="modal-paymentmethods" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content shadow-3">
				<div class="modal-header">
					<h5 class="modal-title">Formas de pago aceptadas</h5>
					<div class="text-xs ms-auto">
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
				</div>
				<div class="modal-body py-0">
					<div class="list-group list-group-flush">
						<div class="list-group-item d-flex align-items-center px-0" v-if="allowCards">
							<div class="flex-fill">
								<div class="d-block text-sm fw-bold mb-1">Pagos online:</div>
								<div class="d-block text-sm">
									Tarjetas Visa, Mastercard, Maestro, Amex, y más!
								</div>
							</div>
						</div>
						<div class="list-group-item d-flex align-items-center px-0" v-if="allowCash">
							<div class="flex-fill">
								<div class="d-block text-sm fw-bold mb-1">Pagos en la entrega:</div>
								<div class="d-block text-sm">
									Puedes pagar en efectivo
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			payment_methods: Array
		},
		setup(props) {
			const allowCash = computed(() => {
				return _.find(props.payment_methods, (row) => { return row.type == 'cash' });
			});

			const allowCards = computed(() => {
				return _.find(props.payment_methods, (row) => { return row.type == 'card' });
			});

			return { allowCash, allowCards };
		}
	}
</script>