<template>
	<header class="bg-light border-bottom pt-6">
		<div class="container-fluid">
			<div class="mb-npx">
				<div class="row g-0 align-items-center">
					<div class="col-12 col-md">
						<div class="d-flex align-items-center gap-3">
							<div class="btn btn-link p-0 text-reset text-md"><i class="bi bi-person"></i></div>
							<div class="vr opacity-20 my-1"></div>
							<h1 class="h4 ls-tight">Editar perfil de usuario</h1>
						</div>
					</div>
				</div>
				<!-- Nav -->
				<ul class="nav nav-tabs mt-4 overflow-x border-0">
					<li class="nav-item ">
						<router-link :to="{name: 'store.user.settings.general', params: { store_id: current_store.id } }" class="nav-link" :class="{'active': (route.name == 'store.user.settings.general')}">General</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{name: 'store.user.settings.password', params: { store_id: current_store.id } }" class="nav-link" :class="{'active': (route.name == 'store.user.settings.password')}">Cambiar contraseña</router-link>
					</li>
				</ul>
			</div>
		</div>
	</header>
</template>

<script>
	import { useRoute } from 'vue-router';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const route = useRoute();
			const { current_store } = composableStore();

			return { route, current_store };
		}
	}
</script>