<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4">
			<div class="row g-0 align-items-center">
				<div class="col">
					<div class="d-flex align-items-center gap-3">
						<button type="button" class="btn btn-link p-0 text-reset text-md" :disabled="submitting" @click="destroy"><i class="bi bi-trash"></i></button>
						<div class="vr opacity-20 my-1"></div>
						<h1 class="h4 ls-tight">Catálogo</h1>
					</div>
				</div>
				<div class="col-auto">
					<div class="hstack gap-2 justify-content-end">
						<a guide="cart-header-catalog-button-detail" href="javascript:void(0);" class="d-flex align-items-center text-dark" @click="modalOrderDetail">
							<div class="text-muted fw-semibold d-none d-md-block me-2">Subtotal: {{ vueNumberFormat(subtotal, $personalizer.currency.value) }}</div>
							<div class="vr opacity-20 d-none d-md-block my-1"></div>
							<div class="text-muted fw-semibold d-none d-md-block ms-2 me-6">{{ `${quantity} ${(quantity == 1) ? 'Producto' : 'Productos'}` }}</div>
						</a>
						<button type="button" class="btn btn-sm btn-square btn-outline-warning d-block d-md-none" @click="modalOrderDetail">{{ quantity }}</button>
						<div guide="cart-header-catalog-button-next" class="btn-group btn-group-sm" role="group" aria-label="Basic example">
							<button type="button" class="btn btn-dark btn-square" :disabled="submitting" @click="finish" v-if="canFinish"><i class="bi bi-chevron-double-right"></i></button>
							<button type="button" class="btn btn-primary" :disabled="isEmpty || submitting" @click="toCheckout">
								Siguiente<i class="bi bi-chevron-right ps-1"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid nav-sections border-top px-5 py-3">
			<div class="d-flex align-items-center gap-3" v-if="searching">
				<div guide="cart-header-catalog-input-search" class="input-group input-group-sm input-group-inline">
					<span class="input-group-text ps-3" v-if="!hasSearchTerm"><i class="bi bi-search"></i></span>
					<button type="button" class="input-group-text ps-3" v-if="hasSearchTerm" @click="searchErase"><i class="bi bi-eraser-fill"></i></button>
					<input type="text" id="searchTerm" class="form-control" placeholder="Buscar..." v-focus :value="searchTerm" @input="$emit('update:searchTerm', $event.target.value)" @keydown.esc="$emit('searchShow', false)" @keydown.page-up.enter="$emit('addFirst')" @keydown.page-down="$emit('subFirst')" />
					<a href="javascript:void(0);" class="input-group-text px-3" @click="$emit('searchShow', false)"><i class="bi bi-x-lg"></i></a>
				</div>
			</div>
			<div class="d-flex align-items-center gap-2" v-if="!searching">
				<a guide="cart-header-catalog-button-search" href="javascript:void(0);" class="btn btn-tag" @click="$emit('searchShow', true)"><i class="bi bi-search"></i></a>
				<div class="vr opacity-20 my-1"></div>
				<ul guide="cart-header-catalog-button-sections" class="horizontal-tags nav">
					<li class="nav-item" v-for="section in sections" :id="`button-${section.id}`" v-bind:key="section.id">
						<a href="javascript:void(0);" class="btn btn-tag" :class="{'active': (onViewport.indexOf(section.id) !== -1)}" @click="showSection(section.id)">{{ section.name }}</a>
					</li>
				</ul>
			</div>
		</div>
	</header>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import VueScrollTo from 'vue-scrollto';
	import { Modal } from 'bootstrap';
	import composableCart from '@/composables/cart';

	export default {
		emits: ['searchShow', 'addFirst', 'subFirst', 'update:searchTerm'],
		props: {
			sections: Array,
			onViewport: Array,
			searching: Boolean,
			searchTerm: String,
			submitting: Boolean
		},
		setup(props, { emit }) {
			const { subtotal, quantity, isEmpty, canFinish, toCheckout, destroy, finish } = composableCart();

			const hasSearchTerm = computed(() => props.searchTerm.length > 0);
			const searchErase = () => {
				emit('update:searchTerm', '');
				document.querySelector('#searchTerm').focus();
			}

			const showSection = (id) => {
				VueScrollTo.scrollTo(`#section-${id}`, 150, { container: (window.innerWidth > 991) ? '.main-slot' : null, offset: -160 });
			}

			const modalDetail = ref(null);
			onMounted(() => modalDetail.value = new Modal(document.getElementById('cart-modal-detail')));
			const modalOrderDetail = () => modalDetail.value.show();

			/*
			const onViewport = ref([]);
			onMounted(() => {
				const observer = new IntersectionObserver(
					(entries) => {
						_.forEach(entries, (entry) => {
							var id = Number(entry.target.getAttribute('section-id'));

							if(entry.isIntersecting && _.indexOf(onViewport.value, id) === -1) {
								onViewport.value.push(id);
							}else if(!entry.isIntersecting) {
								var index = _.indexOf(onViewport.value, id);
								if(index !== -1) onViewport.value.splice(index, 1);
							}
						});

						setTimeout(() => {
							var el = document.querySelector('.horizontal-tags .btn.active:first-child');
							if(el) el.parentNode.parentNode.scrollLeft = el.offsetLeft - 79;
						}, 100);
					}, { root: document, rootMargin: '-240px 0px -130px 0px', }
				);

				_.forEach(document.getElementsByClassName('section-content'), (element) => observer.observe(element));
			});
			*/

			return { subtotal, quantity, isEmpty, canFinish, hasSearchTerm, searchErase, showSection, modalOrderDetail, toCheckout, destroy, finish };
		}
	}
</script>

<style scoped>
	.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
		padding: 0.45rem 0.875rem;
		font-size: 0.9rem;
	}
</style>