<template>
	<div>
		<header>
			<div class="container py-5">
				<div class="align-items-center">
					<common-button-back :redirect="buttonBack" />
				</div>
			</div>
		</header>
		<div class="container">
			<div class="justify-content-center">
				<div class="d-flex flex-column position-relative">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			buttonBack: Object
		},
		setup() {
			document.body.className = 'layouts-store-minimum';
		}
	}
</script>

<style>
	.layouts-store-minimum .container {
		max-width: 650px !important;
	}
</style>