import { ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import qz from 'qz-tray';
import { printpoint } from '@/helpers/Printpoint';
import qzDisconnected from '@/components/common/notification/qz/disconnected';
import cert from '!raw-loader!@/assets/certs/qz.txt';

const toast = useToast();

qz.security.setCertificatePromise((resolve) => resolve(cert));
qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
qz.security.setSignaturePromise(function(toSign) {
	return function(resolve, reject) {
		fetch(`${process.env.VUE_APP_URL}/api/qz/signature?request=${toSign}`, {cache: 'no-store', headers: {'Content-Type': 'text/plain'}}).then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });
	};
});

export const status = ref(null);
export const printers = ref([]);

export const install = () => {
}

export const connect = () => {
	status.value = 'pending';

	qz.websocket.connect().then(() => {
		status.value = 'connected';
		getPrinters();
	}).catch(() => status.value = 'disconnected');
}

export const getPrinters = () => {
	qz.printers.find().then((list) => {
		printers.value = list;
	});
}

export const print = async (printer, code) => {
	var config = qz.configs.create(printer, {encoding: 'Cp850'});

	return qz.print(config, decode(code)).catch(() => {
		toast.error(`Error al imprimir en impresora "${printer}", verifique la conexión de la impresora.`);
		return Promise.reject();
	});
}

const decode = (commands) => {
	var code_print = [],
		code_text;

	//Inicio
	code_print.push('\x1B' + '\x40');

	//Analiza cada línea
	commands.forEach(value => {
		switch(value.type) {
			case 'text':
				code_text = value.content;

				//Alineación
				if('align' in value.format) {
					switch(value.format.align) {
						case 'center':
							code_text = '\x1B' + '\x61' + '\x31' + code_text;
							break;
						case 'right':
							code_text = '\x1B' + '\x61' + '\x32' + code_text;
							break;
						case 'left':
							code_text = '\x1B' + '\x61' + '\x30' + code_text;
							break;
					}
				}else code_text = '\x1B' + '\x61' + '\x30' + code_text;

				//Negrita
				if(value.format.bold) code_text = '\x1B' + '\x45' + '\x0D' + code_text + '\x1B' + '\x45' + '\x0A';

				//Tamaño
				switch(value.format.size) {
					case 'large':
						code_text = '\x1D' + '\x21' + '\x11' + code_text;
						break;
					case 'medium':
						code_text = '\x1D' + '\x21' + '\x01' + code_text;
						break;
					case 'small':
						code_text = '\x1D' + '\x21' + '\x00' + code_text;
						break;
				}

				code_print.push(code_text + '\x0A');
				break;
			case 'line_break':
				code_print.push('\x0A');
				break;
			case 'barcode':
				//barcode data
				var code = value.content;

				//convenience method
				var chr = function(n) { return String.fromCharCode(n); };

				var barcode = '\x1B' + '\x61' + '\x31';				
					barcode+= '\x1D' + 'h' + chr(80);					//barcode height
					barcode+= '\x1D' + 'f' + chr(0);					//font for printed number
					barcode+= '\x1D' + 'k' + chr(5) + code + chr(0);	//ITF

				code_print.push(barcode);
				code_print.push('\x1B' + '\x61' + '\x31' + code + '\x0A');

				code_text = '\x1B' + '\x61' + '\x31' + code_text;
				break;
			case 'qrcode':
				//qrcode data
				var qr = value.content;

				// The dot size of the QR code
				var dots = '\x05';

				// Some proprietary size calculation
				var qrLength = qr.length + 3;
				var size1 =  String.fromCharCode(qrLength % 256);
				var size0 = String.fromCharCode(Math.floor(qrLength / 256));

				code_print.push('\x1B' + '\x61' + '\x31');															// QR Code Center
				code_print.push('\x1D' + '\x28' + '\x6B' + '\x04' + '\x00' + '\x31' + '\x41' + '\x32' + '\x00');	// <Function 165> select the model (model 2 is widely supported)
				code_print.push('\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x43' + dots);				// <Function 167> set the size of the module
				code_print.push('\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x45' + '\x30');				// <Function 169> select level of error correction (48,49,50,51) printer-dependent
				code_print.push('\x1D' + '\x28' + '\x6B' + size1 + size0 + '\x31' + '\x50' + '\x30' + qr);			// <Function 080> send your data (testing 123) to the image storage area in the printer
				code_print.push('\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x51' +'\x30');				// <Function 081> print the symbol data in the symbol storage area
				code_print.push('\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x52' +'\x30');				// <Function 082> Transmit the size information of the symbol data in the symbol storage area
				break;
		}
	});

	//Deja un espacio en la parte inferior
	code_print.push('\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A');

	//Cortar papel
	code_print.push('\x1B' + '\x69');

	//Retorna el código
	return code_print;
}

watch(status, (newStatus) => {
	if(newStatus == 'disconnected' && printpoint.value) toast.error({ component: qzDisconnected }, { position: 'top-center', transition: 'Vue-Toastification__fade', timeout: 10000 });
});

connect();