<template>
	<table class="table table-sm table-borderless">
		<tbody>
			<payment-row-multiple v-for="(payment, index) in payments" v-bind:key="`${renderization}-${index}`" :submitting="submitting" :index="index" :payment="payment" @setPayment="setPayment" @subPayment="subPayment" />
		</tbody>
	</table>
	<div class="alert border-warning text-warning d-flex align-items-center rounded px-2 py-1 mt-2" role="alert" v-if="balance != 0">
		<i class="bi bi-x-circle-fill me-2"></i>
		<div class="text-sm">
			<span class="fw-bold pe-1">¡Atención!</span>
			<span v-if="balance < 0">los pagos son <b>menores</b> que el total ({{ vueNumberFormat(Math.abs(balance), $personalizer.currency.value) }})</span>
			<span v-if="balance > 0">los pagos son <b>mayores</b> que el total ({{ vueNumberFormat(Math.abs(balance), $personalizer.currency.value) }})</span>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['setPayment', 'subPayment'],
		props: {
			submitting: Boolean,
			payments: Array,
			total: Number,
			renderization: Number
		},
		setup(props, { emit }) {
			const balance = computed(() => _.sumBy(props.payments, 'amount') - props.total);
			const setPayment = (data) => emit('setPayment', data);
			const subPayment = (index) => emit('subPayment', index);

			return { balance, setPayment, subPayment };
		}
	}
</script>