<template>
	<div class="card shadow-1 border rounded-2">
		<div class="checkout-option d-flex justify-content-between position-relative" v-for="payment_method in current_store.payment_methods" v-bind:key="payment_method.type">
			<div class="form-check">
				<input type="radio" v-model="method" :value="payment_method.type" class="form-check-input" :disabled="payment?.can_modify === false || submitting">
				<div class="form-check-label text-dark d-block ms-1">
					<a href="javascript:void(0)" class="stretched-link text-dark" @click="selectMethod(payment_method)">{{ payment_method.title }}</a>
					<small class="d-block" v-if="method == payment_method.type && description" v-html="description"></small>
				</div>
			</div>
			<a href="javascript:void(0)" class="text-dark stretched-link position-relative" v-if="payment?.can_modify !== false && method == payment_method.type && method == 'cash'" @click="menuCashShow">Editar</a>
		</div>
	</div>

	<payment-menu-cash :total="total" @setCash="setCash" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import composableStore from '@/composables/store';
	import { Offcanvas } from 'bootstrap';

	export default {
		emits: ['setPayment'],
		props: {
			submitting: Boolean,
			payment: Object,
			total: Number
		},
		setup(props, { emit }) {
			const { current_store } = composableStore();

			const method = computed(() => {
				return props.payment?.method;
			});

			const selectMethod = (payment_method) => {
				if(props.payment?.can_modify === false) return;
				var data = { id: null, method: payment_method.type, amount: null, value: null, can_modify: true };

				switch(payment_method.type) {
					case 'cash':
						data.option = 'exact';
				}

				emit('setPayment', { index: 0, data: data });
			}

			const description = computed(() => {
				var description = null;

				switch(props.payment.method) {
					case 'cash':
						switch(props.payment?.option) {
							case 'exact': description = `Paga <b>con importe exacto</b>`; break;
							case 'other': description = `Paga <b>con $ ${props.payment.value}</b>`; break;
						}
						break;
				}

				return description;
			});

			const menuCash = ref(null);
			onMounted(() => menuCash.value = new Offcanvas(document.getElementById('payment-menu-cash')));

			const menuCashShow = () => {
				if(props.submitting) return;
				menuCash.value.show();
			}

			const setCash = (data) => {
				if(props.payment?.can_modify === false) return;
				emit('setPayment', { index: 0, data: data });
				menuCash.value.hide();
			}

			return { current_store, method, selectMethod, description, menuCashShow, setCash };
		}
	}
</script>