import { computed, watch } from 'vue';
import store from '@/store';
import _ from 'lodash';
import { useToast } from 'vue-toastification';

export default function() {
	const toast = useToast();
	const audio = new Audio(require(`@/assets/sound/alert.mp3`));
	const payouts = computed(() => _.filter(store.getters['payouts/list'], { status: 'PENDING' }));

	watch(payouts, (newValue) => {
		if(newValue.length > 0) {
			toast.warning(`Nuevas rendiciones de dinero.`);
			audio.play();
		}
	});
}
