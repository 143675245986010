<template>
	<div class="w-md-75" v-if="block.from == 'core'">
		<!-- Name -->
		<div class="d-flex align-items-center align-self-center">
			<span class="pe-1"><i class="bi" :class="iconClass"></i></span>
			<span class="d-block lh-1 text-sm fw-semibold text-heading text-truncate">Asistente</span>
			<span class="d-block lh-1 mx-3 text-xs text-muted">{{ createdAtForHummans }}</span>
		</div>
		<!-- Text -->
		<div class="d-flex mt-1" v-for="message in block.messages" v-bind:key="`message-${message.id}`">
			<div :id="`message-${message.id}`" class="px-4 py-3 rounded-bottom-3 rounded-top-end-3 text-sm bg-secondary bg-opacity-10">{{ message.message }}</div>
		</div>
	</div>
	<div class="w-md-75 flex-row-reverse ms-auto" v-if="block.from == 'store'">
		<!-- Name -->
		<div class="d-flex align-items-center align-self-center flex-row-reverse">
			<span class="ps-1"><i class="bi" :class="iconClass"></i></span>
			<span class="d-block lh-1 text-sm fw-semibold text-heading text-end text-truncate">{{ current_store.name }}</span>
			<span class="d-block lh-1 mx-3 text-xs text-muted text-end">{{ createdAtForHummans }}</span>
		</div>
		<!-- Text -->
		<div class="d-flex flex-row-reverse mt-1" v-for="message in block.messages" v-bind:key="`message-${message.id ?? message.hash}`">
			<div :id="`message-${message.id}`" class="px-3 py-1 rounded-bottom-2 rounded-top-start-2 text-sm bg-dark text-white d-flex align-items-center">
				{{ message.message }}
				<i class="bi ps-2" :class="{ 'bi-clock-history': !message.syncError, 'bi-exclamation-diamond': message.syncError }" v-if="'synchronizing' in message"></i>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';
	import moment from 'moment';
	import composableStore from '@/composables/store';

	export default {
		props: {
			ticket: Object,
			block: Object
		},
		setup(props) {
			const { current_store } = composableStore();
			const last = computed(() => _.last(props.block.messages));
			const createdAtForHummans = computed(() => moment(last.value.created_at).format('hh:mm A'));
			const iconClass = computed(() => {
				if(last.value.read_by.length == 0) return 'bi bi-check2';
				else if(last.value.read_by.length > 0) return `bi bi-check2-all ${(props.ticket.members.length == last.value.read_by.length) ? 'text-success' : ''}`;
				else return 'bi bi-check2';
			});

			return { current_store, createdAtForHummans, iconClass };
		}
	}
</script>