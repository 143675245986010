<template>
	<transition name="block" mode="out-in">
		<div class="d-flex h-100 justify-content-center position-relative mx-auto text-center align-items-center" v-if="show">
			<img :src="logo" alt="logo">
			<h2 class="ms-3">{{ text }}</h2>
		</div>
	</transition>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';

	export default {
		props: {
			text: String
		},
		setup(props) {
			const show = ref(false);

			const logo = computed(() => {
				if(props.text) return require('@/assets/img/logo/dark-simple.png');
				return require('@/assets/img/logo/dark-complete.png');
			});

			onMounted(() => {
				show.value = true;
			});

			return { show, logo };
		}
	}
</script>

<style scoped>
	img {
		display: block;
		max-height:45px;
		width: auto;
		height: auto;
	}

	.block-enter-from { opacity: 0; }
	.block-enter-to { opacity: 1; }

	.block-enter-active, .block-leave-active { transition: all 3s ease; }
</style>