<template>
	<layouts-basic>
		<form @submit.prevent="submit">
			<div class="mb-7 text-center">
				<div class="d-block mb-2"><i class="display-1 text-dark" :class="targetIcon"></i></div>
				<div class="d-block h3">Recuperá tu acceso</div>
				<div class="d-block mb-2">Ingresa el código de verificación que enviamos a</div>
				<div class="d-block h4"><strong>{{ targetFormatted }}</strong></div>
			</div>
			<div class="mb-3 text-center">
				<user-phone-input :length="codeLength" @updated="updated" ref="inputsComponent" />
			</div>
			<div class="mb-3 text-center" v-if="validationExternal.length > 0 || validation.code.$errors.length > 0">
				<div class="invalid-feedback d-block" v-for="error of validation.code.$errors" v-bind:key="error.$uid">{{ error.$message }}</div>
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div class="mt-5">
				<button type="submit" class="btn btn-primary w-100" :disabled="sendingCode || submitting">Verificar</button>
			</div>
			<div class="mt-5">
				<button type="submit" class="btn btn-outline-warning w-100" :disabled="sendingCode || !canRequestCode" @click="requestCode">{{ resendDescription }}</button>
			</div>
			<div class="mt-5">
				<router-link :to="{name: targetChangeRoute}" class="btn btn-neutral w-100">{{ targetChangeDescription }}</router-link>
			</div>
		</form>
	</layouts-basic>
</template>

<script>
	import { toRefs, computed, onMounted, onBeforeUnmount } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { useRoute } from 'vue-router';
	import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
	import OtpRepository from '@/repositories/OtpRepository';
	import composableOtp from '@/composables/otp';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';
	import moment from 'moment';

	export default {
		setup() {
			const { query: { target_type, target, issued_for } } = useRoute();
			const { form, timer, codeLength, sendingCode, inputsComponent, canRequestCode, resendDescription, validation } = composableOtp();
			const { redirectBack } = composableRedirect();
			const { submitting, validationExternal, catchError } = composableForm();

			async function submit() {
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				store.dispatch('auth/otp', {
					target_type: target_type,
					target: target,
					received_code: form.code,
					issued_for: issued_for
				}).then(onSuccess).catch(catchError);
			}

			const onSuccess = () => {
				store.dispatch('auth/me').then(() => redirectBack());
			}

			const targetFormatted = computed(() => {
				if(target_type == 'phone' || isValidPhoneNumber(target)) return parsePhoneNumber(target).formatNational();
				return target;

			});

			const targetIcon = computed(() => {
				return (target_type == 'phone') ? 'bi bi-phone' : 'bi bi-envelope';
			});

			const targetChangeDescription = computed(() => {
				return (target_type == 'phone') ? 'Corregir número de teléfono' : 'Corregir email';
			});

			const targetChangeRoute = computed(() => {
				return (target_type == 'phone') ? 'user.forgot.phone' : 'user.forgot.email';
			});

			const updated = (data) => {
				form.code = data;
				validationExternal.value = [];
			}

			const requestCode = () => {
				if(!canRequestCode.value) return;

				inputsComponent.value.setCode(null);
				sendingCode.value = true;

				OtpRepository.store({
						target_type: target_type,
						target: target,
						issued_for: issued_for
					})
					.then((response) => {
						timer.count = response.data.expires_in;
						timer.expires_in = moment(response.data.expires_in * 1000);
						validationExternal.value = [];
						sendingCode.value = false;
					})
					.catch((e) => {
						catchError(e, true);
						sendingCode.value = false;
						router.push({name: `user.forgot.${target_type}`});
					});
			}

			var ac = null;

			onMounted(() => {
				requestCode();

				if(target_type == 'phone' && 'OTPCredential' in window) {
					ac = new AbortController();

					navigator.credentials.get({
						otp: { transport: ['sms'] },
						signal: ac.signal
					}).then(otp => {
						inputsComponent.value.setCode(otp.code);
						submit();
					});
				}
			});

			onBeforeUnmount(() => {
				if(ac) ac.abort();
			});

			if(!target_type || !target || !issued_for) return redirectBack();

			return { ...toRefs(form), inputsComponent, codeLength, validation, validationExternal, targetFormatted, targetIcon, targetChangeDescription, targetChangeRoute, updated, submitting, submit, sendingCode, canRequestCode, requestCode, resendDescription };
		}
	}
</script>

<style scoped>
	.bi-phone {
		font-size: 4rem;
	}
</style>