<template>
	<div guide="config-printers-card" class="card">
		<div class="card-body">
			<div class="mb-3 d-flex justify-content-between align-items-center">
				<div>
					<h4>Impresiones</h4>
					<p class="text-sm text-muted">¿Dónde querés imprimir?</p>
				</div>
				<div class="icon icon-shape bg-secondary text-white text-lg rounded-circle">
					<i class="bi bi-printer"></i>
				</div>
			</div>
			<div class="list-group list-group-flush">
				<div class="list-group-item d-flex align-items-center px-0">
					<div class="flex-fill">
						<span class="d-block h6 fw-bold mb-1">Comandas</span>
						<span class="d-block text-sm text-warning" v-if="!('commander' in printers_store)"><i class="bi bi-info-circle me-1"></i>Sin seleccionar</span>
						<span class="d-block text-sm" v-if="'commander' in printers_store && printers_store.commander.method == 'local'"><i class="bi bi-printer me-1"></i>Impresión local</span>
						<span class="d-block text-sm" v-if="'commander' in printers_store && printers_store.commander.method == 'remote'"><i class="bi bi-router me-1"></i>Impresión remota</span>
					</div>
					<button type="button" guide="config-printers-button-commander" class="btn btn-sm btn-neutral" @click="modalCommanderShow">Editar</button>
				</div>
				<div class="list-group-item d-flex align-items-center px-0">
					<div class="flex-fill">
						<span class="d-block h6 fw-bold mb-1">Resumen de pedido</span>
						<span class="d-block text-sm text-warning" v-if="!('summary' in printers_store)"><i class="bi bi-info-circle me-1"></i>Sin seleccionar</span>
						<span class="d-block text-sm" v-if="'summary' in printers_store && printers_store.summary.method == 'local'"><i class="bi bi-printer me-1"></i>Impresión local</span>
						<span class="d-block text-sm" v-if="'summary' in printers_store && printers_store.summary.method == 'remote'"><i class="bi bi-router me-1"></i>Impresión remota</span>
					</div>
					<button type="button" class="btn btn-sm btn-neutral" @click="modalSummaryShow">Editar</button>
				</div>
				<div class="list-group-item d-flex align-items-center px-0">
					<div class="flex-fill">
						<span class="d-block h6 fw-bold mb-1">Facturación</span>
						<span class="d-block text-sm text-warning" v-if="!('billing' in printers_store)"><i class="bi bi-info-circle me-1"></i>Sin seleccionar</span>
						<span class="d-block text-sm" v-if="'billing' in printers_store && printers_store.billing.method == 'local'"><i class="bi bi-printer me-1"></i>Impresión local</span>
						<span class="d-block text-sm" v-if="'billing' in printers_store && printers_store.billing.method == 'remote'"><i class="bi bi-router me-1"></i>Impresión remota</span>
					</div>
					<button type="button" class="btn btn-sm btn-neutral" @click="modalBillingShow">Editar</button>
				</div>
				<div class="list-group-item d-flex align-items-center px-0">
					<div class="flex-fill">
						<span class="d-block h6 fw-bold mb-1">Caja</span>
						<span class="d-block text-sm text-warning" v-if="!('cashbox' in printers_store)"><i class="bi bi-info-circle me-1"></i>Sin seleccionar</span>
						<span class="d-block text-sm" v-if="'cashbox' in printers_store && printers_store.cashbox.method == 'local'"><i class="bi bi-printer me-1"></i>Impresión local</span>
						<span class="d-block text-sm" v-if="'cashbox' in printers_store && printers_store.cashbox.method == 'remote'"><i class="bi bi-router me-1"></i>Impresión remota</span>
					</div>
					<button type="button" class="btn btn-sm btn-neutral" @click="modalCashboxShow">Editar</button>
				</div>
			</div>
			<router-link guide="config-printers-button-printpoint" :to="{ name: 'store.config.printpoint.form', params: { store_id: current_store.id } }" class="btn btn-neutral w-100 mt-2">{{ (printpoint) ? 'Editar punto de impresión' : 'Crear punto de impresión' }}</router-link>
		</div>
	</div>

	<config-printers-commander :status="status" :printers="printers" :printpoints="printpoints" :assemblies="assemblies" />
	<config-printers-summary :status="status" :printers="printers" :printpoints="printpoints" />
	<config-printers-billing :status="status" :printers="printers" :printpoints="printpoints" />
	<config-printers-cashbox :status="status" :printers="printers" :printpoints="printpoints" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import { status, printers } from '@/helpers/QZTray';
	import { Modal } from 'bootstrap';
	import PrintpointRepository from '@/repositories/PrintpointRepository';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { current_store } = composableStore();
			const { catchError } = composableForm();
			const printpoint = computed(() => store.getters['config/printpoint'](current_store.value.id));
			const printers_store = computed(() => store.getters['config/printers'](current_store.value.id));

			const printpoints = ref([]);
			onMounted(() => {
				PrintpointRepository.index(current_store.value.id).then((response) => printpoints.value = response.data).catch(catchError);
			});

			const assemblies = ref([]);
			onMounted(() => {
				StoreRepository.assemblies(current_store.value.id).then((response) => assemblies.value = response.data).catch(catchError);
			});

			const modalCommanderShow = () => {
				var modalCommander = Modal.getInstance(document.getElementById('config-printers-commander'));
				modalCommander.show();
			}

			const modalSummaryShow = () => {
				var modalSummary = Modal.getInstance(document.getElementById('config-printers-summary'));
				modalSummary.show();
			}

			const modalBillingShow = () => {
				var modalBilling = Modal.getInstance(document.getElementById('config-printers-billing'));
				modalBilling.show();
			}

			const modalCashboxShow = () => {
				var modalCashbox = Modal.getInstance(document.getElementById('config-printers-cashbox'));
				modalCashbox.show();
			}

			return { current_store, printpoint, printers_store, modalCommanderShow, modalSummaryShow, modalBillingShow, modalCashboxShow, status, printers, printpoints, assemblies };
		}
	}
</script>