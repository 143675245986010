import { computed } from 'vue';
import store from '@/store';
import { Modal } from 'bootstrap';
import _ from 'lodash';

export default function() {
	const isDefined = computed(() => store.getters['store/isDefined']);

	const current_store = computed(() => {
		if(isDefined.value) return store.getters['store/info'];
		return { id: null, name: null, logo: null, logo_url: null, cover: null, address: { complete: null } };
	});

	const logo_default = computed(() => {
		if(current_store.value.logo_url) return current_store.value.logo_url;
		return require('@/assets/img/icons/no-image.jpg');
	});

	const isPlanFree = computed(() => {
		return current_store.value?.plan == 'free';
	});

	const isPlanStandard = computed(() => {
		return current_store.value?.plan == 'standard';
	});

	const isPlanPremium = computed(() => {
		return current_store.value?.plan == 'premium';
	});

	const role = computed(() => {
		if(isDefined.value) return current_store.value.pivot.role;
		return null;
	});

	const isAdmin = computed(() => {
		if(isDefined.value) return store.getters['store/isAdmin'];
		return false;
	});

	const status = computed(() => {
		if(isDefined.value) return store.getters['store/status'];
		return false;
	});

	const gateways = computed(() => {
		if(isDefined.value) return store.getters['store/gateways'];
		return [];
	});

	const codes = computed(() => {
		if(isDefined.value) return store.getters['store/codes'];
		return false;
	});

	const codeDefault = computed(() => {
		if(!codes.value) return false;
		return _.find(codes.value, (code) => { return code.default; });
	});

	const maps = computed(() => {
		if(isDefined.value) return store.getters['store/maps'];
		return false;
	});

	const catalog = computed(() => {
		if(isDefined.value) return store.getters['store/catalog'];
		return [];
	});

	const sellers = computed(() => {
		if(isDefined.value) return store.getters['store/sellers'];
		return [];
	});

	const allowImmediateRequests = computed(() => {
		if(isDefined.value) return store.getters['store/allowImmediateRequests'];
		return false;
	});

	const allowScheduledRequests = computed(() => {
		if(isDefined.value) return store.getters['store/allowScheduledRequests'];
		return false;
	});

	const showStatus = () => {
		var modal = new Modal(document.getElementById('modal-status'));
		modal.show();
	}

	return { isDefined, logo_default, current_store, isPlanFree, isPlanStandard, isPlanPremium, role, isAdmin, status, gateways, codes, codeDefault, maps, catalog, sellers, allowImmediateRequests, allowScheduledRequests, showStatus };
}
