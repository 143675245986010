<template>
	<user-settings-header />
	<!-- Main -->
	<main class="py-5 flex-grow-1">
		<!-- Container -->
		<div class="container-fluid mw-screen-md vstack gap-6 min-h-full">
			<form class="card" @submit.prevent="submit">
				<div class="card-header h4 fw-bolder">Cambiar contraseña</div>
				<div class="card-body">
					<div class="row">
						<div class="col-12">
							<div class="form-floating" :class="{'is-invalid': validation.password.$error}">
								<input type="password" id="password" v-model="password" class="form-control" :class="{'is-invalid': validation.password.$error}" placeholder="Ingresá tu contraseña" autocomplete="new-password">
								<label for="password">Contraseña</label>
							</div>
							<span class="mt-1 invalid-feedback" v-for="error of validation.password.$errors" :key="error.$uid">{{ error.$message }}</span>
						</div>
						<div class="col-12 mt-5">
							<div class="form-floating" :class="{'is-invalid': validation.password_confirm.$error}">
								<input type="password" id="password_confirm" v-model="password_confirm" class="form-control" :class="{'is-invalid': validation.password_confirm.$error}" placeholder="Ingresá nuevamente la contraseña" autocomplete="new-password">
								<label for="password_confirm">Repetir contraseña</label>
							</div>
							<span class="mt-1 invalid-feedback" v-for="error of validation.password_confirm.$errors" :key="error.$uid">{{ error.$message }}</span>
						</div>
						<div class="col-12 mt-5 text-center" v-if="validationExternal.length > 0">
							<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<button type="button" class="btn btn-secondary" :disabled="submitting" @click="redirectBack">Cancelar</button>
					<button type="submit" class="btn btn-primary" :disabled="submitting">Guardar</button>
				</div>
			</form>
		</div>
	</main>
</template>

<script>
	import { reactive, toRefs } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength, sameAs } from '@/helpers/i18n/validators';
	import MeRepository from '@/repositories/MeRepository';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { redirectBack } = composableRedirect();
			const { submitting, validationExternal, catchError } = composableForm();

			const form = reactive({
				password: '',
				password_confirm: ''
			});

			const { password } = toRefs(form, 'form');

			const validation = useVuelidate({
				password: {
					required,
					minLength: minLength(8),
					maxLength: maxLength(16)
				},
				password_confirm: {
					required,
					sameAs: sameAs(password, 'contraseña')
				}
			}, form);

			const submit = async () => {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await MeRepository.password(form)
					.then(redirectBack)
					.catch(catchError);
				submitting.value = false;
			}

			return { redirectBack, ...toRefs(form), validation, validationExternal, submitting, submit };
		}
	}
</script>