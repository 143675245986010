<template>
	<teleport to="body">
		<div id="common-order-total" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="commonOrderTotal">
			<div class="offcanvas-header container container-offcanvas">
				<h5 class="offcanvas-title" id="commonOrderTotal">Subtotal: {{ vueNumberFormat(subtotal, $personalizer.currency.value) }}</h5>
				<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body container container-offcanvas pt-0">
				<div class="mb-7">
					<div class="py-4 cursor-pointer" @click="select('percentage')">
						<div class="form-check">
							<input type="radio" v-model="type" value="percentage" class="form-check-input">
							<div class="form-check-label text-dark d-block ms-1">
								<b>Descuento por porcentaje</b>
								<div class="mt-3" v-if="type == 'percentage'">
									<VueNumberFormat :options="{ prefix: '% ', precision: 0, isInteger: true, acceptNegative: false }" v-model:value="values.percentage" class="form-control form-control-sm" @keyup.enter="submit" v-focus></VueNumberFormat>
									<div class="text-danger mt-1" v-for="error of validation.values.$errors" :key="error.$uid">{{ error.$message }}</div>
								</div>
							</div>
						</div>
					</div>
					<hr class="my-0" />
					<div class="py-4 cursor-pointer" @click="select('amount')">
						<div class="form-check">
							<input type="radio" v-model="type" value="amount" class="form-check-input">
							<div class="form-check-label text-dark d-block ms-1">
								<b>Descuento por monto</b>
								<div class="mt-3" v-if="type == 'amount'">
									<VueNumberFormat :options="Object.assign({ acceptNegative: false }, $personalizer.currency.value)" v-model:value="values.amount" class="form-control form-control-sm" @keyup.enter="submit" v-focus></VueNumberFormat>
									<div class="text-danger mt-1" v-for="error of validation.values.$errors" :key="error.$uid">{{ error.$message }}</div>
								</div>
							</div>
						</div>
					</div>
					<hr class="my-0" />
					<div class="py-4 cursor-pointer" @click="select('subtotal')">
						<div class="form-check">
							<input type="radio" v-model="type" value="subtotal" class="form-check-input">
							<div class="form-check-label text-dark d-block ms-1">
								<b>Modificar subtotal</b>
								<div class="mt-3" v-if="type == 'subtotal'">
									<VueNumberFormat :options="Object.assign({ acceptNegative: false }, $personalizer.currency.value)" v-model:value="values.subtotal" class="form-control form-control-sm" @keyup.enter="submit" v-focus></VueNumberFormat>
									<div class="text-danger mt-1" v-for="error of validation.values.$errors" :key="error.$uid">{{ error.$message }}</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="validation.type.$errors.length > 0">
						<hr class="my-0" />
						<div class="text-danger px-8 my-3" v-for="error of validation.type.$errors" :key="error.$uid">{{ error.$message }}</div>
					</div>
				</div>
				<div class="d-grid gap-4 d-md-flex justify-content-md-end">
					<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="offcanvas">Cancelar</button>
					<button type="button" class="btn btn-sm btn-dark" :disabled="submitting" @click="submit">Aplicar</button>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { Offcanvas } from 'bootstrap';
	import composableForm from '@/composables/form';

	export default {
		emits: ['setDiscount'],
		props: {
			subtotal: Number
		},
		setup(props, { emit }) {
			const { submitting } = composableForm();

			const modalOrderTotal = ref(null);
			onMounted(() => {
				modalOrderTotal.value = new Offcanvas(document.getElementById('common-order-total'));

				document.getElementById('common-order-total').addEventListener('shown.bs.offcanvas', () => select('percentage'));
				document.getElementById('common-order-total').addEventListener('hidden.bs.offcanvas', () => {
					form.type = null;
					form.values = { percentage: 0, amount: 0, subtotal: 0 };
				});
			});

			const form = reactive({
				type: null,
				values: { percentage: 0, amount: 0, subtotal: 0 }
			});

			const validateType = (type) => {
				return (type != null);
			}

			const validateValues = (values) => {
				if(form.type == 'percentage') return (values.percentage >= 0 && values.percentage <= 100);
				else if(form.type == 'amount') return (values.amount >= 0 && values.amount <= props.subtotal);
				else if(form.type == 'subtotal') return (values.subtotal >= 0 && values.subtotal <= props.subtotal);
				else return true;
			}

			const validation = useVuelidate({
				type: {
					validateType: helpers.withMessage('Debe seleccionar un tipo', validateType)
				},
				values: {
					validateValues: helpers.withMessage(`El valor ingresado es inválido`, validateValues)
				}
			}, form);

			const submit = async () => {
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				var discount = 0;

				switch(form.type) {
					case 'percentage':
						discount = Math.round(props.subtotal * (form.values.percentage / 100) * 100) / 100;
						break;
					case 'amount':
						discount = form.values.amount;
						break;
					case 'subtotal':
						discount = props.subtotal - form.values.subtotal;
						break;
				}

				submitting.value = false;
				emit('setDiscount', discount);
				modalOrderTotal.value.hide();
			}

			const select = (type) => form.type = type;

			return { ...toRefs(form), validation, submitting, submit, select };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>