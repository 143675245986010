<template>
	<layouts-basic>
		<div class="pb-7">
			<h3 class="ls-tight fw-bolder h3">¿Olvidaste tu contraseña?</h3>
			<p class="text-sm text-muted">Para recuperar tu acceso por email, completá el siguiente formulario.</p>
		</div>
		<form @submit.prevent="submit">
			<div class="mb-3">
				<div class="form-floating" :class="{'is-invalid': validation.email.$error}">
					<input type="email" id="email" v-model="email" class="form-control" :class="{'is-invalid': validation.email.$error}" placeholder="Ingresá tu email" v-focus>
					<label for="email">Email</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.email.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="mb-3 text-center" v-if="validationExternal.length > 0">
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div class="mt-5">
				<button type="submit" class="btn btn-primary w-100" :disabled="submitting">Recuperar acceso</button>
			</div>
			<div class="mt-5">
				<router-link :to="{name: 'user.forgot.phone'}" class="btn btn-neutral w-100">Recuperar por teléfono</router-link>
			</div>
		</form>
		<user-login-social-secondary description="Recordá que podés ingresar con" />
		<div class="mt-10">
			<router-link :to="{name: 'login'}" class="btn btn-danger w-100">Cancelar</router-link>
		</div>
	</layouts-basic>
</template>

<script>
	import { reactive, ref, toRefs } from 'vue';
	import router from '@/router';
	import useVuelidate from '@vuelidate/core';
	import { required, email } from '@/helpers/i18n/validators';

	export default {
		setup() {
			const form = reactive({
				email: ''
			});

			const validationExternal = ref([]);
			const validation = useVuelidate({
				email: {
					required,
					email
				}
			}, form);

			const submitting = ref(false);
			async function submit() {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				router.push({
					name: 'user.forgot.verification',
					query: {
						target_type: 'email',
						target: form.email,
						issued_for: 'recovery_access'
					}
				});
			}

			return { ...toRefs(form), validation, validationExternal, submitting, submit };
		}
	}
</script>