<template>
	<layouts-basic>
		<div class="text-center mb-7">
			<div class="d-inline-block mb-6">
				<img src="@/assets/img/logo/main.png" class="h-rem-12" alt="logo">
			</div>
			<h1 class="ls-tight fw-bolder h2">Regístrate en Wantti</h1>
			<p class="mt-2">Elegí cómo querés registrarte</p>
		</div>
		<user-login-social-primary description="O completando los siguientes datos" />
		<form @submit.prevent="submit" autocomplete="off">
			<div class="mb-3">
				<div class="form-floating" :class="{'is-invalid': validation.fullname.$error}">
					<input type="text" id="fullname" v-model="fullname" class="form-control" :class="{'is-invalid': validation.fullname.$error}" placeholder="Ingresá tu nombre" v-focus>
					<label for="fullname">Nombre</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.fullname.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="mb-3">
				<div class="form-floating" :class="{'is-invalid': validation.email.$error}">
					<input type="email" id="email" v-model="email" class="form-control" :class="{'is-invalid': validation.email.$error}" placeholder="Ingresá tu email">
					<label for="email">Email</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.email.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<common-form-input-phone v-model="phone" title="Teléfono" placeholder="Ingresá tu número telefónico" :validation="validation.phone" class="mb-3" />
			<div class="mb-3">
				<div class="form-floating" :class="{'is-invalid': validation.password.$error}">
					<input type="password" id="password" name="new-password" v-model="password" class="form-control" :class="{'is-invalid': validation.password.$error}" placeholder="Ingresá tu contraseña" autocomplete="current-password">
					<label for="password">Contraseña</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.password.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="mb-3 text-center" v-if="validationExternal.length > 0">
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div class="mt-5 text-center">
				Al registrarte aceptas nuestros <a href="/legal/terms" class="text-secondary fw-semibold" target="_blank">términos de servicio</a> y <a href="/legal/privacy" class="text-secondary fw-semibold" target="_blank">políticas de privacidad</a>.
			</div>
			<div class="mt-5 mb-3">
				<button type="submit" class="btn btn-primary w-100" :disabled="submitting">Crear cuenta</button>
			</div>
		</form>
		<div class="mt-6 text-center">
			¿Ya tienes una cuenta? <router-link :to="{name: 'login'}" class="text-secondary fw-semibold">Ingresar</router-link>
		</div>
	</layouts-basic>
</template>

<script>
	import { reactive, toRefs } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { required, minLength, maxLength, email } from '@/helpers/i18n/validators';
	import { isValidPhoneNumber, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
	import composableForm from '@/composables/form';
	import _ from 'lodash';

	export default {
		setup() {
			const { submitting, validationExternal, catchError } = composableForm();

			const form = reactive({
				fullname: '',
				email: '',
				phone: '',
				password: ''
			});

			const validatePhone = (value) => {
				return isValidPhoneNumber(value);
			}

			const validatePhoneMessage = ({ $model }) => {
				var message = null;

				switch(validatePhoneNumberLength($model)) {
					case 'NOT_A_NUMBER':
						message = 'Debe ser numérico';
						break;
					case 'INVALID_COUNTRY':
						message = 'Debe incluir el código de país, incluyendo el signo +';
						break;
					case 'TOO_SHORT':
						message = 'Es demasiado corto';
						break;
					case 'INVALID_LENGTH':
						message = 'Contiene una longitud inválida';
						break;
					case 'TOO_LONG':
						message = 'Es demasiado largo';
						break;
					default:
						message = 'Es inválido';
						break;
				}

				return message;
			}

			const validation = useVuelidate({
				fullname: {
					required
				},
				email: {
					required,
					email
				},
				phone: {
					validatePhone: helpers.withMessage(validatePhoneMessage, validatePhone)
				},
				password: {
					required,
					minLength: minLength(8),
					maxLength: maxLength(16)
				}
			}, form);

			async function submit() {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				//Formatea el teléfono
				var sendForm = _.clone(form);
				const phoneNumber = parsePhoneNumber(sendForm.phone);
				sendForm.phone = phoneNumber.number;

				submitting.value = true;
				store.dispatch('auth/signup', sendForm).then(onSuccess).catch(catchError);
			}

			const onSuccess = () => {
				store.dispatch('auth/me').then(() => router.push({name: 'select'})).catch(catchError);
			}

			return { ...toRefs(form), validation, validationExternal, submitting, submit };
		}
	}
</script>