import { createRouter, createWebHistory } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';
import store from '@/store';
import { Modal, Offcanvas } from 'bootstrap';
import _ from 'lodash';
import { duplicated } from '@/helpers/Unduplicate';
import composableRedirect from '@/composables/redirect';

import StoresSelect from '@/views/Stores/Select.vue';
import UserSignin from '@/views/User/Signin.vue';
import UserLogin from '@/views/User/Login.vue';
import UserSocial from '@/views/User/Social.vue';
import UserForgotPhone from '@/views/User/Forgot/Phone.vue';
import UserForgotEmail from '@/views/User/Forgot/Email.vue';
import UserForgotVerification from '@/views/User/Forgot/Verification.vue';
import ComponentsStoreCore from '@/components/store/core.vue';
import StoreHome from '@/views/Store/Home.vue';
import UserSettingsGeneral from '@/views/User/Settings/General.vue';
import UserSettingsPassword from '@/views/User/Settings/Password.vue';
import ConfigIndex from '@/views/Config/Index.vue';
import ConfigPrintPointForm from '@/views/Config/PrintPoint/Form.vue';
import CartAddress from '@/views/Cart/Address.vue';
import CartCatalog from '@/views/Cart/Catalog.vue';
import CartCheckout from '@/views/Cart/Checkout.vue';
import CameraIndex from '@/views/Camera/Index.vue';
import MapsIndex from '@/views/Maps/Index.vue';
import CodesIndex from '@/views/Codes/Index.vue';
import OrderGroupCreate from '@/views/OrderGroup/Create.vue';
import OrderGroupBillingInvoice from '@/views/OrderGroup/Billing/Invoice.vue';
import OrdersKanban from '@/views/Orders/Kanban.vue';
import PayingIndex from '@/views/Paying/Index.vue';
import OrderBillingInvoice from '@/views/Order/Billing/Invoice.vue';
import CashboxIndex from '@/views/Cashbox/Index.vue';
import CashboxSettings from '@/views/Cashbox/Settings.vue';
import PagesDuplicated from '@/views/Pages/Duplicated.vue';
import PagesNotFound from '@/views/Pages/NotFound.vue';

const routes = [
	{
		path: '/',
		name: 'select',
		meta: { needUser: true },
		component: StoresSelect
	},
	{
		path: '/signin',
		name: 'signin',
		meta: { needUser: false },
		component: UserSignin
	},
	{
		path: '/login',
		name: 'login',
		meta: { needUser: false },
		component: UserLogin
	},
	{
		path: '/social/:provider',
		name: 'social',
		meta: { needUser: false },
		component: UserSocial
	},
	{
		path: '/user',
		children: [
			{
				path: 'forgot',
				meta: { needUser: false },
				children: [
					{
						path: 'phone',
						name: 'user.forgot.phone',
						component: UserForgotPhone
					},
					{
						path: 'email',
						name: 'user.forgot.email',
						component: UserForgotEmail
					},
					{
						path: 'verification',
						name: 'user.forgot.verification',
						component: UserForgotVerification
					}
				]
			}
		]
	},
	{
		path: '/:store_id',
		meta: { needUser: true },
		component: ComponentsStoreCore,
		children: [
			{
				path: '',
				name: 'store.home',
				meta: { setBack: true },
				component: StoreHome
			},
			{
				path: 'user',
				children: [
					{
						path: 'settings',
						children: [
							{
								path: 'general',
								name: 'store.user.settings.general',
								component: UserSettingsGeneral
							},
							{
								path: 'password',
								name: 'store.user.settings.password',
								component: UserSettingsPassword
							}
						]
					}
				]
			},
			{
				path: 'config',
				meta: { role: ['owner', 'manager', 'operator'] },
				children: [
					{
						path: 'index',
						name: 'store.config.index',
						component: ConfigIndex
					},
					{
						path: 'printpoint',
						meta: { plan: ['standard', 'premium'] },
						children: [
							{
								path: 'form',
								name: 'store.config.printpoint.form',
								component: ConfigPrintPointForm
							}
						]
					}
				]
			},
			{
				path: 'cart',
				meta: { plan: ['standard', 'premium'] },
				children: [
					{
						path: 'address',
						name: 'store.cart.address',
						component: CartAddress
					},
					{
						path: 'catalog',
						name: 'store.cart.catalog',
						meta: { needCart: true },
						component: CartCatalog
					},
					{
						path: 'checkout',
						name: 'store.cart.checkout',
						meta: { needCart: true, needItems: true },
						component: CartCheckout
					}
				]
			},
			{
				path: 'camera',
				name: 'store.camera.index',
				meta: { layout: 'components-empty' },
				component: CameraIndex
			},
			{
				path: 'maps',
				name: 'store.maps.index',
				meta: { setBack: true, plan: ['standard', 'premium'] },
				component: MapsIndex
			},
			{
				path: 'codes',
				name: 'store.codes.index',
				meta: { setBack: true, plan: ['standard', 'premium'] },
				component: CodesIndex
			},
			{
				path: 'orderGroup',
				children: [
					{
						path: 'create',
						name: 'store.orderGroup.create',
						meta: { plan: ['standard', 'premium'] },
						component: OrderGroupCreate
					},
					{
						path: ':id',
						children: [
							{
								path: 'billing',
								meta: { plan: ['premium'] },
								children: [
									{
										path: 'invoice',
										name: 'store.orderGroup.billing.invoice',
										component: OrderGroupBillingInvoice
									}
								]
							},
						]
					}
				]
			},
			{
				path: 'orders',
				children: [
					{
						path: 'kanban',
						name: 'store.orders.kanban',
						meta: { setBack: true, role: ['owner', 'manager', 'operator'] },
						component: OrdersKanban
					}
				]
			},
			{
				path: 'paying/:type/:id',
				name: 'paying',
				component: PayingIndex
			},
			{
				path: 'order/:id',
				children: [
					{
						path: 'billing',
						meta: { plan: ['premium'] },
						children: [
							{
								path: 'invoice',
								name: 'store.order.billing.invoice',
								component: OrderBillingInvoice
							}
						]
					},
				]
			},
			{
				path: 'cashbox',
				meta: { plan: ['standard', 'premium'], role: ['owner', 'manager', 'operator'] },
				children: [
					{
						path: 'index',
						name: 'store.cashbox.index',
						component: CashboxIndex
					},
					{
						path: 'index',
						name: 'store.cashbox.settings',
						component: CashboxSettings
					}
				]
			},
		]
	},
	{
		path: '/duplicated',
		name: 'duplicated',
		component: PagesDuplicated
	},
	{
		path: '/:pathMatch(.*)*',
		component: PagesNotFound
	}
];

window.popStateDetected = false;
window.addEventListener('popstate', () => window.popStateDetected = true);

const scrollException = ['store.cart.checkout'];

const router = createRouter({
	history: createWebHistory('/'),
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		if(_.indexOf(scrollException, to.name) === -1)
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve(savedPosition || { left: 0, top: 0 });
				}, 300);
			});
	}
});

router.beforeEach((to) => {
	const IsItABackButton = window.popStateDetected;
	window.popStateDetected = false;

	if(document.querySelector('.modal.show')) {
		var modalShowed = Modal.getInstance(document.querySelector('.modal.show'));
		modalShowed.hide();

		if(IsItABackButton) return false;
	}

	if(document.querySelector('.offcanvas.show')) {
		var offcanvasShowed = Offcanvas.getInstance(document.querySelector('.offcanvas.show'));
		offcanvasShowed.hide();

		if(IsItABackButton) return false;
	}

	if(duplicated.value && to.name != 'duplicated') {
		return router.push({ name: 'duplicated' });
	}

	if(!duplicated.value && to.name == 'duplicated') {
		return router.push({ name: 'select' });
	}

	if(to.meta.needUser === true && !store.getters['auth/hasUser']) {
		return router.push({ name: 'login' });
	}

	if(to.meta.needUser === false && store.getters['auth/hasUser']) {
		const { redirectBack } = composableRedirect();
		redirectBack();
	}

	if(to.meta.needCart && !store.getters['cart/isDefined']) {
		return router.push({ name: 'store.home', params: { store_id: to.params.store_id } });
	}

	if(to.meta.needItems && store.getters['cart/isEmpty']) {
		return router.push({ name: 'store.home', params: { store_id: to.params.store_id } });
	}

	if(to.meta?.role != undefined && _.indexOf(to.meta?.role, store.getters['store/info']?.pivot?.role) === -1) {
		return router.push({ name: 'store.home', params: { store_id: to.params.store_id } });
	}

	if(to.meta?.plan != undefined && _.indexOf(to.meta?.plan, store.getters['store/info']?.plan) === -1) {
		return router.push({ name: 'store.home', params: { store_id: to.params.store_id } });
	}

	if(to.meta.setBack) {
		store.dispatch('redirect/setBack', to.path);
	}
});

trackRouter(router);

export default router
