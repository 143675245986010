import { ref, watch, computed } from 'vue';
import store from '@/store';
import { createPopper } from '@popperjs/core';
import _ from 'lodash';
import VueScrollTo from 'vue-scrollto';
import translation from '@/helpers/i18n/translations/guide/es.json';

export const install = () => {
}

const popper = ref(null);
export const modalSelect = ref(null);
const guideCurrent = ref(null);
export const stepsShown = ref([]);
export const stepCurrent = ref(null);

export const guideModal = () => {
	if(!store.getters['config/guide']) store.dispatch('config/setGuide', true);

	if(guideCurrent.value) guideEnd();
	modalSelect.value.modal.show();
}

export const guideSelect = (guide) => {
	guideCurrent.value = guide;
	setStepsShown();

	modalSelect.value.modal.hide();
	if(stepsShown.value.length > 0) guideGo(_.head(stepsShown.value));
	guideObserver.observe(document.body, { subtree: true, childList: true });
}

const popperShow = async (step) => {
	if(popper.value) return popperRender(step);

	document.getElementById('v-guide').classList.remove('d-none');
	popper.value = createPopper(document.querySelector(`[guide="${step.id}"]`), document.getElementById('v-guide'), {
		placement: `${step.placement ?? 'top'}`,
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, (step.offset ?? 16)]
				}
			}
		]
	});

	VueScrollTo.scrollTo(`[guide="${step.id}"]`, 500, { offset: -100 });
}

const popperRender = async (step) => {
	await popper.value.setOptions({
		placement: `${step.placement ?? 'top'}`,
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, (step.offset ?? 16)]
				}
			}
		]
	});

	popper.value.state.elements.reference = document.querySelector(`[guide="${step.id}"]`);
	await popper.value.update();
}

const popperDestroy = () => {
	document.getElementById('v-guide').classList.add('d-none');
	popper.value.destroy();
	popper.value = null;
}

export const hasNext = computed(() => {
	let indexCurrent = _.indexOf(guideCurrent.value.steps, stepCurrent.value.id),
		idNext = _.find(guideCurrent.value.steps, function(id, index) { return (index > indexCurrent && _.indexOf(stepsShown.value, id) !== -1); });

	return (idNext != undefined);
});

export const guideNext = () => {
	let indexCurrent = _.indexOf(guideCurrent.value.steps, stepCurrent.value.id),
		idNext = _.find(guideCurrent.value.steps, function(id, index) { return (index > indexCurrent && _.indexOf(stepsShown.value, id) !== -1); });

	if(idNext != undefined) guideGo(idNext);
	else return false;
}

export const guideGo = (id) => {
	let stepGo = _.find(translation.steps, { id: id });
	if(stepGo != undefined) stepCurrent.value = stepGo;
	else guideNext();
}

export const guideEnd = () => {
	guideCurrent.value = null;
	stepCurrent.value = null;
	guideObserver.disconnect();
}

watch(stepCurrent, (newStep, oldStep) => {
	if(newStep) popperShow(newStep);
	else if(oldStep != null) popperDestroy();
});

const setStepsShown = () => {
	const elements = document.querySelectorAll('[guide]');
	const values = _.uniq(_.map(elements, (element) => element.getAttribute('guide')));

	if(!_.isEqual(stepsShown.value, values)) stepsShown.value = _.intersection(guideCurrent.value.steps, values);
}

const guideObserver = new MutationObserver(() => {
	if(guideCurrent.value) {
		let stepsShownOld = _.clone(stepsShown.value);
		setStepsShown();

		if(!_.isEqual(stepsShown.value, stepsShownOld)) {
			let newIntersection = _.intersection(stepsShown.value, (stepCurrent.value?.next ?? [])),
				oldIntersection = _.intersection(stepsShownOld, (stepCurrent.value?.next ?? []));

			if(newIntersection.length > 0 && oldIntersection.length == 0) guideGo(_.head(newIntersection));
			else if(stepsShown.value.length == 0 || _.indexOf(guideCurrent.value.finish, stepCurrent.value.id) !== -1) guideEnd();
			else if(_.indexOf(stepsShown.value, stepCurrent.value.id) == -1) guideGo(_.head(stepsShown.value));
		}
	}
});

export const guideInfo = () => {
	let guideInit = _.find(translation.guides, { id: 'guide-init' });
	guideSelect(guideInit);
}