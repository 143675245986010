<template>
	<ordergroup-header-create :code="code" />
	<main class="py-5 flex-grow-1">
		<div class="container-fluid mw-screen-sm vstack gap-3 min-h-full">
			<div class="card">
				<div class="card-header h4 fw-bolder">Abrir <u>{{ code?.description }}</u></div>
				<div class="card-body">
					<h5 class="card-title mb-5">Información general</h5>
					<div class="row" v-if="isAdmin">
						<div guide="views-ordergroup-create-input-seller" class="col-12 mb-7">
							<label for="seller_id" class="form-label">Vendedor <span class="text-muted">(Opcional)</span></label>
							<select id="seller_id" v-model="seller_id" class="form-select" :disabled="submitting">
								<option value="">Sin seleccionar</option>
								<option v-for="seller in sellers" v-bind:key="seller.id" v-bind:value="seller.id">{{ seller.fullname }}</option>
							</select>
						</div>
					</div>
					<div class="row" v-if="!isAdmin">
						<div class="col-12 mb-7">
							<label class="form-label">Vendedor</label>
							<user-card-main :user="user" />
						</div>
					</div>
					<h5 class="card-title mb-5">Información extra</h5>
					<div class="row">
						<div guide="views-ordergroup-create-input-people" class="col-12 mb-7">
							<label for="data.people" class="form-label">Cantidad de personas</label>
							<div class="input-group">
								<button type="button" class="btn border" :disabled="submitting" @click="peopleSub"><i class="bi bi-dash-lg"></i></button>
								<input type="number" id="data.people" v-model="data.people" :disabled="submitting" class="form-control text-center fw-semibold">
								<button type="button" class="btn border" :disabled="submitting" @click="peopleAdd"><i class="bi bi-plus-lg"></i></button>
							</div>
						</div>
						<div class="col-12 mb-7">
							<div class="form-check">
								<input type="checkbox" id="data.childrens" v-model="data.childrens" :disabled="submitting" class="form-check-input">
								<label class="form-check-label" for="data.childrens">Hay niños</label>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<button type="button" class="btn btn-secondary" :disabled="submitting" @click="redirectBack">Cancelar</button>
					<button type="submit" guide="views-ordergroup-create-buton-submit" class="btn btn-primary" :disabled="submitting" @click="submit">Abrir</button>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
	import { reactive, toRefs, computed } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import composableRedirect from '@/composables/redirect';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import composableCart from '@/composables/cart';
	import OrderGroupsRepository from '@/repositories/OrderGroupsRepository';
	import _ from 'lodash';

	export default {
		setup() {
			const { redirectBack } = composableRedirect();
			const { query: { code_id } } = useRoute();
			const { current_store, isAdmin, sellers, codes } = composableStore();
			const { submitting, catchError } = composableForm();
			const { selectCode } = composableCart();
			const user = computed(() => store.getters['auth/user']);
			const code = computed(() => _.find(codes.value, (row) => { return row.id == code_id; }));

			const form = reactive({
				code_id: code_id,
				seller_id: '',
				data: {
					people: 2,
					childrens: false
				}
			});

			const peopleSub = () => {
				if(form.data.people <= 1) return false;
				else form.data.people--;
			}

			const peopleAdd = () => form.data.people++;

			const submit = async () => {
				submitting.value = true;
				await OrderGroupsRepository.store(current_store.value.id, form)
					.then(() => selectCode(code.value))
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { redirectBack, ...toRefs(form), current_store, code, isAdmin, user, sellers, peopleSub, peopleAdd, submitting, submit };
		}
	}
</script>