<template>
	<cart-header-catalog :sections="sections" :onViewport="onViewport" :searching="searching" :submitting="submitting" v-model:searchTerm="searchTerm" @searchShow="searchShow" @addFirst="catalogSearch.addFirst()" @subFirst="catalogSearch.subFirst()" v-if="hasCart" />
	<!-- Main -->
	<main class="py-5 flex-grow-1" v-if="hasCart">
		<!-- Container -->
		<div class="container-fluid vstack gap-3 min-h-full">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-7 col-xl-8 mb-7">
					<catalog-index :sections="sections" :submitting="submitting" @selectItem="selectItem" v-if="!searching" @observe="observe" />
					<catalog-search :sections="sections" :submitting="submitting" :searchTerm="searchTerm" @selectItem="selectItem" ref="catalogSearch" v-if="searching" />
				</div>
				<div class="col-12 col-md-6 col-lg-5 col-xl-4 mb-7">
					<div class="cart-detail-checkout position-sticky">
						<cart-detail-checkout :submitting="submitting" :showOptions="false" />
					</div>
				</div>
			</div>
		</div>
	</main>

	<catalog-item-detail :item="item" @selectItem="selectItem" />
	<cart-modal-detail :canDelete="!submitting" />
</template>

<script>
	import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
	import VueScrollTo from 'vue-scrollto';
	import _ from 'lodash';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { catalog } = composableStore();
			const { hasCart, type, catalogType, submitting } = composableCart();

			const searching = ref(false);
			const searchTerm = ref('');
			const searchShow = (value) => {
				searchTerm.value = '';
				searching.value = value;
				VueScrollTo.scrollTo('.container-fluid', 150, { container: (window.innerWidth > 1200) ? '.main-slot' : null, offset: -148 });
			}

			onMounted(() => {
				document.onkeydown = (e) => {
					if(e.key.toUpperCase() === 'F' && (e.ctrlKey || e.metaKey)) {
						searchShow(true);
						e.preventDefault();
					}
				}
			});

			onUnmounted(() => {
				document.onkeydown = null;
			});

			const catalogSearch = ref(null);

			const sections = computed(() => {
				var results = catalog.value.sections;
				var type = (_.find(results, (row) => { return row.type == catalogType.value; }) !== undefined) ? catalogType.value : 'ONLINE';

				return _.filter(results, (section) => {
					return (section.type == type && _.find(catalog.value.items, { section_id: section.id }));
				});
			});

			const item = ref(null);

			const selectItem = (data) => item.value = data;

			const onViewport = ref([]);
			const observer = new IntersectionObserver(
				(entries) => {
					_.forEach(entries, (entry) => {
						var id = Number(entry.target.getAttribute('section-id'));

						if(entry.isIntersecting && _.indexOf(onViewport.value, id) === -1) {
							onViewport.value.push(id);
						}else if(!entry.isIntersecting) {
							var index = _.indexOf(onViewport.value, id);
							if(index !== -1) onViewport.value.splice(index, 1);
						}
					});

					setTimeout(() => {
						var el = document.querySelector('.horizontal-tags .btn.active:first-child');
						if(el) el.parentNode.parentNode.scrollLeft = el.offsetLeft - 79;
					}, 100);
				}, { root: document, rootMargin: '-240px 0px -130px 0px', }
			);

			const observe = (element) => observer.observe(element);

			watch(() => type.value, () => {
				VueScrollTo.scrollTo('.container-fluid', 150, { container: (window.innerWidth > 1200) ? '.main-slot' : null, offset: 0 });
			}, { deep: true });

			return { searching, searchTerm, searchShow, catalogSearch, sections, item, selectItem, hasCart, submitting, onViewport, observe };
		}
	}
</script>

<style scoped>
	.cart-detail-checkout.position-sticky {
		top: 154px !important;
	}
</style>