<template>
	<code-header />
	<!-- Main -->
	<main class="py-5 flex-grow-1">
		<!-- Container -->
		<div class="container-fluid vstack gap-3 min-h-full">
			<div guide="code-index-select" class="row g-5" v-if="codes.length > 0">
				<div :class="cardClass" v-for="code in codes" v-bind:key="code.id">
					<code-card :code="code" :orderGroups="orderGroups" @select="select" />
				</div>
			</div>
			<div class="row" v-if="codes.length == 0">
				<div class="col-12">
					<common-message-error text="No se encontraron códigos." />
				</div>
			</div>
		</div>
	</main>

	<ordergroup-modal-view :id="order_group_id" @select="selectOrderGroup" @selectChange="selectChange" />
	<ordergroup-modal-change :orderGroup="orderGroupChange" @selectChange="selectChange" />
</template>

<script>
	import { ref, computed } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';
	import _ from 'lodash';

	export default {
		props: {
			cardClass: String
		},
		setup() {
			const { current_store, isAdmin, codes } = composableStore();
			const { selectCode } = composableCart();
			const orderGroups = computed(() => store.getters['orderGroups/list']);
			const order_group_id = ref(null);

			const select = (code) => {
				if(code.group) {
					var info = _.find(orderGroups.value, (data) => { return (data.code.id == code.id && _.indexOf(['OPENED', 'PAYING'], data.status) !== -1); });

					if(!info) router.push({ name: 'store.orderGroup.create', params: { store_id: current_store.value.id }, query: { code_id: code.id } });
					else if(isAdmin.value && info.status == 'PAYING') router.push({ name: 'paying', params: { store_id: current_store.value.id, type: 'orderGroup', id: info.id } });
					else if(isAdmin.value) selectOrderGroup(info);
					else selectCode(code);
				}else selectCode(code);
			}

			const selectOrderGroup = (data) => order_group_id.value = data?.id ?? null;

			const orderGroupChange = ref(null);
			const selectChange = (data) => orderGroupChange.value = data;

			return { codes, orderGroups, order_group_id, select, selectOrderGroup, orderGroupChange, selectChange };
		}
	}
</script>