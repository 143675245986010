<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4 d-flex align-items-center">
			<div class="row g-0">
				<div class="col-12 col-md">
					<div class="d-flex align-items-center gap-3">
						<div class="btn btn-link p-0 text-reset text-md"><i class="bi bi-gear"></i></div>
						<div class="vr opacity-20 my-1"></div>
						<h1 class="h4 ls-tight">Configuración inicial de la caja</h1>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>