<template>
	<teleport to="body">
		<div class="modal" id="tickets-modal-new" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content shadow-3" v-if="order">
					<div class="modal-header">
						<div>
							<h4 class="modal-title">Ticket del pedido Nro: {{ order.number }}</h4>
							<small class="d-block">Seleccionar motivo del ticket</small>
						</div>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body py-0">
						<div class="row">
							<div class="col-12 px-0">
								<div class="list-group list-group-flush">
									<a href="javascript:void(0)" class="list-group-item px-6 py-3 fw-semibold text-danger-hover" v-for="(description, reason) in REASONS" v-bind:key="reason" @click="submit(reason)">{{ description }}</a>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal" :disabled="submitting">Cancelar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import TicketRepository from '@/repositories/TicketRepository';
	import composableStore from '@/composables/store';
	import composableTicket from '@/composables/ticket';
	import composableForm from '@/composables/form';

	export default {
		emits: ['selectTicket', 'selectTicketNew'],
		props: {
			order: Object
		},
		setup(props, { emit }) {
			const { current_store } = composableStore();
			const { REASONS } = composableTicket();
			const { submitting, catchError } = composableForm();
			const modalTicketNew = ref(null);

			onMounted(() => {
				modalTicketNew.value = new Modal(document.getElementById('tickets-modal-new'));

				document.getElementById('tickets-modal-new').addEventListener('hidden.bs.modal', () => {
					emit('selectTicketNew', null);
				});
			});

			watch(() => props.order, (newValue) => {
				if(newValue) modalTicketNew.value.show();
			});

			const submit = async (reason = null) => {
				if(submitting.value) return false;

				submitting.value = true;
				modalTicketNew.value.hide();

				await TicketRepository.store(current_store.value.id, { order_id: props.order.id, reason: reason })
					.then((response) => store.dispatch('tickets/sync', response.data).then(() => emit('selectTicket', response.data)))
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { REASONS, submitting, submit };
		}
	}
</script>