import Repository from './Repository';

const resource = '/store';

export default {
	index(store_id) {
		return Repository.get(`${resource}/${store_id}/config/printpoint`);
	},
	store(store_id, payload) {
		return Repository.post(`${resource}/${store_id}/config/printpoint`, payload);
	},
	update(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/config/printpoint/${id}`, payload);
	}
}