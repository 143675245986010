import { computed } from 'vue';
import store from '@/store';
import router from '@/router';
import _ from 'lodash';
import { useToast } from 'vue-toastification';
import { print as QzPrint } from '@/helpers/QZTray';
import composableStore from '@/composables/store';
import composableForm from '@/composables/form';
import OrdersRepository from '@/repositories/OrdersRepository';
import OrderGroupsRepository from '@/repositories/OrderGroupsRepository';
import CashboxRepository from '@/repositories/CashboxRepository';

const { current_store } = composableStore();
const { catchError } = composableForm();
const toast = useToast();

const redirectConfig = () => {
	toast.warning(`Configurá la forma de impresión.`);
	router.push({ name: 'store.config.index', params: { store_id: current_store.value.id } });
}

export const printpoint = computed(() => store.getters['config/printpoint'](current_store.value.id));
export const printers = computed(() => store.getters['config/printers'](current_store.value.id));
const printeds = computed(() => store.getters['config/printeds']);

export const printCommander = (id) => {
	if('commander' in printers.value) {
		OrdersRepository.printCommander(current_store.value.id, id, {
			printpoint_id: (printers.value.commander.method == 'remote') ? printers.value.commander.value : printpoint.value?.id,
			current_printpoint_id: printpoint.value?.id ?? null
		}).catch(catchError);
	}else redirectConfig();
}

export const printRejected = (id) => {
	if('commander' in printers.value) {
		OrdersRepository.printRejected(current_store.value.id, id, {
			printpoint_id: (printers.value.commander.method == 'remote') ? printers.value.commander.value : printpoint.value?.id,
			current_printpoint_id: printpoint.value?.id ?? null
		}).catch(catchError);
	}else redirectConfig();
}

export const printSummary = (type, id) => {
	if('summary' in printers.value) {
		switch(type) {
			case 'order':
				OrdersRepository.printSummary(current_store.value.id, id, {
					printpoint_id: (printers.value.summary.method == 'remote') ? printers.value.summary.value : printpoint.value?.id,
					current_printpoint_id: printpoint.value?.id ?? null
				}).catch(catchError);
				break;
			case 'ordergroup':
				OrderGroupsRepository.printSummary(current_store.value.id, id, {
					printpoint_id: (printers.value.summary.method == 'remote') ? printers.value.summary.value : printpoint.value?.id,
					current_printpoint_id: printpoint.value?.id ?? null
				}).catch(catchError);
				break;
		}
	}else redirectConfig();
}

export const printInvoice = (type, id) => {
	if('billing' in printers.value) {
		switch(type) {
			case 'order':
				OrdersRepository.printInvoice(current_store.value.id, id, {
					printpoint_id: (printers.value.billing.method == 'remote') ? printers.value.billing.value : printpoint.value?.id,
					current_printpoint_id: printpoint.value?.id ?? null
				}).catch(catchError);
				break;
			case 'ordergroup':
				OrderGroupsRepository.printInvoice(current_store.value.id, id, {
					printpoint_id: (printers.value.billing.method == 'remote') ? printers.value.billing.value : printpoint.value?.id,
					current_printpoint_id: printpoint.value?.id ?? null
				}).catch(catchError);
				break;
		}
	}else redirectConfig();
}

export const printCashbox = (id) => {
	if('cashbox' in printers.value) {
		CashboxRepository.print(current_store.value.id, id, {
			printpoint_id: (printers.value.cashbox.method == 'remote') ? printers.value.cashbox.value : printpoint.value?.id,
			current_printpoint_id: printpoint.value?.id ?? null
		}).catch(catchError);
	}else redirectConfig();
}

export const print = (response) => {
	var printer = null,
		printers_list = [];

	switch(response.data.type) {
		case 'rejected':
			printer = printers.value['commander'];

			var printed = _.find(printeds.value, { id: response.data.order_id });
			if(!printed) return false;
			printers_list = printed.printers;

			break;
		default:
			printer = printers.value[response.data.type];

			if(printer.value) printers_list.push(printer.value);
			_.forEach(response.data.print.assemblies ?? [], (assembly_id) => {
				var assembly_printer = printer?.assemblies[assembly_id];
				if(assembly_printer && printers_list.indexOf(assembly_printer) === -1) printers_list.push(assembly_printer);
			});

			break;
	}

	if(!printer || printer?.method == 'remote') {
		toast.error('La impresión enviada no pudo ser realizada, debe configurarla con una impresora local');
		return false;
	}

	if(printers_list.length > 0) {
		_.forEach(printers_list, (printer_name) => { QzPrint(printer_name, response.data.print.commands); });

		if(response.data?.order_id) switch(response.data.type) {
			case 'commander':
			case 'summary':
			case 'rejected':
				store.dispatch('config/setPrinted', { id: response.data.order_id, type: response.data.type, printers: printers_list });
				break;
		}
	}
}