import Repository from './Repository';

const resource = '/store';

export default {
	store(store_id, payload) {
		return Repository.post(`${resource}/${store_id}/orders`, payload);
	},
	updateMassive(store_id, payload) {
		return Repository.patch(`${resource}/${store_id}/orders/massive`, payload);
	},
	update(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orders/${id}`, payload);
	},
	delay(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orders/${id}/delay`, payload);
	},
	reject(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orders/${id}/reject`, payload);
	},
	paying(store_id, id) {
		return Repository.get(`${resource}/${store_id}/orders/${id}/paying`);
	},
	payingCancel(store_id, id) {
		return Repository.delete(`${resource}/${store_id}/orders/${id}/paying`);
	},
	list(store_id) {
		return Repository.get(`${resource}/${store_id}/orders`);
	},
	get(store_id, id) {
		return Repository.get(`${resource}/${store_id}/orders/${id}`);
	},
	destroyItem(store_id, id, item_id) {
		return Repository.delete(`${resource}/${store_id}/orders/${id}/item/${item_id}`);
	},
	invoice(store_id, id, payload) {
		return Repository.post(`${resource}/${store_id}/orders/${id}/billing/invoice`, payload);
	},
	printCommander(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orders/${id}/print/commander`, payload);
	},
	printRejected(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orders/${id}/print/rejected`, payload);
	},
	printSummary(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orders/${id}/print/summary`, payload);
	},
	printInvoice(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orders/${id}/print/invoice`, payload);
	}
}