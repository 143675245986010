<template>
	<nav class="navbar bg-white px-0 py-4 w-100 d-block d-lg-none border-bottom">
		<div class="container-fluid">
			<div class="d-flex justify-content-between w-100">
				<div class="d-flex align-items-center">
					<router-link :to="{ name: 'store.home', params: { store_id: current_store.id } }" class="navbar-brand">
						<img src="@/assets/img/logo/main.png" class="h-rem-8" alt="Logo">
					</router-link>
				</div>
				<div class="d-flex hstack gap-1">
					<a href="javascript:void(0)" class="avatar avatar-md border border-2 border-dashed border-primary text-primary rounded-circle" @click="modalShow" v-if="!hasCart">
						<i class="bi bi-plus-lg text-lg"></i>
					</a>
					<a href="javascript:void(0)" class="avatar avatar-md border border-2 border-danger text-danger rounded-circle" @click="destroy" v-if="hasCart && route.meta?.needCart">
						<i class="bi bi-trash text-lg"></i>
					</a>
					<a href="javascript:void(0)" class="avatar avatar-md border border-2 border-dashed border-warning text-warning rounded-circle" @click="toCatalog" v-if="hasCart && !route.meta?.needCart">
						<i class="bi bi-arrow-clockwise text-xl"></i>
					</a>
					<a href="javascript:void(0)" class="avatar avatar-md border border-2 rounded-circle" :class="{ 'bg-primary border-primary': open, 'bg-danger border-danger': !open }" data-bs-toggle="offcanvas" data-bs-target="#common-menu-store">
						<img :src="logo_default" class="border border-2 rounded-circle border-white" alt="logo" @load="imageReady = true" v-show="imageReady">
						<Skeletor width="42" height="42" class="rounded-circle" v-show="!imageReady" />
					</a>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	import { ref, computed } from 'vue';
	import { useRoute } from 'vue-router';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { current_store, logo_default, status } = composableStore();
			const { hasCart, toCatalog, modalShow, destroy } = composableCart();
			const route = useRoute();

			const imageReady = ref(false);

			const open = computed(() => status.value?.open);

			return { current_store, logo_default, imageReady, open, hasCart, toCatalog, modalShow, destroy, route };
		}
	}
</script>

<style scoped>
	.navbar {
		overflow-y: overlay;
		min-width: 5rem;
		width: 5rem;
	}

	.navbar-brand img {
		height: 2rem !important;
	}

	.nav-link {
		font-size: 1.25rem !important;
		color: white;
	}

	.nav-item:has(.active) {
		background-color: #00ff00;
		border-radius: 0.375rem !important;
	}

	.nav-item:has(.active) .nav-link {
		color: #000;
	}
</style>