<template>
	<div class="container">
		<div class="justify-content-center">
			<div class="d-flex flex-column position-relative">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			buttonBack: Object
		},
		setup() {
			document.body.className = 'layouts-minimum';
		}
	}
</script>

<style scoped>
	.navbar-brand img {
		width: 10rem !important;
	}

	.layouts-minimum .container {
		max-width: 500px !important;
	}
</style>