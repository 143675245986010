<template>
	<div class="d-flex flex-column position-relative scan-body h-100">
		<qr-stream @decode="onDecode" @rejectionHandled="rejectionHandled" @init="onInit">
			<div style="color: red;" class="frame"></div>
		</qr-stream>
	</div>
	<footer class="footer mt-auto py-3 bg-white">
		<div class="container text-center">
			<div class="pb-3">
				<button type="button" class="btn btn-danger w-100" @click="redirectBack">Cancelar</button>
			</div>
			<small class="text-muted">Apuntá la cámara hacia un código Wantti</small>
		</div>
	</footer>
</template>

<script>
	import { ref } from 'vue';
	import { QrStream } from 'vue3-qr-reader';
	import { useToast } from 'vue-toastification';
	import _ from 'lodash';
	import composableRedirect from '@/composables/redirect';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';

	export default {
		components: { QrStream },
		setup() {
			const { redirectBack } = composableRedirect();
			const { codes } = composableStore();
			const { selectCode } = composableCart();
			const toast = useToast();
			const flash = ref(false);
			const baseUrl = 'https://wantti.com/code/';

			const rejectionHandled = () => {
				toast.error('No se pudo acceder a la cámara.');
			}

			const onDecode = (data) => {
				var code = null;

				if(data.startsWith(baseUrl)) {
					var hash = data.replaceAll(baseUrl, '');
					code = _.find(codes.value, (row) => { return row.code == hash });
				}

				if(code) selectCode(code);
				else toast.error('Código inválido.');
			}

			const onInit = async (promise) => {
				try {
					const { capabilities } = await promise;
					flash.value = !!capabilities.torch;
				}catch(error) {
					if(error.name === 'NotAllowedError') {
						toast.error('ERROR: Debes conceder los permisos necesarios.');
					}else if(error.name === 'NotFoundError') {
						toast.error('ERROR: No hay cámaras en éste dispositivo.');
					}else if(error.name === 'NotSupportedError') {
						toast.error('ERROR: No posee una conexión segura.');
					}else if(error.name === 'NotReadableError') {
						toast.error('ERROR: La cámara está en uso por otra aplicación.');
					}else if(error.name === 'OverconstrainedError') {
						toast.error('ERROR: La cámara no es compatible.');
					}else if(error.name === 'StreamApiNotSupportedError') {
						toast.error('ERROR: El entorno no es compatible.');
					}else if(error.name === 'InsecureContextError') {
						toast.error('ERROR: El acceso a la cámara debe realizarse en un sitio seguro.');
					}else{
						toast.error('ERROR: No se pudo acceder a la cámara.');
					}

					redirectBack();
				}
			}

			return { redirectBack, rejectionHandled, onDecode, onInit, flash };
		}
	}
</script>