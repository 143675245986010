<template>
	<div class="row">
		<div class="col-12 hstack gap-4">
			<a href="/auth/facebook" class="btn w-100 btn-facebook">
				<i class="bi bi-facebook pe-2"></i>
				Facebook
			</a>
			<a href="/auth/google" class="btn w-100 btn-google">
				<i class="bi bi-google pe-2"></i>
				Google
			</a>
		</div>
	</div>
	<div class="pt-5 pb-3 text-center">
		<span class="text-xs text-uppercase fw-semibold">{{ description }}</span>
	</div>
</template>

<script>
	export default {
		props: {
			description: String
		}
	}
</script>