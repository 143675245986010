<template>
	<layouts-basic>
		<div class="text-center mb-7">
			<div class="d-inline-block mb-6">
				<img src="@/assets/img/logo/main.png" class="h-rem-12" alt="logo">
			</div>
			<h1 class="ls-tight fw-bolder h2">¡Bienvenido nuevamente!</h1>
			<p class="mt-2">Elegí cómo querés ingresar</p>
		</div>
		<user-login-social-primary description="O con email y contraseña" />
		<form @submit.prevent="submit">
			<div class="mb-3">
				<div class="form-floating" :class="{'is-invalid': validation.email.$error}">
					<input type="email" id="email" v-model="email" class="form-control" :class="{'is-invalid': validation.email.$error}" placeholder="Ingresá tu email" v-focus>
					<label for="email">Email</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.email.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="mb-3">
				<div class="form-floating" :class="{'is-invalid': validation.password.$error}">
					<input type="password" id="password" name="new-password" v-model="password" class="form-control" :class="{'is-invalid': validation.password.$error}" placeholder="Ingresá tu contraseña" autocomplete="current-password">
					<label for="password">Contraseña</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.password.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="mb-3 text-center" v-if="validationExternal.length > 0">
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div class="text-end mb-3">
				¿No recordás tu contraseña? <router-link :to="{name: 'user.forgot.email'}" class="text-secondary fw-semibold">Recuperala acá.</router-link>
			</div>
			<div class="mt-5">
				<button type="submit" class="btn btn-primary w-100" :disabled="submitting">Iniciar sesión</button>
			</div>
		</form>
		<div class="mt-6 text-center">
			¿No tienes una cuenta? <router-link :to="{name: 'signin'}" class="text-secondary fw-semibold">Crear cuenta</router-link>
		</div>
	</layouts-basic>
</template>

<script>
	import { reactive, toRefs } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength, email } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { submitting, validationExternal, catchError } = composableForm();

			const form = reactive({
				email: '',
				password: ''
			});

			const validation = useVuelidate({
				email: {
					required,
					email
				},
				password: {
					required,
					minLength: minLength(8),
					maxLength: maxLength(16)
				}
			}, form);

			async function submit() {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				store.dispatch('auth/login', form).then(setUser).catch(catchError);
			}

			const setUser = () => {
				store.dispatch('auth/me').then(() => router.push({name: 'select'})).catch(catchError);
			}

			return { ...toRefs(form), validation, validationExternal, submitting, submit };
		}
	}
</script>