import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';

//Styles
import '@/assets/css/customization.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

//Document height
const documentHeight = () => document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
window.addEventListener('resize', documentHeight);
documentHeight();

const app = createApp(App);

//Vue Store
import store from '@/store';
app.use(store);

//Vue Router
import router from '@/router';
app.use(router);

//Register all "Single File Components"
const files = require.context('./components', true, /\.vue$/i);
files.keys().map(key => {
	var routeArray = key.split('/');
	routeArray.shift();
	routeArray[routeArray.length - 1] = routeArray[routeArray.length - 1].split('.')[0].toLowerCase();
	app.component(routeArray.join('-'), files(key).default);
});

//Vue Analytics
import VueGtag from 'vue-gtag-next';
app.use(VueGtag, { property: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }});

//Vue Skeletor
import { Skeletor } from 'vue-skeletor';
app.component(Skeletor.name, Skeletor);

//Vue Number Format
import VueNumberFormat from 'vue-number-format';
app.use(VueNumberFormat, { prefix: '$ ', thounsand: '.', decimal: ',', precision: 2, acceptNegative: true });

//Vue ScrollTo
import VueScrollTo from 'vue-scrollto';
app.use(VueScrollTo);

//Vue Google Maps
import VueGoogleMaps from '@fawmi/vue-google-maps';
app.use(VueGoogleMaps, { load: { key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY, libraries: 'places' } });

//Vue Toast
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
app.use(Toast, { position: 'top-center', transition: 'Vue-Toastification__fade', timeout: 3000, closeOnClick: true, pauseOnFocusLoss: true, pauseOnHover: true, draggable: true, showCloseButtonOnHover: false, closeButton: false, icon: false });

//Moment Js
import moment from 'moment';
import 'moment/locale/es';
app.use(moment);

//Maska
import Maska from 'maska';
app.use(Maska);

//Vue Cookie Next
import { VueCookieNext } from 'vue-cookie-next';
app.use(VueCookieNext);

//Vue3 Touch Events
import Vue3TouchEvents from 'vue3-touch-events';
app.use(Vue3TouchEvents, { touchHoldTolerance: 400 });

//Personalizer
import helperPersonalizer from '@/helpers/Personalizer';
app.use(helperPersonalizer);

//Unduplicate
import * as Unduplicate from '@/helpers/Unduplicate';
app.use(Unduplicate);

//Screensaver
import ScreenSaver from '@/helpers/ScreenSaver';
app.use(ScreenSaver);

//Order Observer
import OrderObserver from '@/observers/Order';
app.use(OrderObserver);

//Payout Observer
import PayoutObserver from '@/observers/Payout';
app.use(PayoutObserver);

//QZ Tray
import * as QZTray from '@/helpers/QZTray';
app.use(QZTray);

//Guide
import * as Guide from '@/helpers/Guide';
app.use(Guide);

//Vue Focus directive
app.directive('focus', { mounted(el, active) { if(active.value == undefined || active.value == true) el.focus(); } });

//App Mount
app.mount('body');