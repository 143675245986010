<template>
	<div class="d-flex align-items-center text-start">
		<div class="avatar avatar-md rounded-circle me-3" v-if="store.logo">
			<img :src="store.logo_url" alt="logo" @load="imageReady = true" v-show="imageReady">
			<Skeletor width="100%" height="100%" class="rounded" v-show="!imageReady" />
		</div>
		<div>
			<span class="fw-semibold h4 d-block">{{ store.name }}</span>
			<small class="text-dark d-block" v-if="store.description">{{ store.description }}</small>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue';

	export default {
		props: {
			store: Object
		},
		setup() {
			const imageReady = ref(false);

			return { imageReady };
		}
	}
</script>