<template>
	<user-settings-header />
	<!-- Main -->
	<main class="py-5 flex-grow-1">
		<!-- Container -->
		<div class="container-fluid mw-screen-md vstack gap-6 min-h-full">
			<form class="card" @submit.prevent="submit">
				<div class="card-header h4 fw-bolder">Configuración general</div>
				<div class="card-body">
					<div class="row">
						<div class="col-12">
							<div class="form-floating" :class="{'is-invalid': validation.fullname.$error}">
								<input type="text" id="fullname" v-model="fullname" class="form-control" :class="{'is-invalid': validation.fullname.$error}" placeholder="Ingresá tu nombre">
								<label for="fullname">Nombre</label>
							</div>
							<span class="mt-1 invalid-feedback" v-for="error of validation.fullname.$errors" :key="error.$uid">{{ error.$message }}</span>
						</div>
						<div class="col-12 mt-8">
							<h5 class="mb-4">¿Qué notificaciones querés recibir?</h5>
							<div class="form-check mt-2">
								<input type="checkbox" id="notification_types_OrderNew" v-model="notification_types" value="OrderNew" class="form-check-input">
								<label class="form-check-label" for="notification_types_OrderNew">Pedidos nuevos.</label>
							</div>
						</div>
						<div class="col-12 mt-5">
							<h5 class="mb-4">¿Dónde las querés recibir?</h5>
							<div class="form-check mt-2">
								<input type="checkbox" id="notification_channels_mail" v-model="notification_channels" value="database" class="form-check-input">
								<label class="form-check-label" for="notification_channels_mail">
									Por email.
									<small class="d-block text-muted">Al correo <b>{{ user.email }}</b>.</small>
								</label>
							</div>
							<div class="form-check mt-2">
								<input type="checkbox" id="notification_channels_push" v-model="notification_channels" value="push" class="form-check-input">
								<label class="form-check-label" for="notification_channels_push">
									Al navegador.
									<small class="d-block text-muted">Mediante notificaciones tipo push.</small>
								</label>
							</div>
						</div>
						<div class="col-12 mt-5 text-center" v-if="validationExternal.length > 0">
							<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<button type="button" class="btn btn-secondary" :disabled="submitting" @click="redirectBack">Cancelar</button>
					<button type="submit" class="btn btn-primary" :disabled="submitting">Guardar</button>
				</div>
			</form>
			<div class="card border" v-if="!current_store.pivot.owner">
				<div class="card-body hstack gap-2">
					<div>
						<h5 class="text-danger mb-0">Dejar tienda</h5>
						<p class="text-sm text-muted">Quitará tu usuario de la tienda <b>{{ current_store.name }}</b>.</p>
					</div>
					<div class="ms-auto">
						<button type="button" class="btn btn-sm btn-danger" @click="modalLeave" :disabled="submitting">Eliminar</button>
					</div>
				</div>
			</div>
		</div>
	</main>

	<common-modal-confirm description="¿Realmente deseas quitar tu usuario de esta tienda?" @confirm="leave" />
</template>

<script>
	import { reactive, toRefs, computed } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/helpers/i18n/validators';
	import { useToast } from 'vue-toastification';
	import { Modal } from 'bootstrap';
	import composableRedirect from '@/composables/redirect';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import MeRepository from '@/repositories/MeRepository';
	import StoreRepository from '@/repositories/StoreRepository';

	export default {
		setup() {
			const { redirectBack } = composableRedirect();
			const { current_store } = composableStore();
			const { submitting, validationExternal, catchError } = composableForm();
			const toast = useToast();
			const user = computed(() => store.getters['auth/user']);

			const form = reactive({
				fullname: user.value.fullname,
				notification_types: user.value.notification_types ?? [],
				notification_channels: user.value.notification_channels ?? []
			});

			const validation = useVuelidate({
				fullname: {
					required
				}
			}, form);

			const submit = async () => {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await MeRepository.update(form)
					.then((response) => {
						store.dispatch('auth/setUser', response.data);
						redirectBack();
					})
					.catch(catchError);
				submitting.value = false;
			}

			const modalLeave = () => {
				Modal.getInstance(document.getElementById('common-modals-confirm')).show();
			}

			const leave = async () => {
				submitting.value = true;
				await StoreRepository.leave(current_store.value.id)
					.then(() => {
						toast.success(`Tu usuario fue correctamente eliminado de la tienda ${current_store.value.name}.`);
						router.push({ name: 'select' });
					})
					.catch((response) => catchError(response, true));
				submitting.value = false;
			}

			return { redirectBack, current_store, ...toRefs(form), user, validation, validationExternal, submitting, submit, modalLeave, leave };
		}
	}
</script>