<template>
	<div class="px-6 p-lg-0 h-100">
		<div class="d-flex justify-content-center h-100">
			<div class="col-md-12 col-lg-10 col-xl-8 col-xxl-6 d-flex flex-column justify-content-center py-16 py-lg-16 px-lg-20 position-relative">
				<div class="row align-items-center gy-16">
					<div class="col-12 col-md-6 text-center">
						<img :src="require(`@/assets/img/illustrations/duplicated.png`)" class="img-fluid">
					</div>
					<div class="col-12 col-md-6 text-center">
						<div class="px-5">
							<div class="lh-xs ls-tight display-5 text-dark">¡Atención!</div>
							<div class="h3 mt-3">La aplicación está abierta en otra ventana. Haz clic en "<b>Usar aquí</b>" para abrirla en esta ventana.</div>
							<div class="mt-8">
								<button type="button" class="btn btn-dark" @click="useHere">Usar aquí</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router';
	import { init } from '@/helpers/Unduplicate';

	export default {
		setup() {
			const useHere = () => {
				init();
				router.push({ name: 'select' });
			}

			return { useHere };
		}
	}
</script>