<template>
	<div class="d-flex flex-column" style="height: 100%;">
		<common-menu-main />
		<header>
			<div class="container">
				<slot />
			</div>
		</header>
		<common-footer-minimum />
	</div>
</template>

<script>
	export default {
		setup() {
			document.body.className = 'layouts-main';
		}
	}
</script>

<style>
	header {
		background-color: var(--bs-body-bg);
	}

	body.layouts-main {
		padding-top: 4.4rem;
	}
</style>