<template>
	<div class="pt-2 px-5 text-center">
		<span class="badge border" :class="{'border-success text-success': openLocal, 'border-danger text-danger': !openLocal}" v-if="open && typeLocal">Local</span>
		<span class="badge border" :class="{'border-success text-success': openTakeaway, 'border-danger text-danger': !openTakeaway}" v-if="open && typeDelivery">Take away</span>
		<span class="badge border" :class="{'border-success text-success': openDelivery, 'border-danger text-danger': !openDelivery}" v-if="open && typeTakeaway">Delivery</span>
		<span class="badge bg-danger" v-if="!open">Cerrado</span>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { status } = composableStore();

			const open = computed(() => status.value?.open);
			const services = computed(() => (status.value?.type?.inmediate?.services ?? []));

			const typeLocal = computed(() => ('LOCAL' in services.value));
			const openLocal = computed(() => (open.value && 'LOCAL' in services.value && services.value['LOCAL']));

			const typeTakeaway = computed(() => ('TAKEAWAY' in services.value));
			const openTakeaway = computed(() => (open.value && 'TAKEAWAY' in services.value && services.value['TAKEAWAY']));

			const typeDelivery = computed(() => ('DELIVERY' in services.value));
			const openDelivery = computed(() => (open.value && 'DELIVERY' in services.value && services.value['DELIVERY']));

			return { open, typeLocal, openLocal, typeTakeaway, openTakeaway, typeDelivery, openDelivery };
		}
	}
</script>

<style scoped>
	.badge {
		padding: .2rem .5rem !important;
	}

	.badge + .badge {
		margin-left: 7px;
	}
</style>