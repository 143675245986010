import Repository from './Repository';

const resource = '/store';

export default {
	getProfile(id) {
		return Repository.get(`${resource}/${id}/profile`);
	},
	getMap(id, map_id) {
		return Repository.get(`${resource}/${id}/map/${map_id}`);
	},
	getCatalog(id) {
		return Repository.get(`${resource}/${id}/catalog`);
	},
	assemblies(id) {
		return Repository.get(`${resource}/${id}/assemblies`);
	},
	updateStatus(id, payload) {
		return Repository.patch(`${resource}/${id}/status`, payload);
	},
	updateAutoclose(id, payload) {
		return Repository.patch(`${resource}/${id}/autoclose`, payload);
	},
	shippingCost(id, payload) {
		return Repository.post(`${resource}/${id}/shipping/cost`, payload);
	},
	scheduleOptions(id, payload) {
		return Repository.post(`${resource}/${id}/schedule/options`, payload);
	},
	promocodeCheck(id, payload) {
		return Repository.post(`${resource}/${id}/promocode/check`, payload);
	},
	getCatalogItems(id) {
		return Repository.get(`catalog/${id}/items`);
	},
	getCatalogSections(id) {
		return Repository.get(`catalog/${id}/sections`);
	},
	leave(id) {
		return Repository.delete(`${resource}/${id}/leave`);
	}
}
