<template>
	<div class="modal" id="common-modals-delete" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content shadow-3">
				<div class="modal-header">
					<h4 class="modal-title">{{ title }}</h4>
				</div>
				<div class="modal-body">
					<p class="text-break">{{ description }}</p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cancelar</button>
					<button type="button" class="btn btn-sm btn-danger" @click="destroy">Eliminar</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, watch, computed, onMounted } from 'vue';
	import { Modal } from 'bootstrap';

	export default {
		emits: ['select', 'destroy'],
		props: {
			title: String,
			target: Object
		},
		setup(props, { emit }) {
			const modal = ref(null);

			onMounted(() => {
				modal.value = new Modal(document.getElementById('common-modals-delete'));
				document.getElementById('common-modals-delete').addEventListener('hidden.bs.modal', () => {
					emit('select', null);
				});
			});

			const description = computed(() => {
				if(props.target) return props.target.description;
				else return null;
			});

			const destroy = () => {
				emit('destroy', props.target.id);
				modal.value.hide();
			}

			watch(() => props.target, () => {
				if(props.target) modal.value.show();
			});

			return { description, destroy };
		}
	}
</script>