<template>
	<div class="card border-1 border-lighter shadow shadow-3-hover">
		<!-- Card Body -->
		<div class="card-body p-3 hstack gap-3">
			<div>
				<div class="avatar avatar-lg rounded">
					<img :src="logo_default" alt="logo">
				</div>
			</div>
			<div class="overflow-hidden">
				<router-link :to="{ name: 'store.home', params: { store_id: store.id } }" class="h4 fw-semibold link-dark stretched-link mb-1 d-block text-truncate">{{ store.name }}</router-link>
				<div class="text-sm text-muted text-truncate info-extra">{{ store.address.small }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';

	export default {
		props: {
			store: Object
		},
		setup(props) {
			const logo_default = computed(() => {
				if(props.store.logo) return props.store.logo_url;
				else return require('@/assets/img/icons/no-image.jpg');
			});

			return { logo_default };
		}
	}
</script>