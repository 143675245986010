<template>
	<div class="row">
		<div class="col" v-for="(item, index) in length" v-bind:key="index">
			<input type="tel" class="form-control form-control-lg text-center code-number" placeholder="*" :ref="el => { refs[index] = el }" v-model="inputs[index]" @input="handleInput($event, index)" maxlength="1">
		</div>
	</div>
</template>

<script>
	import { ref, onMounted, watch } from 'vue';

	export default {
		props: {
			length: {
				type: Number,
				default: 4
			}
		},
		setup(props, {emit}) {
			const inputs = ref([]);
			const refs = ref([]);

			onMounted(() => refs.value[0].select());

			watch(inputs.value, (newValue) => {
				emit('updated', newValue.join(''));
			});

			const handleInput = (e, index) => {
				inputs.value[index] = e.target.value.replace(/[^\d]/g,'').charAt(0);
				if(index < (props.length - 1) && inputs.value[index].length > 0) refs.value[(index + 1)].select();
			}

			const setCode = (code) => {
				for(var i = 0; i < props.length; i++) inputs.value[i] = (code) ? String(code).charAt(i) : '';
				refs.value[0].select();
			}

			return { inputs, refs, handleInput, setCode };
		}
	}
</script>

<style scoped>
	.code-number {
		min-height: 2em;
		padding: 0.5rem;
		font-size: 1.5rem;
		-moz-appearance:textfield;
	}

	.code-number::-webkit-outer-spin-button,
	.code-number::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
</style>