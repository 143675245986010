<template>
	<div class="card border">
		<div class="card-body d-flex align-items-center justify-content-between p-5">
			<a href="javascript:void(0);" class="h4 modal-title text-reset stretched-link" @click="redirectMap"><i class="bi bi-map pe-2"></i>Mapas</a>
			<button type="button" class="btn btn-neutral btn-sm btn-square"><i class="bi bi-chevron-right text-md"></i></button>
		</div>
	</div>
</template>

<script>
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { redirectMap } = composableCart();

			return { redirectMap };
		}
	}
</script>