<template>
	<div class="modal fade" id="common-modal-screensaver" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="modal-content shadow-3">
				<div class="modal-body text-center pt-14 pb-10 px-10 vstack gap-4">
					<img src="@/assets/img/logo/main.png" class="h-rem-12 mx-auto" alt="Logo">
					<i class="bi bi-plus-lg text-xl"></i>
					<store-cardsmall :store="current_store" class="mx-auto" />
					<div class="align-items-center gap-3 mt-8 ps-2 pe-5 py-2 border border-dark text-xs fw-bold text-dark text-center rounded-3" v-if="pendings == 0">
						Sin pedidos pendientes de aprobación.
					</div>
					<div class="d-inline-flex align-items-center gap-3 mt-8 ps-2 pe-5 py-2 bg-dark text-xs fw-bold text-white rounded-3" v-if="pendings == 1">
						<span class="badge bg-danger bg-opacity-75 rounded text-uppercase">Atención</span>
						Hay 1 pedido que está pendiente de aprobación.
					</div>
					<div class="d-inline-flex align-items-center gap-3 mt-8 ps-2 pe-5 py-2 bg-dark text-xs fw-bold text-white rounded-3" v-if="pendings >= 2">
						<span class="badge bg-danger bg-opacity-75 rounded text-uppercase">Atención</span>
						Hay {{ pendings }} pedidos que están pendientes de aprobación.
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import _ from 'lodash';
	import { Modal } from 'bootstrap';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { current_store } = composableStore();
			const pendings = computed(() => _.filter(store.getters['orders/list'], { status: 'PENDING' }).length);
			const modal = ref(null);
			const imageReady = ref(false);

			onMounted(() => modal.value = new Modal(document.getElementById('common-modal-screensaver')));

			return { current_store, imageReady, pendings };
		}
	}
</script>