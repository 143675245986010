<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4">
			<div class="row g-0 align-items-center">
				<div class="col">
					<div class="d-flex align-items-center gap-3">
						<button type="button" class="btn btn-link p-0 text-reset text-md" :disabled="submitting" @click="toCatalog"><i class="bi bi-chevron-left"></i></button>
						<div class="vr opacity-20 my-1"></div>
						<h1 class="h4 ls-tight">Checkout</h1>
					</div>
				</div>
				<div class="col-auto">
					<div class="hstack gap-2 justify-content-end">
						<button guide="cart-header-checkout-button-finish" type="button" class="btn btn-sm btn-primary" :disabled="!canFinish || submitting" @click="finish">
							{{ (submitting) ? 'Enviando...' : 'Finalizar' }}<i class="bi bi-chevron-right ps-1" v-if="!submitting"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import composableCart from '@/composables/cart';

	export default {
		props: {
			submitting: Boolean
		},
		setup() {
			const { canFinish, toCatalog, finish } = composableCart();

			return { canFinish, toCatalog, finish };
		}
	}
</script>