<template>
	<teleport to="body">
		<div class="modal" id="orders-modal-reject" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content shadow-3" v-if="order">
					<div class="modal-header">
						<h4 class="modal-title">Rechazar pedido N°: {{ order.number }}</h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body py-0">
						<div class="row">
							<div class="col-12 px-0">
								<div class="list-group list-group-flush">
									<a href="javascript:void(0)" class="list-group-item px-6 py-3 fw-semibold text-danger-hover" v-for="(description, reject_reason) in REJECTED_REASONS" v-bind:key="reject_reason" @click="submit(reject_reason)">{{ description }}</a>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal" :disabled="submitting">Cancelar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import OrdersRepository from '@/repositories/OrdersRepository';
	import composableStore from '@/composables/store';
	import composableOrder from '@/composables/order';
	import composableForm from '@/composables/form';
	import { Modal } from 'bootstrap';

	export default {
		emits: ['selectReject'],
		props: {
			order: Object
		},
		setup(props, { emit }) {
			const { current_store } = composableStore();
			const { REJECTED_REASONS } = composableOrder();
			const { submitting, catchError } = composableForm();
			const modalRejectView = ref(null);

			onMounted(() => {
				modalRejectView.value = new Modal(document.getElementById('orders-modal-reject'));

				document.getElementById('orders-modal-reject').addEventListener('hidden.bs.modal', () => {
					emit('selectReject', null);
				});
			});

			watch(() => props.order, (newValue) => {
				if(newValue) modalRejectView.value.show();
			});

			const submit = async (reject_reason = null) => {
				if(submitting.value) return false;

				submitting.value = true;
				await OrdersRepository.reject(current_store.value.id, props.order.id, { reject_reason: reject_reason })
					.then(() => modalRejectView.value.hide())
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { REJECTED_REASONS, submitting, submit };
		}
	}
</script>