<template>
	<teleport to="body">
		<div id="catalog-item-detail" class="offcanvas offcanvas-bottom min-h-full" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
			<div class="offcanvas-header container container-offcanvas py-3 ps-2 pe-0 gap-2" v-if="item">
				<button type="button" class="btn btn-square text-reset shadow-none" data-bs-dismiss="offcanvas"><i class="bi bi-chevron-left h2"></i></button>
				<div class="flex-fill">
					<div>
						<h4 class="h4" v-show="hasItem">{{ item.name }}</h4>
						<Skeletor width="200" class="mt-4 h4" v-show="!hasItem" />
					</div>
					<div class="mt-1">
						<div class="d-flex align-items-center gap-3" v-show="hasItem">
							<h4 class="h4 text-nowrap">{{ vueNumberFormat(priceReal, $personalizer.currency.value) }}</h4>
							<div class="d-flex align-items-center gap-3" v-if="hasDiscount">
								<div class="text-muted" v-if="price != priceReal"><del>{{ vueNumberFormat(price, $personalizer.currency.value) }}</del></div>
								<div class="badge rounded-pill bg-danger text-end">{{ discountDescription }}</div>
							</div>
						</div>
						<Skeletor width="100" class="mt-4 h4" v-show="!hasItem" />
					</div>
				</div>
			</div>
			<div class="offcanvas-body container container-offcanvas pt-0 position-relative" v-if="item">
				<div class="row mx-n6" v-show="!!item.image_md">
					<div class="col-12 p-0">
						<div class="bg-cover store-cover" :style="{'background-image': `url(${item.image_md})`}" v-if="!imageSkeletor && !!item.image_md"></div>
						<Skeletor height="250" class="bg-cover store-cover" v-if="imageSkeletor" />
					</div>
				</div>
				<div class="row description-area">
					<div class="col-12">
						<p class="text-muted pt-5" v-show="hasItem">{{ item.description }}</p>
						<Skeletor height="50" class="mt-2 text-muted" v-show="!hasItem" />
						<div class="my-5" v-if="hasItem">
							<catalog-group v-for="group in item.groups" v-bind:key="group.id" @update="update" :group="group" />
						</div>
						<div class="mt-3 mb-7" v-if="hasItem">
							<label class="form-label">Querés agregar una aclaración sobre éste producto?</label>
							<input type="text" class="form-control" v-model="comments" placeholder="Agregar aquí la aclaración" maxlength="100">
						</div>
					</div>
				</div>
			</div>
			<div class="offcanvas-footer container container-offcanvas px-6 py-4 border-top">
				<div class="row g-4">
					<div class="offset-md-2 col-6 col-md-4">
						<div class="input-group input-group-sm">
							<button type="button" @click="decrease" class="btn btn-outline-dark" :disabled="submitting"><i class="bi bi-dash-lg"></i></button>
							<input type="text" v-model="quantity" class="form-control border-dark text-center fw-semibold" readonly="">
							<button type="button" @click="increase" class="btn btn-outline-dark" :disabled="submitting"><i class="bi bi-plus-lg"></i></button>
						</div>
					</div>
					<div class="col-6 col-md-4">
						<div class="d-grid gap-2">
							<button class="btn btn-sm btn-dark" type="button" :disabled="submitting" @click="addToCart">Agregar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, watch, watchEffect, onMounted } from 'vue';
	import store from '@/store';
	import composableCart from '@/composables/cart';
	import composableForm from '@/composables/form';
	import { Offcanvas } from 'bootstrap';
	import moment from 'moment';
	import _ from 'lodash';

	export default {
		emits: ['selectItem'],
		props: {
			item: Object
		},
		setup(props, { emit }) {
			const { hasCart, validateItem, submitting } = composableCart();
			const { catchError } = composableForm();
			const imageSkeletor = ref(true);
			const selection = ref([]);
			const comments = ref(null);

			const hasItem = computed(() => !!props.item?.id);

			const offcanvas = ref(null);

			onMounted(() => {
				offcanvas.value = new Offcanvas(document.getElementById('catalog-item-detail'));
				document.getElementById('catalog-item-detail').addEventListener('hidden.bs.offcanvas', () => emit('selectItem', null));
				document.getElementById('catalog-item-detail').addEventListener('shown.bs.offcanvas', () => {
					comments.value = '';
					selection.value = [];
					document.querySelector('.description-area').scrollIntoView({ behavior: 'smooth' });
				});
			});

			watch(() => props.item, () => {
				if(props.item) {
					quantity.value = 1;
					imageSkeletor.value = false;
					offcanvas.value.show();
				}
			});

			const unitPrice = computed(() => {
				var price = props.item.price;

				_.forEach(selection.value, (group) => {
					_.forEach(group.additionals, (additional) => {
						price+= additional.quantity * additional.unit_price;
					});
				});

				return price;
			});

			const price = computed(() => {
				return (unitPrice.value * quantity.value);
			});

			const dayNumber = moment().day();
			const catalogType = computed(() => (store.getters['code/isDefined']) ? store.getters['code/type'] : 'ONLINE');
			const discount = computed(() => { return _.find(store.getters['store/discounts'], (row) => { return (_.indexOf(row.catalogs, catalogType.value) !== -1 && (row.days == null || _.indexOf(row.days, dayNumber) != -1) && (row.target == null || _.indexOf(row.target, props.item.id) != -1)); }); });
			const hasDiscount = computed(() => { return !!discount.value; });
			const discountAmount = computed(() => {
				if(!hasDiscount.value) return 0;

				var amount = 0;
				switch(discount.value.type) {
					case 'PERCENTAGE':
						amount = Math.round(price.value * (discount.value.value / 100) * 100) / 100;
						break;
					case 'PRODUCTS':
						var factor = Math.trunc(quantity.value / discount.value.products_from);
						var free = factor * (discount.value.products_from - discount.value.products_to);

						amount = free * unitPrice.value;
						break;
				}

				return amount;
			});
			const priceReal = computed(() => { return price.value - discountAmount.value; });

			const discountDescription = computed(() => {
				var description = null;

				if(hasDiscount.value) {
					switch(discount.value.type) {
						case 'PERCENTAGE':
							description = `${discount.value.value}% OFF`;
							break;
						case 'PRODUCTS':
							description = `Lleva ${discount.value.products_from} paga ${discount.value.products_to}`;
							break;
					}
				}

				return description;
			});

			const quantity = ref(1);
			const decrease = () => {
				if(quantity.value == 1) return;
				quantity.value-= 1;
			}
			const increase = () => quantity.value++;

			const update = (data) => {
				_.remove(selection.value, (row) => { return row.group_id == data.group_id; });
				selection.value.push(data);
			}

			const addToCart = () => {
				if(!validateItem(props.item, selection.value, true) || !hasCart.value) return;

				var itemCart = {
					id: props.item.id,
					name: props.item.name,
					quantity: quantity.value,
					unit_price: props.item.price,
					discount: 0,
					comments: comments.value,
					additionals: []
				};

				_.forEach(selection.value, (group) => {
					_.forEach(group.additionals, (additional) => {
						var additionalSelection = JSON.parse(JSON.stringify(additional));
						additionalSelection.group_id = group.group_id;
						itemCart.additionals.push(additionalSelection);
					});
				});

				store.dispatch('cart/addItem', itemCart).then(offcanvas.value.hide()).catch(catchError);
			}

			watchEffect(() => {
				if(hasItem.value) {
					imageSkeletor.value = !!props.item.image_md;

					if(props.item.image_md != null) {
						var loadImage = new Image();
						loadImage.onload = function() { imageSkeletor.value = false; };
						loadImage.src = props.item.image_md;
					}
				}
			});

			return { hasItem, imageSkeletor, price, hasDiscount, discount, discountDescription, priceReal, quantity, decrease, increase, update, comments, addToCart, submitting };
		}
	}
</script>

<style scoped>
	.store-cover {
		height: 250px;
		background-position: center;
	}
</style>