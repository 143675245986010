<template>
	<teleport to="body">
		<div id="common-menu-store" class="offcanvas offcanvas-start" tabindex="-1" aria-labelledby="offcanvasRightLabel">
			<div class="offcanvas-header">
				<img src="@/assets/img/logo/main.png" class="h-rem-8 mx-auto" alt="Logo">
			</div>
			<div class="offcanvas-body pt-2 px-0">
				<common-menu-status />
				<!-- Divider -->
				<hr class="navbar-divider mb-0 opacity-70">
				<!-- Navigation -->
				<ul class="list-group list-group-flush">
					<router-link :to="{ name: 'store.orders.kanban', params: { store_id: current_store.id } }" class="list-group-item px-5 py-3" :class="{'active': (route.name == 'store.orders.kanban')}" v-if="isAdmin">
						<i class="bi bi-kanban pe-3"></i>Pedidos
					</router-link>
					<router-link :to="{ name: 'store.maps.index', params: { store_id: current_store.id } }" class="list-group-item px-5 py-3" :class="{'active': (route.name == 'store.maps.index')}" v-if="!isPlanFree && maps.length > 0">
						<i class="bi bi-map pe-3"></i>Mapas
					</router-link>
					<router-link :to="{ name: 'store.codes.index', params: { store_id: current_store.id } }" class="list-group-item px-5 py-3" :class="{'active': (route.name == 'store.codes.index')}" v-if="!isPlanFree && codes.length > 0">
						<i class="bi bi-qr-code pe-3"></i>Códigos
					</router-link>
					<router-link :to="{ name: 'store.cashbox.index', params: { store_id: current_store.id } }" class="list-group-item px-5 py-3" :class="{'active': (route.name == 'store.cashbox.index')}" v-if="!isPlanFree && isAdmin">
						<i class="bi bi-inbox pe-3"></i>Caja
					</router-link>
				</ul>
			</div>
			<div class="offcanvas-footer p-4 bg-dark">
				<div class="navbar-user d-flex" id="sidebarUser">
					<router-link :to="{ name: 'store.config.index', params: { store_id: current_store.id } }" class="text-lg text-white text-opacity-75 text-white-hover" v-if="isAdmin">
						<i class="bi bi-gear"></i>
					</router-link>
					<a href="javascript:void(0)" class="avatar avatar-md border border-2 border-dashed border-primary text-primary rounded-circle" @click="orderNew" v-if="!hasCart">
						<i class="bi bi-plus-lg text-lg"></i>
					</a>
					<a href="javascript:void(0)" class="avatar avatar-md border border-2 border-danger text-danger rounded-circle" @click="orderDestroy" v-if="hasCart && route.meta?.needCart">
						<i class="bi bi-trash text-lg"></i>
					</a>
					<a href="javascript:void(0)" class="avatar avatar-md border border-2 border-dashed border-warning text-warning rounded-circle" @click="toCatalog" v-if="hasCart && !route.meta?.needCart">
						<i class="bi bi-arrow-clockwise text-xl"></i>
					</a>
					<a href="javascript:void(0);" class="text-lg text-white text-opacity-75 text-white-hover" @click="userMenu" v-if="hasUser">
						<i class="bi bi-person"></i>
					</a>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import { Modal, Offcanvas } from 'bootstrap';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { current_store, isPlanFree, isAdmin, codes, maps } = composableStore();
			const { hasCart, toCatalog, modalShow, destroy } = composableCart();
			const route = useRoute();

			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);

			const modalMenuMain = ref(null);
			onMounted(() => modalMenuMain.value = new Offcanvas(document.getElementById('common-menu-store')));

			const orderNew = () => {
				modalMenuMain.value.hide();
				modalShow();
			}

			const orderDestroy = () => {
				modalMenuMain.value.hide();
				destroy();
			}

			const userMenu = () => {
				modalMenuMain.value.hide();
				var modalScreenSaver = Modal.getInstance(document.getElementById('common-menu-user'));
				modalScreenSaver.show();
			}

			return { current_store, isPlanFree, isAdmin, codes, maps, route, hasUser, user, hasCart, toCatalog, orderNew, orderDestroy, userMenu };
		}
	}
</script>

<style scoped>
	.offcanvas.offcanvas-start {
		width: 300px !important;
	}
</style>
