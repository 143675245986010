<template>
	<common-menu-mobile />
	<div class="d-flex flex-column flex-lg-row h-100">
		<common-menu-shortcuts />
		<div class="flex-grow-1 overflow-y-lg-auto d-flex flex-column main-slot">
			<slot />
		</div>
	</div>

	<cart-modal-new />
	<common-menu-main />
	<common-menu-user :user="user" v-if="hasUser" />
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);

			return { hasUser, user };
		}
	}
</script>