<template>
	<config-header />
	<main class="py-5 flex-grow-1">
		<div class="container-fluid mw-screen-md vstack gap-5 min-h-full">
			<config-sound />
			<config-printers v-if="!isPlanFree" />
		</div>
	</main>
</template>

<script>
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { isPlanFree } = composableStore();

			return { isPlanFree };
		}
	}
</script>