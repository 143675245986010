import TokensRepository from '@/repositories/TokensRepository';
import UsersRepository from '@/repositories/UsersRepository';
import MeRepository from '@/repositories/MeRepository';
import router from '@/router';

const DEFAULT = {
	status: 'guest',
	user: null,
	token: null
};

const AuthModule = {
	namespaced: true,
	state: DEFAULT,
	getters: {
		token(state) {
			return state.token;
		},
		hasUser(state) {
			return (state.user != null);
		},
		user(state) {
			return state.user;
		},
		userHasPhone(state) {
			if (state.user == null) return false;
			else return state.user.phone != null;
		},
		userHasVerifiedPhone(state) {
			if (state.user == null) return false;
			else return !!state.user.phone_verified;
		},
		userGetPicture(state) {
			if (state.user == null) return '';
			else return state.user.avatar;
		},
		userGetFullname(state) {
			if (state.user == null) return '';
			else return state.user.fullname;
		},
		userGetEmail(state) {
			if (state.user == null) return '';
			else return state.user.email;
		},
		userGetPhone(state) {
			if (state.user == null) return '';
			else return state.user.phone;
		},
		orderRequestFilters(state, getters) {
			if(!getters['hasUser']) return { name: 'login' };

			return null;
		}
	},
	actions: {
		destroy({ commit }) {
			commit('destroy');
		},
		status({ commit }, status) {
			commit('setStatus', status);
		},
		signup({ dispatch }, data) {
			return UsersRepository.signup(data).then(() => {
				return dispatch('login', data);
			});
		},
		login({ commit }, credentials) {
			return TokensRepository.store(credentials).then(response => {
				commit('setToken', response.data);
			});
		},
		exchange({ commit }, data) {
			return TokensRepository.exchange(data.provider, data.token).then(response => {
				commit('setToken', response.data);
			});
		},
		otp({ commit }, data) {
			return TokensRepository.otpExchange(data).then(response => {
				commit('setToken', response.data);
			});
		},
		me({ commit }) {
			return MeRepository.me().then(response => {
				commit('setUser', response.data);
				commit('setStatus', 'authenticated');
			});
		},
		logout({ dispatch, commit }) {
			commit('logout');
			dispatch('store/destroy', null, { root: true });
			dispatch('cart/destroy', null, { root: true });

			router.push({name: 'login'});
		},
		// Cuando el user actualiza sus datos ejecutamos esto ...
		setUser({ commit }, data) {
			commit('setUser', data);
		},
		phoneVerified({ commit }) {
			commit('phoneVerified', true);
		},
		updatePhone({ commit }, value) {
			commit('setPhone', value);
			commit('phoneVerified', false);
		}
	},
	mutations: {
		logout(state) {
			Object.assign(state, DEFAULT);
		},
		setUser(state, aUserProfile) {
			state.user = aUserProfile;
		},
		setToken(state, aToken) {
			state.token = aToken;
		},
		loadToken(state, aToken) {
			state.token = aToken;
		},
		loadUser(state, anUser) {
			state.user = anUser;
		},
		setStatus(state, status) {
			state.status = status;
		},
		phoneVerified(state, verified) {
			state.user.phone_verified = verified;
		},
		setPhone(state, value) {
			state.user.phone = value;
		}
	}
};

export default AuthModule;
