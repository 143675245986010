<template>
	<div class="card">
		<div class="card-header border-bottom">
			<cart-checkout-type />
		</div>
		<div class="card-body pt-0">
			<div class="list-group list-group-flush">
				<div class="d-flex list-group-item" v-if="items.length == 0">
					<div class="flex-fill">No hay productos.</div>
				</div>
				<cart-detail-item v-for="(item, index) in items" v-bind:key="item.id" :item="item" :index="index" :canDelete="true" class="list-group-item" />
				<div class="list-group-item totals-area">
					<div class="d-flex justify-content-between mb-2" v-if="subtotal != total">
						<h5 class="h5">Subtotal</h5>
						<h5 class="h5 text-nowrap">{{ vueNumberFormat(subtotal, $personalizer.currency.value) }}</h5>
					</div>
					<div class="d-flex justify-content-between mb-2" v-if="discount > 0">
						<h5 class="h5 text-danger">Descuento general</h5>
						<h5 class="h5 text-danger text-nowrap">{{ vueNumberFormat(discount, $personalizer.currency.value) }}</h5>
					</div>
					<div class="d-flex justify-content-between mb-2" v-if="type.option == 'DELIVERY'">
						<h5 class="h5">
							Envío
							<span class="text-xs text-danger d-block mt-2" v-if="subtotal < shippingCost.order_minimum"><i class="bi bi-exclamation-circle me-1"></i>Pedido mínimo: {{ vueNumberFormat(shippingCost.order_minimum, $personalizer.currency.value) }}</span>
							<span class="text-xs text-muted d-block mt-2" v-if="shippingCost?.raining"><i class="bi bi-cloud-drizzle"></i> El pedido puede sufrir demoras debido a las condiciones climáticas.</span>
							<span class="text-xs text-muted d-block mt-2" v-if="shippingCost?.high_wait"><i class="bi bi-hourglass-split"></i> El costo puede variar debido a las altas esperas en la zona del cliente.</span>
						</h5>
						<h5 class="h5 text-nowrap">{{ vueNumberFormat(deliveryAmount, $personalizer.currency.value) }}</h5>
					</div>
					<div class="d-flex justify-content-between">
						<h4 class="h4">Total</h4>
						<h4 class="h4 text-nowrap">{{ vueNumberFormat(total, $personalizer.currency.value) }}</h4>
					</div>
				</div>
				<div class="list-group-item pb-0" v-if="showOptions && canDiscount">
					<button guide="cart-detail-checkout-button-discount" class="btn btn-neutral w-100" :disabled="submitting" @click="modalOrderTotalShow">Aplicar descuento</button>
				</div>
				<div class="list-group-item pb-0" v-if="showOptions && canDiscount">
					<button guide="cart-detail-checkout-button-promocode" class="btn btn-neutral w-100" :disabled="submitting" @click="modalOrderPromocodeShow" v-if="!hasPromocode">Usar código promocional</button>
					<button class="btn btn-neutral w-100" :disabled="submitting" @click="setPromocode(null)" v-if="hasPromocode">Quitar código promocional</button>
				</div>
			</div>
		</div>
	</div>

	<common-order-promocode @setPromocode="setPromocode" />
	<common-order-total :subtotal="subtotal" @setDiscount="setDiscount" />
</template>

<script>
	import store from '@/store';
	import composableCart from '@/composables/cart';
	import { Offcanvas } from 'bootstrap';

	export default {
		props: {
			submitting: Boolean,
			showOptions: Boolean
		},
		setup(props) {
			const { type, items, subtotal, discount, deliveryAmount, total, canDiscount, hasPromocode, shippingCost } = composableCart();

			const modalOrderPromocodeShow = () => {
				if(props.submitting.value) return;
				Offcanvas.getInstance(document.getElementById('common-order-promocode')).show();
			}
			const setPromocode = (promocode) => store.dispatch('cart/setPromocode', promocode);

			const modalOrderTotalShow = () => {
				if(props.submitting.value) return;
				Offcanvas.getInstance(document.getElementById('common-order-total')).show();
			}
			const setDiscount = (discount) => store.dispatch('cart/setDiscount', discount);

			return { type, items, subtotal, discount, deliveryAmount, total, canDiscount, hasPromocode, shippingCost, modalOrderPromocodeShow, setPromocode, modalOrderTotalShow, setDiscount };
		}
	}
</script>

<style scoped>
	.position-sticky {
		top: 93px !important;
	}

	@media (min-width: 768px) {
		.position-sticky {
			top: 93px !important;
		}
	}
</style>