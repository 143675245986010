<template>
	<teleport to="body">
		<div class="modal" id="tickets-modal-view" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md modal-fullscreen-sm-down modal-dialog-scrollable">
				<div class="modal-content shadow-3" v-if="ticket">
					<div class="modal-header">
						<div>
							<h4 class="modal-title">
								Ticket Nro: {{ ticket.id }}
								<span class="badge rounded-pill bg-opacity-30 border border-danger text-danger text-sm ms-2" v-if="ticket.status == 'closed'">Finalizado</span>
							</h4>
							<small class="d-block">Pedido <b>Nro. {{ order.number }}</b></small>
						</div>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body flex-fill px-3 py-5 p-lg-7 overflow-y-lg-auto messages-list">
						<div class="vstack gap-5">
							<div class="hstack gap-7 w-100 justify-content-around">
								<div class="h-rem-px flex-fill bg-dark bg-opacity-10"></div>
								<div class="flex-auto text-xs text-muted fw-semibold">{{ getCreatedAtFormatted }}</div>
								<div class="h-rem-px flex-fill bg-dark bg-opacity-10"></div>
							</div>
							<tickets-block v-for="block in messageBlocks" v-bind:key="`block-${block.id}`" :ticket="ticket" :block="block" />
						</div>
						<div id="messages-bottom"></div>
					</div>
					<div class="modal-footer d-block bg-light">
						<div class="d-flex align-items-center bg-light border rounded-2">
							<div class="flex-fill d-flex align-items-center">
								<div class="w-100">
									<form class="card-comment-box hstack gap-3 align-items-center" @submit.prevent="submit">
										<input type="text" id="message" v-model="message" class="form-control py-4 ps-5" placeholder="Mensaje" :disabled="ticket.status == 'closed'" v-focus>
										<button type="submit" class="btn btn-link text-lg text-muted" :disabled="ticket.status == 'closed'"><i class="bi bi-send"></i></button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, watch, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';
	import VueScrollTo from 'vue-scrollto';
	import TicketRepository from '@/repositories/TicketRepository';
	import composableStore from '@/composables/store';
	import composableTicket from '@/composables/ticket';

	export default {
		emits: ['select'],
		props: {
			id: Number
		},
		setup(props, { emit }) {
			const { current_store } = composableStore();
			const { reload, order, getCreatedAtFormatted } = composableTicket();
			const modalView = ref(null);
			const message = ref(null);
			const ticket = computed(() => _.find(store.getters['tickets/list'], { id: props.id }));
			const messageSync = ref([]);
			const messageList = computed(() => {
				return _.concat((ticket.value?.messages ?? []), messageSync.value);
			});
			const messageBlocks = computed(() => {
				var list = [],
					last = null;

				_.forEach(messageList.value, (message) => {
					if(!last || last != message.from) list.push({ id: message.id, from: message.from, messages: [] });
					list[(list.length - 1)].messages.push(message);
					last = message.from;
				});
				
				return list;
			});

			onMounted(() => {
				modalView.value = new Modal(document.getElementById('tickets-modal-view'));

				document.getElementById('tickets-modal-view').addEventListener('hidden.bs.modal', () => {
					emit('select', null);
				});
			});

			watch(() => ticket.value, (newValue) => {
				if(newValue) {
					reload(newValue);

					_.forEach(newValue.messages, (message) => {
						var index = _.findIndex(messageSync.value, { hash: message.hash });
						if(index >= 0) messageSync.value.splice(index, 1);
					});

					TicketRepository.read(current_store.value.id, ticket.value.id);
				}
			}, { deep: true });

			watch(() => messageBlocks.value, (newValue) => {
				if(newValue.length > 0) setTimeout(() => VueScrollTo.scrollTo('#messages-bottom', 0, { container: '.messages-list' }), 100);
			}, { deep: true });

			watch(() => props.id, (newValue) => {
				if(newValue) modalView.value.show();
			});

			const submit = () => {
				if(!message.value) return false;

				var data = {
					synchronizing: true,
					syncError: false,
					id: null,
					from: 'store',
					read_by: [],
					hash: Date.now().toString(36) + Math.random().toString(36).substr(2),
					message: message.value
				};

				messageSync.value.push(data);
				syncMessages();

				message.value = null;
			}

			const synchronizing = ref(false);
			const syncMessages = async (force = false) => {
				if(synchronizing.value && !force) return;
				var index = _.findIndex(messageSync.value, { synchronizing: true });

				if(index >= 0) {
					synchronizing.value = true;
					messageSync.value[index].synchronizing = false;
					await TicketRepository.message(current_store.value.id, ticket.value.id, messageSync.value[index]).finally(() => {
						syncMessages(true);
					}).catch(() => {
						messageSync.value[index].syncError = true;
					});
				}else synchronizing.value = false;
			}

			return { message, ticket, messageBlocks, order, getCreatedAtFormatted, submit };
		}
	}
</script>