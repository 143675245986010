export default {
	namespaced: true,
	state: {
		back: null
	},
	getters: {
		back(state) {
			if(state.back) return state.back;
			else return null;
		}
	},
	actions: {
		setBack({ commit }, data) {
			commit('setBack', data);
		}
	},
	mutations: {
		setBack(state, data) {
			state.back = data;
		}
	}
}