import { useToast } from 'vue-toastification';
import _ from 'lodash';
import OrdersRepository from '@/repositories/OrdersRepository';

const toast = useToast();

const DEFAULT = {
	store: null,
	list: []
};

export default {
	namespaced: true,
	state: DEFAULT,
	getters: {
		isDefined(state) {
			return !!state.store;
		},
		isEmpty(state) {
			return (state.list.length === 0);
		},
		store(state) {
			return state.store;
		},
		list(state) {
			return state.list;
		}
	},
	actions: {
		destroy({ commit }) {
			commit('destroy');
		},
		setStore({ commit }, store) {
			commit('setStore', store);
		},
		setList({ commit }, list) {
			commit('setList', list);
		},
		sync({ dispatch, state }, order) {
			var list = _.clone(state.list);
			var hasOrder = false;

			_.forEach(list, (o, key) => {
				if(o.id == order.id) {
					hasOrder = true;
					list[key] = order;
				}
			});

			if(!hasOrder) list.push(order);

			dispatch('setList', list);
		},
		updateStatus({ dispatch, state, rootGetters }, data) {
			var current_store = rootGetters['store/info'];
			var oldStatus = null;
			var list = _.clone(state.list);

			_.forEach(list, (o, key) => {
				if(o.id == data.id) {
					oldStatus = list[key].status;
					list[key].status = data.newStatus;
					list[key].synchronizing = true;

					OrdersRepository.update(current_store.id, data.id, { status: data.newStatus }).catch((e) => {
						_.forEach(e.response.data.errors, (value) => toast.error(value));

						var list = _.clone(state.list);
						_.forEach(list, (o, key) => {
							if(o.id == data.id) {
								list[key].status = oldStatus;
								list[key].synchronizing = false;
							}
						});

						dispatch('setList', list);
					});
				}
			});

			dispatch('setList', list);
		},
	},
	mutations: {
		destroy(state) {
			Object.assign(state, DEFAULT);
		},
		setStore(state, store) {
			state.store = store;
		},
		setList(state, list) {
			state.list = list;
		}
	}
}