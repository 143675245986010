import { ref, computed, onMounted } from 'vue';
import store from '@/store';
import router from '@/router';
import { Modal } from 'bootstrap';
import _ from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import OrdersRepository from '@/repositories/OrdersRepository';
import composableRedirect from '@/composables/redirect';
import composableStore from '@/composables/store';
import composableForm from '@/composables/form';

export default function() {
	const { redirectBack } = composableRedirect();
	const { current_store, isPlanPremium, codeDefault, status, gateways } = composableStore();
	const { catchError } = composableForm();

	const billing = computed(() => _.find(gateways.value, { type: 'billing' }));

	const hasCart = computed(() => store.getters['cart/isDefined']);
	const type = computed(() => store.getters['cart/type']);
	const when = computed(() => store.getters['cart/when']);
	const payments = computed(() => store.getters['cart/payments']);
	const catalogType = computed(() => store.getters['cart/catalogType']);
	const code = computed(() => store.getters['cart/code']);
	const shippingCost = computed(() => store.getters['cart/shippingCost']);
	const address = computed(() => store.getters['cart/address']);
	const promocode = computed(() => store.getters['cart/promocode']);
	const items = computed(() => store.getters['cart/items']);
	const subtotal = computed(() => store.getters['cart/subtotal']);
	const discount = computed(() => store.getters['cart/discount']);
	const deliveryAmount = computed(() => store.getters['cart/deliveryAmount']);
	const total = computed(() => store.getters['cart/total']);
	const quantity = computed(() => _.sumBy(items.value, function(row) { return row.quantity; }));

	const seller_id = computed(() => store.getters['cart/seller_id']);
	const description = computed(() => store.getters['cart/description']);
	const phone = computed(() => store.getters['cart/phone']);
	const comments = computed(() => store.getters['cart/comments']);

	const canDiscount = computed(() => (!code.value?.group));
	const needPayment = computed(() => (type.value?.option == 'DELIVERY'));
	const canPayment = computed(() => !(type.value?.option == 'LOCAL' && code.value?.group));
	const canBilling = computed(() => (!!billing.value && !code.value?.group));
	const hasPromocode = computed(() => store.getters['cart/hasPromocode']);
	const isEmpty = computed(() => store.getters['cart/isEmpty']);
	const canFinish = computed(() => (!!type.value && !!when.value && !isEmpty.value && (!needPayment.value || payments.value.length > 0)));

	const validateItem = (item, selection, withErrors) => {
		var valid = true,
			requiredGroups = [],
			first_error_group_id = null;

		//Obtiene todos los grupos obligatorios.
		requiredGroups = _.filter(item.groups, (group) => { return group.constraints.min > 0 });
		requiredGroups = _.map(requiredGroups, (group) => { return group.id; });

		//Checkea que haya selecciones para todos los grupos obligatorios
		_.forEach(requiredGroups, (group_id) => {
			let exists = _.find(selection, (selected) => { return selected.group_id == group_id; });

			if(!exists) {
				valid = false;
				if(!first_error_group_id) first_error_group_id = group_id;
			}
		});

		if(!valid) {
			if(withErrors) document.querySelector(`#group-${first_error_group_id}`).scrollIntoView({ behavior: 'smooth' });
			return valid;
		}

		//Ya tiene todos los grupos seleccionados. Ahora verifico que sus selecciones sean válidas.
		_.forEach(selection, (selected) => {
			if(!selected.satisfy) {
				valid = false;
				if(!first_error_group_id) first_error_group_id = selected.group_id;
			}
		});

		if(!valid && withErrors) document.querySelector(`#group-${first_error_group_id}`).scrollIntoView({ behavior: 'smooth' });

		return valid;
	}

	const modalNew = ref(null);
	onMounted(() => modalNew.value = new Modal(document.getElementById('cart-modal-new')));

	const modalShow = () => {
		var modal = Modal.getInstance(document.getElementById('cart-modal-new'));
		modal.show();
	}

	const selectCodeDefault = () => {
		selectCode(codeDefault.value);
	}

	const selectType = (data) => {
		store.dispatch('cart/setType', data);
	}

	const selectCode = (code) => {
		store.dispatch('cart/setStore', current_store.value.id);
		store.dispatch('cart/setItems', []);
		store.dispatch('cart/setPayments', []);
		store.dispatch('cart/setCatalogType', code.type);
		store.dispatch('cart/setAddress', null);
		store.dispatch('cart/setCode', code);

		selectType({ option: 'LOCAL' });
		selectWhenDefault();
		toCatalog();
	}

	const selectTakeaway = () => {
		store.dispatch('cart/setStore', current_store.value.id);
		store.dispatch('cart/setItems', []);
		store.dispatch('cart/setPayments', []);
		store.dispatch('cart/setCatalogType', 'ONLINE');
		store.dispatch('cart/setAddress', null);
		store.dispatch('cart/setCode', null);

		selectType({ option: 'TAKEAWAY' });
		selectWhenDefault();
		toCatalog();
	}

	const redirectDelivery = () => {
		router.push({ name: 'store.cart.address', params: { store_id: current_store.value.id } });
	}

	const setShippingCost = (data) => {
		store.dispatch('cart/setShippingCost', data);
	}

	const selectDelivery = (data) => {
		store.dispatch('cart/setStore', current_store.value.id);
		store.dispatch('cart/setItems', []);
		store.dispatch('cart/setPayments', []);
		store.dispatch('cart/setCatalogType', 'ONLINE');
		store.dispatch('cart/setAddress', data);
		store.dispatch('cart/setCode', null);

		selectType({ option: 'DELIVERY' });
		selectWhenDefault();
		toCatalog();
	}

	const redirectCamera = () => {
		router.push({ name: 'store.camera.index', params: { store_id: current_store.value.id } });
	}

	const redirectCode = () => {
		router.push({ name: 'store.codes.index', params: { store_id: current_store.value.id } });
	}

	const redirectMap = () => {
		router.push({ name: 'store.maps.index', params: { store_id: current_store.value.id } });
	}

	const toCatalog = () => {
		router.push({ name: 'store.cart.catalog', params: { store_id: current_store.value.id } });
	}

	const toCheckout = () => {
		router.push({ name: 'store.cart.checkout', params: { store_id: current_store.value.id } });
	}

	const selectWhen = (option) => {
		store.dispatch('cart/setWhen', option);
	}

	const selectWhenDefault = () => {
		if(status.value?.type?.inmediate?.services[type.value.option]) selectWhen({ option: 'immediate' });
	}

	const setPayments = (payments) => {
		store.dispatch('cart/setPayments', payments);
	}

	const destroy = () => {
		store.dispatch('cart/destroy').then(redirectBack);
	}

	const submitting = computed(() => store.getters['cart/submitting']);

	const finish = () => {
		store.dispatch('cart/setSubmitting', true);

		OrdersRepository.store(current_store.value.id, getPayload()).then((response) => {
			var toBilling = canBilling.value;

			store.dispatch('cart/destroy').then(() => {
				if(response.data.order.status == 'PAYING') router.push({ name: 'paying', params: { store_id: current_store.value.id, type: 'order', id: response.data.order.id } });
				else if(isPlanPremium.value && toBilling) router.push({ name: 'store.order.billing.invoice', params: { store_id: current_store.value.id, id: response.data.order.id } });
				else redirectBack();
			});
		}).catch((e) => {
			catchError(e, true);
			store.dispatch('cart/setSubmitting', false);
		});
	}

	const getPayload = () => {
		var payload = {
			type: type.value,
			when: when.value,
			payments: payments.value,
			items: items.value,
			subtotal: subtotal.value,
			discount: discount.value,
			delivery_amount: deliveryAmount.value,
			total: total.value,
			seller_id: seller_id.value,
			description: description.value,
			phone: (phone.value) ? parsePhoneNumber(phone.value, current_store.value.country).number : null,
			comments: comments.value
		}

		if(type.value.option == 'DELIVERY') payload.address = address.value;
		if(code.value) payload.code = code.value;
		if(hasPromocode.value) payload.promocode = promocode.value;

		return payload;
	}

	return { hasCart, type, when, payments, catalogType, code, shippingCost, address, promocode, items, subtotal, discount, deliveryAmount, total, quantity, seller_id, description, phone, comments, canDiscount, needPayment, canPayment, canBilling, hasPromocode, isEmpty, canFinish, validateItem, modalShow, selectType, selectCodeDefault, selectCode, selectTakeaway, redirectDelivery, setShippingCost, selectDelivery, redirectCamera, redirectCode, redirectMap, toCatalog, toCheckout, selectWhen, setPayments, destroy, submitting, finish };
}