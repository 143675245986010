<template>
	<map-header :maps="maps" :orderGroups="orderGroups" :current_map="current_map" @selectMap="selectMap" />
	<!-- Main -->
	<main class="py-6 flex-grow-1 bg-maps">
		<!-- Container -->
		<div class="container-fluid vstack gap-3 min-h-full">
			<div class="row" v-if="current_map">
				<div guide="map-index-select" class="col-12">
					<map-view :map="current_map" :orderGroups="orderGroups" @select="select" />
				</div>
			</div>
			<div class="row" v-if="!current_map">
				<div class="col-12">
					<common-message-error text="No se encontraron mapas." />
				</div>
			</div>
		</div>
	</main>

	<ordergroup-modal-view :id="order_group_id" @select="selectOrderGroup" @selectChange="selectChange" />
	<ordergroup-modal-change :orderGroup="orderGroupChange" @selectChange="selectChange" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';
	import _ from 'lodash';

	export default {
		setup() {
			const { current_store, isAdmin, maps } = composableStore();
			const { selectCode } = composableCart();
			const current_map = ref(null);
			const orderGroups = computed(() => store.getters['orderGroups/list']);
			const order_group_id = ref(null);

			const selectMap = (map) => current_map.value = map;
			const select = (code) => {
				if(code.group) {
					var info = _.find(orderGroups.value, (data) => { return (data.code.id == code.id && _.indexOf(['OPENED', 'PAYING'], data.status) !== -1); });

					if(!info) router.push({ name: 'store.orderGroup.create', params: { store_id: current_store.value.id }, query: { code_id: code.id } });
					else if(isAdmin.value && info.status == 'PAYING') router.push({ name: 'paying', params: { store_id: current_store.value.id, type: 'orderGroup', id: info.id } });
					else if(isAdmin.value) selectOrderGroup(info);
					else selectCode(code);
				}else selectCode(code);
			}

			const selectOrderGroup = (data) => order_group_id.value = data?.id ?? null;

			const orderGroupChange = ref(null);
			const selectChange = (data) => orderGroupChange.value = data;

			onMounted(() => current_map.value = _.head(maps.value));

			return { maps, orderGroups, order_group_id, select, selectMap, selectOrderGroup, orderGroupChange, selectChange, current_map };
		}
	}
</script>