<template>
	<div class="list-group-item px-0 py-3 d-flex fw-semibold" :class="{'text-warning': item.order.status != 'COMPLETE'}">
		<div class="hstack gap-2 pe-2" v-if="item.order.status != 'COMPLETE'">
			<button type="button" class="btn btn-xs btn-link p-0 text-danger" @click="remove"><i class="bi bi-trash"></i></button>
			<i class="bi bi-clock-history"></i>
		</div>
		<div class="pe-2">{{ item.quantity }}x</div>
		<div class="flex-fill">
			<div class="d-flex">
				<div class="flex-fill">{{ item.name }}</div>
				<div class="flex-fill text-nowrap text-end ps-2">{{ vueNumberFormat(total(), $personalizer.currency.value) }}</div>
			</div>
			<div class="d-flex">
				<div class="flex-fill">
					<p class="text-muted" v-for="additional in item.additionals" v-bind:key="additional.id">+ {{ `${additional.quantity} u. ${additional.name}${(item.quantity > 1) ? ' c/u' : ''}` }}</p>
					<p class="text-warning">{{ item.comments }}</p>
				</div>
				<div class="flex-fill text-nowrap text-end" v-if="item.discount > 0">
					<small class="text-danger">{{ vueNumberFormat(discountPercentage(), { prefix: '', suffix: '% OFF' }) }}</small>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';

	export default {
		emits: ['delete'],
		props: {
			item: Object,
			index: Number
		},
		setup(props, { emit }) {
			const additionalsAsString = () => {
				var all = [];
				_.forEach(props.item.additionals, (additional) => { all.push(`${additional.name} x ${additional.quantity.toString()}${(props.item.quantity > 1) ? ' c/u' : ''}`); });
				return all.join(', ');
			}

			const subtotal = () => {
				var subtotal = props.item.price;
				_.forEach(props.item.additionals, (additional) => { subtotal+= additional.price * additional.quantity; });
				return subtotal * props.item.quantity;
			}

			const total = () => {
				return subtotal() - props.item.discount;
			}

			const discountPercentage = () => {
				return ('discount' in props.item) ? Math.round((props.item.discount * 100 / subtotal()) * 100) / 100 : 0;
			}

			const remove = () => emit('delete', props.item);

			return { additionalsAsString, total, discountPercentage, remove };
		}
	}
</script>

<style scoped="">
</style>