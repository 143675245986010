import Repository from './Repository';

const resource = '/store';

export default {
	store(store_id, payload) {
		return Repository.post(`${resource}/${store_id}/tickets`, payload);
	},
	read(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/tickets/${id}/read`, payload);
	},
	message(store_id, id, payload) {
		return Repository.post(`${resource}/${store_id}/tickets/${id}/message`, payload);
	}
}