import { ref, computed } from 'vue';
import moment from 'moment';
import _ from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
import composableStore from '@/composables/store';

export default function(data = null) {
	const { gateways } = composableStore();
	const order = ref(data);

	const billingGateway = computed(() => _.find(gateways.value, { type: 'billing' }));
	const shippingGateway = computed(() => _.find(gateways.value, { type: 'shipping' }));

	const reload = (data) => order.value = data;

	const TYPES = {
		'DELIVERY': {
			title: 'Delivery',
			border: 'border-danger',
			badge: 'border-danger bg-danger'
		},
		'TAKEAWAY': {
			title: 'Take Away',
			border: 'border-dark',
			badge: 'border-dark bg-dark'
		},
		'LOCAL': {
			title: 'Local',
			border: 'border-warning',
			badge: 'border-warning bg-warning'
		}
	}

	const SHIPPING_STATUSES = {
		'CANCELLEDBYUSER': {
			title: 'Cancelado por el cliente',
			progress: 0,
			class: 'danger'
		},
		'CANCELLEDBYSTORE': {
			title: 'Cancelado por el comercio',
			progress: 0,
			class: 'danger'
		},
		'CANCELLEDBYPROVIDER': {
			title: 'Cancelado por el proveedor de envíos',
			progress: 0,
			class: 'danger'
		},
		'PENDING': {
			title: 'Pendiente',
			progress: 5,
			class: 'danger'
		},
		'ARRIVING': {
			title: 'En camino a la tienda',
			progress: 20,
			class: 'dark'
		},
		'INSTORE': {
			title: 'Esperando en la tienda',
			progress: 40,
			class: 'warning'
		},
		'SENDING': {
			title: 'Enviando',
			progress: 60,
			class: 'warning'
		},
		'DELIVERED': {
			title: 'Entregado',
			progress: 100,
			class: 'success'
		}
	}

	const STATUSES = {
		'PAYING': {
			title: 'Pagando',
			class: 'warning',
			ready_by: false,
			refresh: 30,
			vibrate: false,
			message: false,
			progress: {
				percentage: 20,
				class: 'bg-warning'
			}
		},
		'PENDING': {
			title: 'Pendiente',
			class: 'secondary',
			ready_by: false,
			refresh: 30,
			vibrate: false,
			message: false,
			progress: {
				percentage: 20,
				class: 'bg-secondary'
			}
		},
		'ACCEPTED': {
			title: 'Aceptado',
			class: 'warning',
			ready_by: true,
			refresh: 30,
			vibrate: [500, 250, 500, 250],
			message: '¡Tu pedido fue aceptado!',
			progress: {
				percentage: 50,
				class: 'bg-success'
			}
		},
		'DELIVER': {
			title: 'Por entregar',
			class: 'success',
			ready_by: true,
			refresh: 60,
			vibrate: [500, 250, 500, 250, 500, 250],
			message: '¡Tu pedido está listo para ser entregado!',
			progress: {
				percentage: 80,
				class: 'bg-info'
			}
		},
		'COMPLETE': {
			title: 'Entregado',
			class: 'primary',
			ready_by: false,
			refresh: false,
			vibrate: [500, 250, 500, 250, 500, 250],
			message: '¡Tu pedido fue entregado!',
			progress: {
				percentage: 100,
				class: 'bg-primary'
			}
		},
		'REJECTED': {
			title: 'Rechazado',
			class: 'danger',
			ready_by: false,
			refresh: false,
			vibrate: [500, 250, 500, 250, 500, 250],
			message: 'Lo sentimos, tu pedido fue rechazado',
			progress: {
				percentage: 100,
				class: 'bg-danger'
			}
		},
	}

	const PAYMENT_METHODS = {
		'agree': 'A acordar',
		'cash': 'Efectivo',
		'card': 'Tarjeta',
		'card-credit': 'Tarjeta de crédito',
		'card-debit': 'Tarjeta de débito',
		'mercadopago-online': 'Tarjeta',
		'mercadopago-qr': 'MercadoPago QR',
		'cuenta-dni': 'Cuenta DNI',
		'bank-transfer': 'Transferencia bancaria',
		'bancard': 'Bancard'
	}

	const REJECTED_REASONS = {
		closed: 'Local cerrado',
		not_deliver: 'El local no entrega en la direccion',
		missing_data: 'Faltan datos de la direccion',
		missing_products: 'Faltan productos para el pedido',
		much_demand: 'Hay mucha demanda en el local',
		no_cadet_available: 'No hay cadete disponible',
		false_order: 'Pedido falso',
		wrong_order: 'Pedido equivocado',
		outdated_profile: 'Perfil del local desactualizado',
		no_payments: 'No se recibió el pago correspondiente',
		by_user: 'Cancelado por el cliente',
		by_store: 'Cancelado por la tienda',
	}

	const title = computed(() => {
		if(order.value.user) return order.value.user?.fullname;
		else if(order.value.description) return order.value.description;
		else if(order.value.phone) return phoneFormatted.value;
		else return 'Sin datos';
	});

	const phoneFormatted = computed(() => {
		const phoneNumber = parsePhoneNumber(order.value.phone);
		return phoneNumber.formatInternational();
	});

	const subtotal = computed(() => order.value.subtotal);
	const discount = computed(() => order.value.discount);
	const delivery_amount = computed(() => order.value.delivery_amount);
	const total = computed(() => order.value.total);

	const canDiscount = computed(() => (!order.value.code?.group));
	const canPayment = computed(() => !(order.value.type == 'LOCAL' && order.value.code?.group));
	const canBilling = computed(() => (!!billingGateway.value && !order.value.code?.group));

	const setDiscount = (discount) => {
		order.value.discount = discount;
		order.value.total = order.value.subtotal - order.value.discount + order.value.delivery_amount;
	}

	const setPromocode = (promocode) => {
		order.value.promocode = promocode;

		if(order.value.promocode) {
			var discount = 0;

			switch(order.value.promocode.type) {
				case 'PERCENTAGE':
					discount = Math.round((order.value.subtotal * (order.value.promocode.value / 100)) * 100) / 100;
					break;
				case 'AMOUNT':
					discount = (order.value.subtotal < order.value.promocode.value) ? order.value.subtotal : order.value.promocode.value;
					break;
			}

			setDiscount(discount);
		}

		order.value.total = order.value.subtotal - order.value.discount + order.value.delivery_amount;
	}

	const paymentsInfo = computed(() => {
		var info = null;

		switch(order.value.payments.length ?? 0) {
			case 0:
				info = 'Sin pagos';
				break;
			case 1:
				info = (_.has(PAYMENT_METHODS, order.value.payments[0].method)) ? PAYMENT_METHODS[order.value.payments[0].method] : '-';
				break;
			default:
				info = 'Pagos múltiples';
				break;
		}

		return info;
	});

	const hasShipping = computed(() => (order.value.shipping.length > 0));
	const canOpenTicket = computed(() => (hasShipping.value && (shippingGateway.value?.config?.support ?? false)));

	const shipping = computed(() => {
		return (order.value.shipping.length > 0)
			? order.value.shipping[0]
			: null;
	});

	const shippingInfo = computed(() => {
		return (shipping.value && _.has(SHIPPING_STATUSES, shipping.value.status))
			? SHIPPING_STATUSES[shipping.value.status]
			: null;
	});

	const typeInfo = computed(() => {
		return (_.has(TYPES, order.value.type))
			? TYPES[order.value.type]
			: { title: 'Sin definir', border: '', badge: '' };
	});

	const typeTitle = computed(() => {
		return typeInfo.value.title + ((order.value.code && order.value.code.description) ? ` (${order.value.code.description})` : '');
	});

	const getTypeBorder = computed(() => {
		return (_.has(TYPES, order.value.type))
			? TYPES[order.value.type].border
			: '-';
	});

	const getTypeBadge = computed(() => {
		return (_.has(TYPES, order.value.type))
			? TYPES[order.value.type].badge
			: '-';
	});

	const getStatusTitle = computed(() => {
		return (_.has(STATUSES, order.value.status))
			? STATUSES[order.value.status].title
			: '-';
	});

	const getStatusClass = computed(() => {
		return (_.has(STATUSES, order.value.status))
			? STATUSES[order.value.status].class
			: '';
	});

	const getStatusReadyBy = computed(() => {
		return (_.has(STATUSES, order.value.status))
			? STATUSES[order.value.status].ready_by
			: false;
	});

	const getCreatedAtFormatted = computed(() => moment(order.value.created_at).format('D [de] MMMM, HH:mm[Hs]'));
	const getReadyByFormatted = computed(() => moment(order.value.ready_by).format('D [de] MMMM, HH:mm[Hs]'));

	const getDeliveredDateFormatted = computed(() => {
		var description = null;

		if(!order.value.ready_by) description = null;
		else if(order.value.type == 'DELIVERY') description = moment(order.value.ready_by).add(Number(process.env.VUE_APP_DELIVERY_FROM), 'minutes').format('D [de] MMMM, HH:mm[Hs]');
		else description = moment(order.value.ready_by).format('D [de] MMMM, HH:mm[Hs]');

		return description;
	});

	const getDeliveredTimeFormatted = computed(() => {
		var description = null,
			ready_by = null,
			ready_from = null,
			ready_to = null;

		if(!order.value.ready_by) {
			description = `A confirmar`;
		}else if(order.value.type == 'DELIVERY') {
			ready_from = moment(order.value.ready_by).add(Number(process.env.VUE_APP_DELIVERY_FROM), 'minutes').format('HH:mm');
			ready_to = moment(order.value.ready_by).add(Number(process.env.VUE_APP_DELIVERY_TO), 'minutes').format('HH:mm');
			description = `Entre las ${ready_from} hs y ${ready_to} hs`;
		}else{
			ready_by = moment(order.value.ready_by).format('HH:mm');
			description = `A partir de las ${ready_by} hs`;
		}

		return description;
	});

	const getDeliveryTo = computed(() => {
		var description = null;

		switch(order.value.type) {
			case 'DELIVERY':
				description = order.value.address;
				break;
			case 'LOCAL':
				description = (order.value.code && order.value.code.description) ? order.value.code.description : null;
				break;
		}

		return description;
	});

	const getPaymentMethod = computed(() => {
		var descriptions = [];
		_.forEach(order.value.payments, function(payment) {
			if(_.has(PAYMENT_METHODS, payment.method)) {
				var description = PAYMENT_METHODS[payment.method];

				switch(payment.status) {
					case 'PENDING': description = `<span class="text-warning">${description} (Pendiente)</span>`; break;
					case 'REJECTED': description = `<span class="text-danger">${description} (Rechazado)</span>`; break;
				}

				descriptions.push(description);
			}
		});

		return (descriptions.length > 0) ? descriptions.join('<br>') : 'Sin pagos';
	});

	return { PAYMENT_METHODS, REJECTED_REASONS, reload, title, phoneFormatted, subtotal, discount, delivery_amount, total, hasShipping, canOpenTicket, shipping, shippingInfo, canDiscount, canPayment, canBilling, setDiscount, setPromocode, paymentsInfo, typeInfo, typeTitle, getTypeBorder, getTypeBadge, getStatusTitle, getStatusClass, getStatusReadyBy, getCreatedAtFormatted, getReadyByFormatted, getDeliveredDateFormatted, getDeliveredTimeFormatted, getDeliveryTo, getPaymentMethod };
}