<template>
	<div class="d-flex justify-content-between align-items-center">
		<div>
			<a href="javascript:void(0)" class="text-dark fw-bold stretched-link" @click="select">Ticket N°: {{ ticket.id }}</a>
			<small class="d-block">Pedido <b>{{ (order) ? `número ${order.number}` : `sin identificar` }}</b></small>
		</div>
		<div class="text-end">
			<div class="text-xs text-muted">{{ getUpdatedAtForHummans }}</div>
			<span class="badge rounded-pill border border-warning bg-warning" v-if="newMessages > 0">{{ newMessages }}</span>
			<span class="badge rounded-pill border" :class="`border-${statusInfo.class} bg-${statusInfo.class}`" v-else>{{ statusInfo.title }}</span>
		</div>
	</div>
</template>

<script>
	import { watch } from 'vue';
	import composableTicket from '@/composables/ticket';

	export default {
		emits: ['select'],
		props: {
			ticket: Object
		},
		setup(props, { emit }) {
			const { reload, order, newMessages, statusInfo, getUpdatedAtForHummans } = composableTicket(props.ticket);

			watch(() => props.ticket, (newValue) => {
				if(newValue) reload(newValue);
			}, { deep: true });

			const select = () => emit('select', props.ticket);

			return { order, newMessages, statusInfo, getUpdatedAtForHummans, select };
		}
	}
</script>

<style scoped>
	.badge {
		--x-badge-padding-x: 0.4rem;
		--x-badge-padding-y: 0.05rem;
	}
</style>