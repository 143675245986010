<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4">
			<div class="row g-0 align-items-center">
				<div class="col">
					<h1 class="h4 ls-tight">Mapas</h1>
				</div>
				<div class="col-auto">
					<ul guide="map-header-select" class="horizontal-tags nav">
						<li class="nav-item" v-for="map in maps" v-bind:key="map.id">
							<a href="javascript:void(0);" class="btn btn-tag" :class="{'active': (map.id == current_map?.id), 'border-danger': waitings.includes(map.id), 'border-info': payings.includes(map.id), 'border-success': (!waitings.includes(map.id) && openeds.includes(map.id))}" @click="selectMap(map)">{{ map.name }}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['selectMap'],
		props: {
			maps: Array,
			current_map: Object,
			orderGroups: Array
		},
		setup(props, { emit }) {
			const selectMap = (map) => emit('selectMap', map);

			const orderGroupsFiltered = computed(() => _.filter(props.orderGroups, (data) => { return (_.indexOf(['OPENED', 'PAYING'], data.status) !== -1); }));

			const openeds = computed(() => {
				var list = [];
				_.forEach(props.maps, (map) => {
					_.forEach(map.draw.objects, (object) => {
						if(object?.code_id) {
							var orderGroup = _.find(orderGroupsFiltered.value, { code: { id: Number(object?.code_id) } });
							if(orderGroup && orderGroup?.status == 'OPENED') list.push(map.id);
						}
					});
				});

				return list;
			});

			const waitings = computed(() => {
				var list = [];
				_.forEach(props.maps, (map) => {
					_.forEach(map.draw.objects, (object) => {
						if(object?.code_id) {
							var orderGroup = _.find(orderGroupsFiltered.value, { code: { id: Number(object?.code_id) } });
							if(orderGroup && orderGroup?.waiting_count > 0) list.push(map.id);
						}
					});
				});

				return list;
			});

			const payings = computed(() => {
				var list = [];
				_.forEach(props.maps, (map) => {
					_.forEach(map.draw.objects, (object) => {
						if(object?.code_id) {
							var orderGroup = _.find(orderGroupsFiltered.value, { code: { id: Number(object?.code_id) } });
							if(orderGroup && orderGroup?.status == 'PAYING') list.push(map.id);
						}
					});
				});

				return list;
			});

			return { selectMap, openeds, waitings, payings, orderGroupsFiltered };
		}
	}
</script>