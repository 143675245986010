<template>
	<router-view></router-view>
</template>

<script>
	import { onBeforeMount } from 'vue';
	import store from '@/store';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { load } = composableStore();

			onBeforeMount(() => load(store.getters['cart/slug']));
		}
	}
</script>