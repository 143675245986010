<template>
	<div class="row">
		<div class="col-12 px-0">
			<div class="list-group list-group-flush">
				<div class="list-group-item d-flex align-items-center px-6 py-3" v-for="(name, index) in ((printpoint) ? printers : [])" v-bind:key="index">
					<div class="flex-fill">
						<div class="fw-semibold">{{ name }}</div>
						<small class="d-block"><i class="bi bi-printer pe-1"></i>Conectada a éste dispositivo</small>
					</div>
					<div class="text-end ps-4">
						<button type="button" class="btn btn-sm" :class="{ 'btn-neutral': (method != 'local' || value != name), 'btn-primary': (method == 'local' && value == name) }" @click="select('local', name)">{{ (method == 'local' && value == name) ? 'Seleccionado' : 'Seleccionar' }}</button>
					</div>
				</div>
				<div class="list-group-item d-flex align-items-center px-6 py-3" v-if="status == 'pending'">
					<div class="fw-semibold">Obteniendo impresoras...</div>
				</div>
				<div class="list-group-item px-6 py-3" v-if="!printpoint">
					<div class="fw-semibold">¿Hay impresoras conectadas a éste dispositivo?</div>
					<small class="d-block"><i class="bi bi-info-circle pe-1"></i>Para conectar con impresoras de éste dispositivo hacé <router-link :to="{ name: 'store.config.printpoint.form', params: { store_id: current_store.id } }">click aquí</router-link></small>
				</div>
				<div class="list-group-item d-flex align-items-center px-6 py-3" v-for="data in printpoints_filtered" v-bind:key="data.id">
					<div class="flex-fill">
						<div class="fw-semibold">{{ data.name }}</div>
						<small class="d-block"><i class="bi bi-router pe-1"></i>Punto de impresión remoto</small>
					</div>
					<div class="text-end ps-4">
						<button type="button" class="btn btn-sm" :class="{ 'btn-neutral': (method != 'remote' || value != String(data.id)), 'btn-primary': (method == 'remote' && value == String(data.id)) }" @click="select('remote', String(data.id))">{{ (method == 'remote' && value == String(data.id)) ? 'Seleccionado' : 'Seleccionar' }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';
	import composableStore from '@/composables/store';

	export default {
		emits: ['select'],
		props: {
			type: String,
			status: String,
			printers: Array,
			printpoints: Array,
			printpoint: Object,
			method: String,
			value: String
		},
		setup(props, { emit }) {
			const { current_store } = composableStore();

			const printpoints_filtered = computed(() => {
				return _.filter(props.printpoints, (o) => { return (_.indexOf(o.types, props.type) !== -1 && o.id != props.printpoint?.id); });
			});

			const select = (method, value) => {
				emit('select', method, value);
			}

			return { current_store, printpoints_filtered, select };
		}
	}
</script>