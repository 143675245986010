<template>
	<div class="position-relative">
		<!-- Avatar -->
		<div class="d-flex justify-content-center mb-4" v-if="current_store.logo">
			<div class="position-relative d-inline-block text-white">
				<div class="avatar avatar-xl border border-3 rounded-circle" :class="{ 'bg-primary border-primary': open, 'bg-danger border-danger': !open }">
					<img :src="current_store.logo_url" class="border border-3 rounded-circle border-white" @load="imageReady = true" v-show="imageReady" alt="Avatar">
					<Skeletor class="rounded-circle" v-show="!imageReady" />
				</div>
			</div>
		</div>
		<!-- Name + subtitle -->
		<div class="text-center">
			<router-link :to="{ name: 'store.home', params: { store_id: current_store.id } }" class="h5 stretched-link">{{ current_store.name }}</router-link>
		</div>
	</div>
	<component :is="viewMain" />
</template>

<script>
	import { ref, computed } from 'vue';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { current_store, status, isAdmin } = composableStore();

			const imageReady = ref(false);

			const open = computed(() => status.value?.open);

			const viewMain = computed(() => {
				if(isAdmin.value) return 'common-menu-status-edit';
				else return 'common-menu-status-info';
			});

			return { current_store, imageReady, open, viewMain };
		}
	}
</script>