<template>
	<cart-header-address :disabled="submitting || !form.geolocation.lat" @next="submit" />
	<!-- Main -->
	<main class="py-5 flex-grow-1">
		<!-- Container -->
		<div class="container-fluid vstack gap-5 min-h-full">
			<div class="row g-5">
				<div class="col-12 col-md-8 col-lg-9">
					<GMapAutocomplete
						guide="views-cart-address-autocomplete"
						placeholder="Busque la dirección incluyendo calle y número de puerta"
						@place_changed="setPlace"
						:options="{ type: ['geocode'], componentRestrictions: { country: current_store.country } }"
						class="form-control autocomplete-field"
						:class="{'is-invalid': validation.address_line_1.$error}"
						v-focus
					>
					</GMapAutocomplete>
					<span class="mt-1 invalid-feedback" v-for="error of validation.address_line_1.$errors" :key="error.$uid">{{ error.$message }}</span>
				</div>
				<div class="col-12 col-md-4 col-lg-3">
					<input guide="views-cart-address-line-2" type="text" class="form-control" :class="{'is-invalid': validation.address_line_2.$error}" placeholder="Dpto, casa (opcional)" v-model="form.address_line_2" maxlength="20" />
					<span class="mt-1 invalid-feedback" v-for="error of validation.address_line_2.$errors" :key="error.$uid">{{ error.$message }}</span>
				</div>
				<div class="col-12 mb-5 text-center" v-if="validationExternal.length > 0">
					<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
				</div>
			</div>
			<div class="row flex-grow-1">
				<div class="col-12">
					<GMapMap
						:center="center"
						:zoom="zoom"
						:options="{ disableDefaultUI: true }"
						map-type-id="roadmap"
						style="width: 100%; min-height: 350px;"
						class="h-100"
					>
						<GMapMarker
							:icon="{ url: require('@/assets/img/icons/marker.png'), scaledSize: { width: 46, height: 46 } }"
							:position="center"
							v-if="hasAddress"
						/>
					</GMapMap>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
	import { ref, reactive, computed, watch } from 'vue';
	import _ from 'lodash';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { maxLength } from '@/helpers/i18n/validators';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { current_store } = composableStore();
			const { setShippingCost, selectDelivery } = composableCart();
			const { submitting, validationExternal, catchError } = composableForm();
			const hasAddress = ref(false);
			const zoom = computed(() => {
				let data;

				if(hasAddress.value) data = 16;
				else switch(current_store.value.country) {
					case 'PY':
						data = 7;
						break;
					case 'AR':
					default:
						data = 6;
						break;
				}

				return data;
			});
			const center = computed(() => {
				let data;

				if(hasAddress.value) data = form.geolocation;
				else switch(current_store.value.country) {
					case 'PY':
						data = { lat: -23.25, lng: -58.47 };
						break;
					case 'AR':
					default:
						data = { lat: -35.2, lng: -65.2 };
						break;
				}

				return data;
			});

			const place = reactive({
				route: null,
				street_number: null
			});

			const form = reactive({
				address_line_1: null,
				address_line_2: null,
				geolocation: {
					lat: null,
					lng: null
				}
			});

			watch(() => form.address_line_1, (newValue) => document.querySelector('.autocomplete-field').value = newValue);

			const setPlace = (result) => {
				if(!result?.geometry) return;
				_.forEach(result.address_components, function(component) { _.forEach(component.types, function(type) { if(type in place) place[type] = component.short_name; }); });

				form.address_line_1 = result.name;
				form.geolocation.lat = result.geometry.location.lat();
				form.geolocation.lng = result.geometry.location.lng();

				hasAddress.value = true;
			}

			const validateAddress = () => {
				if(!place.route) return false;
				if(!place.street_number) return false;
				if(!form.address_line_1) return false;
				if(!form.geolocation.lat) return false;
				if(!form.geolocation.lng) return false;

				return true;
			}

			const validation = useVuelidate({
				address_line_1: {
					validateAddress: helpers.withMessage('Seleccione una dirección válida.', validateAddress)
				},
				address_line_2: {
					maxLength: maxLength(20)
				}
			}, form);

			const submit = async () => {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await StoreRepository.shippingCost(current_store.value.id, form)
					.then((data) => {
						setShippingCost(data.data);
						selectDelivery(form);
					})
					.catch((e) => {
						catchError(e, true);
						submitting.value = false;
					});
			}

			return { current_store, hasAddress, form, zoom, center, setPlace, validation, validationExternal, submitting, submit };
		}
	}
</script>

<style>
	.vue-map {
		border-radius: .375rem !important;
		border-width: 1px !important;
		border: 0 solid #e7eaf0;
	}

	.map-container {
		position: relative;
	}

	.map-container .map-marker {
		position: absolute;
		left: calc(50% - 20px);
		top: calc(50% - 40px);
		height: 40px;
		width: 40px;
		z-index: 1;
	}

	.pac-container {
		z-index: 10 !important;
		font-family: inherit;
		font-size: 1rem;
		color: #464E5F;
		border-width: 0px;
		box-shadow: none;
		padding: 0px 0px 10px 0px;
		margin: 5px 0px 0px 0px;
		background-color: #ffffff;
		border-radius: .3rem !important;
		border-width: 1px !important;
		border: 0 solid #e7eaf0;
	}

	.pac-container:before { 
		content: "Selecciona una opción...";
		display: block;
		font-family: inherit;
		font-size: 1rem;
		text-align: left;
		color: #FFA800;
		width: 100%;
		padding: 4px 10px;
		border-width: 0px 0px 1px 0px;
		border-style: solid;
		border-color: #e2e5ec;
	}

	.pac-item {
		border-width: 0px;
		cursor: pointer;
		margin: 0px;
		padding: 5px 10px;
	}

	.pac-item:not(:last-child) {
		border-width: 0px 0px 1px 0px;
		border-style: solid;
		border-color: #e2e5ec;
	}

	.pac-icon {
		display: none;
		padding: 0px 9px;
	}
</style>