<template>
	<header guide="orders-kanban-header" class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4">
			<div class="row g-0 align-items-center">
				<div class="col">
					<h1 class="h4 ls-tight">Tablero de pedidos</h1>
				</div>
				<div class="col hstack gap-2 justify-content-end">
					<button type="button" guide="orders-kanban-header-button-payouts" class="btn btn-sm btn-square btn-neutral position-relative" @click="modalPayoutsShow" v-if="isAdmin && payouts.length > 0">
						<i class="bi bi-envelope-paper"></i>
						<span class="badge bg-danger badge-count badge-sm border border-2 border-current rounded-circle position-absolute top-0 end-0 transform mt-n2 me-n2">{{ payouts.length }}</span>
					</button>
					<button type="button" guide="orders-kanban-header-button-tickets" class="btn btn-sm btn-square btn-neutral position-relative" @click="modalTicketsIndexShow" v-if="isAdmin && tickets.length > 0">
						<i class="bi bi-chat-right-text"></i>
						<span class="badge bg-warning badge-count badge-sm border border-2 border-current rounded-circle position-absolute top-0 end-0 transform mt-n2 me-n2" v-if="ticketsNewMessages > 0">{{ ticketsNewMessages }}</span>
					</button>
					<div guide="orders-kanban-header-button-filters" class="dropstart" :class="{'btn-group': (filters.type != null)}">
						<button type="button" class="btn btn-sm btn-square btn-danger" @click="$emit('emptyFilters')" v-if="filters.type != null"><i class="bi bi-trash"></i></button>
						<ul class="dropdown-menu" aria-labelledby="filterDropdown">
							<li>
								<h6 class="dropdown-header">Tipos de servicios</h6>
							</li>
							<li v-if="typeLocal">
								<a href="javascript:void(0);" class="dropdown-item" :class="{'active': (filters.type == 'LOCAL')}" @click="$emit('setFilters', 'type', 'LOCAL')"><i class="bi bi-shop me-2"></i>Local</a>
							</li>
							<li v-if="typeTakeaway">
								<a href="javascript:void(0);" class="dropdown-item" :class="{'active': (filters.type == 'TAKEAWAY')}" @click="$emit('setFilters', 'type', 'TAKEAWAY')"><i class="bi bi-handbag me-2"></i>Take away</a>
							</li>
							<li v-if="typeDelivery">
								<a href="javascript:void(0);" class="dropdown-item" :class="{'active': (filters.type == 'DELIVERY')}" @click="$emit('setFilters', 'type', 'DELIVERY')"><i class="bi bi-bicycle me-2"></i>Delivery</a>
							</li>
						</ul>
						<button type="button" id="filterDropdown" class="btn btn-sm btn-neutral" data-bs-toggle="dropdown" aria-expanded="false">Filtrar</button>
					</div>
					<button guide="orders-kanban-header-button-massive" type="button" class="btn btn-sm btn-square btn-neutral" @click="modalOrdersMassiveShow" v-if="isAdmin">
						<i class="bi bi-fast-forward"></i>
					</button>
					<button guide="orders-kanban-header-button-list" type="button" class="btn btn-sm btn-square btn-neutral position-relative" @click="modalOrdersIndexShow">
						<i class="bi bi-list"></i>
						<span class="badge bg-warning badge-count badge-sm text-sm border-2 border-current rounded-circle position-absolute top-0 end-0 transform mt-n2 me-n2" v-if="hasPaying"><i class="bi bi-coin"></i></span>
					</button>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';
	import { Modal, Offcanvas } from 'bootstrap';
	import _ from 'lodash';
	import composableStore from '@/composables/store';

	export default {
		emits: ['setFilters', 'emptyFilters'],
		props: {
			orders: Array,
			filters: Object
		},
		setup(props) {
			const { status, isAdmin } = composableStore();

			const tickets = computed(() => store.getters['tickets/list']);
			const ticketsNewMessages = computed(() => {
				var count = 0;
				_.forEach((tickets.value ?? []), (ticket) => { _.forEach(ticket.messages, (message) => { if(message.from != 'store' && _.indexOf(message.read_by, 'store') == -1) count++; }); });

				return count;
			});

			const payouts = computed(() => _.filter(store.getters['payouts/list'], { status: 'PENDING' }));
			const hasPaying = computed(() => (_.filter(props.orders, { status: 'PAYING' }).length > 0));

			const services = computed(() => (status.value?.type?.inmediate?.services ?? []));
			const typeLocal = computed(() => ('LOCAL' in services.value));
			const typeTakeaway = computed(() => ('TAKEAWAY' in services.value));
			const typeDelivery = computed(() => ('DELIVERY' in services.value));

			const modalOrdersIndexShow = () => Offcanvas.getInstance(document.getElementById('orders-index')).show();
			const modalOrdersMassiveShow = () => Modal.getInstance(document.getElementById('orders-modal-massive')).show();
			const modalTicketsIndexShow = () => Offcanvas.getInstance(document.getElementById('tickets-index')).show();
			const modalPayoutsShow = () => Modal.getInstance(document.getElementById('payouts-modal-index')).show();

			return { isAdmin, typeLocal, typeTakeaway, typeDelivery, modalOrdersIndexShow, modalOrdersMassiveShow, modalTicketsIndexShow, modalPayoutsShow, tickets, ticketsNewMessages, payouts, hasPaying };
		}
	}
</script>