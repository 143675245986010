<template>
	<cart-header-checkout :submitting="submitting" v-if="hasCart" />
	<!-- Main -->
	<main class="py-5 flex-grow-1" v-if="hasCart">
		<!-- Container -->
		<div class="container-fluid vstack gap-3 min-h-full">
			<div class="row gx-5">
				<div class="col-12 col-md-8 mb-7">
					<div class="card">
						<div class="card-body">
							<h5 class="card-title mb-5">Últimos datos</h5>
							<div class="row g-5">
								<template v-if="isAdmin">
									<div guide="views-cart-checkout-input-seller" class="col-12">
										<label for="seller_id" class="form-label">Vendedor <span class="text-muted">(Opcional)</span></label>
										<select id="seller_id" v-model="seller_id" class="form-select" :disabled="submitting">
											<option value="">Sin seleccionar</option>
											<option v-for="seller in sellers" v-bind:key="seller.id" v-bind:value="seller.id">{{ seller.fullname }}</option>
										</select>
										<small class="text-warning mt-2 d-block" v-if="code?.group"><b>Importante:</b> si no selecciona el vendedor, se asignará aquel que abrió el código.</small>
									</div>
								</template>
								<template v-if="!isAdmin">
									<div class="col-12">
										<label class="form-label">Vendedor</label>
										<user-card-main :user="user" />
									</div>
								</template>
								<div guide="views-cart-checkout-input-description" class="col-12 col-md">
									<label class="form-label" for="description">Descripción <span class="text-muted">(Opcional)</span></label>
									<input type="text" id="description" v-model="description" class="form-control" :disabled="submitting" placeholder="Ingresá una descripción">
								</div>
								<div guide="views-cart-checkout-input-phone" class="col-12 col-md">
									<label class="form-label" for="phone">
										Teléfono
										<span class="text-danger" v-if="type?.option == 'DELIVERY'">(Obligatorio)</span>
										<span class="text-muted" v-else>(Opcional)</span>
									</label>
									<input type="tel" id="phone" v-model="phone" class="form-control" :class="{'is-invalid': validation.phone.$error}" :disabled="submitting" placeholder="Ingresá un teléfono">
									<span class="mt-1 invalid-feedback" v-for="error of validation.phone.$errors" :key="error.$uid">{{ error.$message }}</span>
								</div>
								<div guide="views-cart-checkout-input-comments" class="col-12">
									<label class="form-label" for="comments">¿Necesitás aclarar algo? <span class="text-muted">(Opcional)</span></label>
									<input type="text" id="comments" v-model="comments" class="form-control" :disabled="submitting" placeholder="Agregar aclaración" maxlength="100">
								</div>
								<div guide="views-cart-checkout-when" id="checkout-when" class="col-12">
									<cart-checkout-when :submitting="submitting" />
								</div>
								<div guide="views-cart-checkout-payment" id="checkout-payment" class="col-12" v-if="canPayment">
									<label class="form-label d-flex justify-content-between">Forma de pago</label>
									<payment-block :submitting="submitting" :total="total" :saved="payments" @setPayments="setPayments" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 mb-7">
					<div class="cart-detail-checkout position-sticky">
						<cart-detail-checkout :submitting="submitting" :showOptions="true" />
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
	import { reactive, toRefs, computed, watch, onMounted } from 'vue';
	import store from '@/store';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { isValidPhoneNumber } from 'libphonenumber-js';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';
	import VueScrollTo from 'vue-scrollto';

	export default {
		setup() {
			const { current_store, isAdmin, sellers } = composableStore();
			const { type, total, payments, setPayments, hasCart, code, canPayment, submitting, seller_id, description, phone, comments } = composableCart();
			const user = computed(() => store.getters['auth/user']);

			const form = reactive({
				seller_id: seller_id.value ?? '',
				description: description.value ?? '',
				phone: phone.value ?? '',
				comments: comments.value ?? ''
			});

			const validatePhone = (value) => {
				return isValidPhoneNumber(value, current_store.value.country);
			}

			const validation = useVuelidate({
				phone: {
					validatePhone: helpers.withMessage('Teléfono inválido', validatePhone)
				}
			}, form);

			watch(() => form.seller_id, (newValue) => store.dispatch('cart/setSellerId', newValue));
			watch(() => form.description, (newValue) => store.dispatch('cart/setDescription', newValue));
			watch(() => form.phone, (newValue) => store.dispatch('cart/setPhone', newValue));
			watch(() => form.comments, (newValue) => store.dispatch('cart/setComments', newValue));

			onMounted(() => {
				VueScrollTo.scrollTo((window.innerWidth < 768) ? '.totals-area' : '.container-fluid', 150, { container: (window.innerWidth > 1200) ? '.main-slot' : null, offset: -67 });
			});

			return { ...toRefs(form), isAdmin, validation, code, sellers, user, canPayment, type, total, payments, setPayments, hasCart, submitting };
		}
	}
</script>

<style scoped>
	.cart-detail-checkout.position-sticky {
		top: 93px !important;
	}

	@media (min-width: 768px) {
		.cart-detail-checkout.position-sticky {
			top: 93px !important;
		}
	}
</style>