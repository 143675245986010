<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4 d-flex align-items-center">
			<div class="row g-0">
				<div class="col-12 col-md">
					<div class="d-flex align-items-center gap-3">
						<router-link :to="{ name: 'store.config.index', params: { store_id: current_store.id } }" class="btn btn-link p-0 text-reset text-md" v-if="route.name != 'store.config.index'"><i class="bi bi-chevron-left"></i></router-link>
						<div class="btn btn-link p-0 text-reset text-md" v-else><i class="bi bi-gear"></i></div>
						<div class="vr opacity-20 my-1"></div>
						<h1 class="h4 ls-tight">Configuraciones del punto de venta</h1>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import composableStore from '@/composables/store';
	import { useRoute } from 'vue-router';

	export default {
		setup() {
			const { current_store } = composableStore();
			const route = useRoute();

			return { current_store, route };
		}
	}
</script>