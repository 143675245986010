<template>
	<tr>
		<td class="ps-0" scope="row">
			<select v-model="method" class="form-select form-select-sm" :disabled="!can_modify || submitting">
				<option value="null" selected>Seleccionar</option>
				<option v-for="payment_method in current_store.payment_methods" v-bind:key="payment_method.type" v-bind:value="payment_method.type">{{ payment_method.title }}</option>
			</select>
		</td>
		<td>
			<VueNumberFormat :options="Object.assign({ acceptNegative: false }, $personalizer.currency.value)" v-model:value="amount" class="form-control form-control-sm" :disabled="!can_modify || submitting"></VueNumberFormat>
		</td>
		<td class="text-end pe-0">
			<button type="button" class="btn btn-sm btn-square btn-danger" @click="subPayment" :disabled="!can_modify || submitting"><i class="bi bi-trash"></i></button>
		</td>
	</tr>
</template>

<script>
	import { reactive, toRefs, watch, onMounted } from 'vue';
	import composableStore from '@/composables/store';

	export default {
		emits: ['setPayment', 'subPayment'],
		props: {
			submitting: Boolean,
			index: Number,
			payment: Object
		},
		setup(props, { emit }) {
			const { current_store } = composableStore();
			const subPayment = () => emit('subPayment', props.index);

			const form = reactive({
				id: null,
				method: null,
				amount: 0,
				can_modify: true
			});

			onMounted(() => {
				form.id = props.payment.id;
				form.method = props.payment.method;
				form.amount = props.payment.amount;
				form.can_modify = props.payment.can_modify;
			});

			watch(form, (newValue) => emit('setPayment', { index: props.index, data: newValue }));

			return { ...toRefs(form), current_store, subPayment };
		}
	}
</script>