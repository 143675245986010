<template>
	<teleport to="body">
		<div id="tickets-index" class="offcanvas offcanvas-end" tabindex="-1" aria-labelledby="offcanvasRightLabel">
			<div class="offcanvas-header">
				<h5 class="offcanvas-title" id="Label">Listado de tickets</h5>
				<button type="button" class="btn-close text-reset text-xs" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body pt-0">
				<div v-if="list.length > 0" class="list-group list-group-flush">
					<tickets-row class="list-group-item px-0 py-2" v-for="ticket in list" v-bind:key="ticket.id" :ticket="ticket" @select="select" />
				</div>
				<common-message-error class="col-12" text="No se encontraron tickets." v-if="list.length == 0" />
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import { Offcanvas } from 'bootstrap';
	import _ from 'lodash';

	export default {
		emits: ['select'],
		setup(props, { emit }) {
			const tickets = computed(() => store.getters['tickets/list']);
			const list = computed(() => _.orderBy(tickets.value, ['updated_at'], ['desc']));

			const modalTicketsIndex = ref(null);
			onMounted(() => modalTicketsIndex.value = new Offcanvas(document.getElementById('tickets-index')));

			const select = (data) => {
				modalTicketsIndex.value.hide();
				emit('select', data);
			}

			return { list, select };
		}
	}
</script>