<template>
	<teleport to="body">
		<div id="orders-index" guide="orders-index-offcanvas" class="offcanvas offcanvas-end" tabindex="-1" aria-labelledby="offcanvasRightLabel">
			<div class="offcanvas-header">
				<h5 class="offcanvas-title" id="Label">Pedidos finalizados</h5>
				<button type="button" class="btn-close text-reset text-xs" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body pt-0">
				<div v-if="list.length > 0" class="list-group list-group-flush">
					<orders-row class="list-group-item px-0 py-2" v-for="order in list" v-bind:key="order.id" :order="order" @select="select" />
				</div>
				<common-message-error class="col-12" text="No se encontraron pedidos." v-if="list.length == 0" />
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import router from '@/router';
	import { Offcanvas } from 'bootstrap';
	import _ from 'lodash';
	import composableStore from '@/composables/store';

	export default {
		emits: ['select'],
		props: {
			orders: Array
		},
		setup(props, { emit }) {
			const { current_store } = composableStore();
			const list = computed(() => _.orderBy(_.filter(props.orders, (order) => { return (_.indexOf(['PENDING', 'ACCEPTED', 'DELIVER'], order.status) === -1); }), ['created_at'], ['desc']));

			const modalOrdersIndex = ref(null);
			onMounted(() => modalOrdersIndex.value = new Offcanvas(document.getElementById('orders-index')));

			const select = (data) => {
				modalOrdersIndex.value.hide();
				if(data.status == 'PAYING') router.push({ name: 'paying', params: { store_id: current_store.value.id, type: 'order', id: data.id } });
				else emit('select', data);
			}

			return { list, select };
		}
	}
</script>