<template>
	<payment-row-single :submitting="submitting" :payment="payments[0] ?? null" :total="total" @setPayment="setPayment" v-if="!showMultiple" />
	<payment-type-multiple :submitting="submitting" :payments="payments" :total="total" :renderization="renderization" @setPayment="setPayment" @subPayment="subPayment" v-if="showMultiple" />
	<div class="text-sm fw-semibold mt-3 px-2 d-flex justify-content-between" v-if="payments.length > 0 && !submitting">
		<a href="javascript:void(0)" class="text-dark" @click="addPayment"><i class="bi bi-plus-lg pe-1"></i>Agregar forma de pago</a>
		<a href="javascript:void(0)" class="text-danger" @click="rmvPayments"><i class="bi bi-trash pe-1"></i>Quitar pagos</a>
	</div>
</template>

<script>
	import { ref, computed, watch, onMounted } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['setPayments'],
		props: {
			submitting: Boolean,
			total: Number,
			saved: Array
		},
		setup(props, { emit }) {
			const renderization = ref(0);
			const payments = ref([]);
			const setPayment = (data) => payments.value[data.index] = data.data;
			const addPayment = () => payments.value.push({ id: null, method: null, amount: null, option: null, can_modify: true });
			const subPayment = (index) => {
				renderization.value++;
				payments.value.splice(index, 1);
			}

			const rmvPayments = () => payments.value = _.filter(_.cloneDeep(payments.value), { can_modify: false });

			const showMultiple = computed(() => {
				var exactPayment = Number(_.sumBy(payments.value, 'amount'));
				return (payments.value.length > 1 || (exactPayment > 0 && props.total != exactPayment));
			});

			watch(payments, (newValue) => emit('setPayments', newValue), { deep: true });
			onMounted(() => payments.value = _.cloneDeep(props.saved));

			return { renderization, payments, setPayment, addPayment, subPayment, rmvPayments, showMultiple };
		}
	}
</script>