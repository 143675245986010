<template>
	<teleport to="body">
		<div class="modal" id="cart-modal-new" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-sm modal-dialog-centered">
				<div class="modal-content shadow-3">
					<div class="modal-header d-block">
						<h4 class="modal-title">Cargar pedido</h4>
						<small class="d-block text-muted">Seleccioná el tipo de pedido</small>
					</div>
					<div class="modal-body p-0 list-group list-group-flush">
						<button type="button" class="list-group-item fw-bold" @click="selectCodeDefault" v-if="codeDefault && typeLocal">En local: <b>{{ codeDefault.description }}</b></button>
						<button type="button" class="list-group-item fw-bold" @click="selectTakeaway" v-if="typeTakeaway">Take away</button>
						<button type="button" class="list-group-item fw-bold" @click="redirectDelivery" v-if="typeDelivery">Delivery</button>
						<div class="list-group-item py-0 px-5" v-if="codes.length > 0 || maps.length > 0">
							<div class="row">
								<div class="col d-inline-block d-lg-none" @click="redirectCamera" v-if="codes.length > 0">
									<button type="button" class="btn btn-link w-100 text-dark py-4"><i class="bi bi-camera"></i></button>
								</div>
								<div class="col" @click="redirectMap" v-if="maps.length > 0">
									<button type="button" class="btn btn-link w-100 text-dark py-4"><i class="bi bi-map"></i></button>
								</div>
								<div class="col" @click="redirectCode" v-if="codes.length > 0">
									<button type="button" class="btn btn-link w-100 text-dark py-4"><i class="bi bi-qr-code"></i></button>
								</div>
							</div>
						</div>
						<button type="button" class="list-group-item text-danger" data-bs-dismiss="modal">Cancelar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { computed } from 'vue';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { status, codes, codeDefault, maps } = composableStore();
			const { selectCodeDefault, selectTakeaway, redirectDelivery, redirectCamera, redirectCode, redirectMap } = composableCart();

			const services = computed(() => (status.value?.type?.inmediate?.services ?? []));
			const typeLocal = computed(() => ('LOCAL' in services.value));
			const typeTakeaway = computed(() => ('TAKEAWAY' in services.value));
			const typeDelivery = computed(() => ('DELIVERY' in services.value));

			return { status, codes, maps, codeDefault, typeLocal, typeTakeaway, typeDelivery, selectCodeDefault, selectTakeaway, redirectDelivery, redirectCamera, redirectCode, redirectMap };
		}
	}
</script>

<style scoped>
	i.bi {
		font-size: 1.25rem !important;
	}
</style>