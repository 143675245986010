<template>
	<canvas id="canvas-map" width="800" height="800"></canvas>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import composableStore from '@/composables/store';
	import _ from 'lodash';
	import { fabric } from 'fabric';

	export default {
		emits: ['select'],
		props: {
			map: Object,
			orderGroups: Array
		},
		setup(props, { emit }) {
			const { codes } = composableStore();
			const canvas = ref(null);

			const loadMap = (map) => {
				if(canvas.value) canvas.value.dispose();

				canvas.value = new fabric.Canvas(`canvas-map`, { backgroundColor: '#f8f8f8', hoverCursor: 'pointer', selection: false });
				canvas.value.loadFromJSON(map.draw);
				canvas.value.getObjects().map((o) => o.selectable = false);
				canvas.value.on('mouse:down', (options) => {
					if(options?.target?.code_id) {
						var code = _.find(codes.value, { id: Number(options.target.code_id) });
						if(code) emit('select', code);
					}
				});
			}

			const render = () => {
				canvas.value.getObjects().map(o => {
					if(o?.code_id) {
						var orderGroup = _.find(props.orderGroups, (data) => { return (data.code.id == Number(o.code_id) && _.indexOf(['OPENED', 'PAYING'], data.status) !== -1); });

						o.getObjects().forEach((obj) => {
							if(obj.type != 'text') {
								var color = (orderGroup?.waiting_count > 0) ? '#ff3366' : ((orderGroup?.status == 'PAYING') ? '#00d4ff' : '#39cc5b'),
									width = (orderGroup) ? 3 : 0;

								obj.set('stroke', color);
								obj.set('strokeWidth', width);
							}
						});
					}
				});

				canvas.value.renderAll();
			}

			onMounted(() => {
				loadMap(props.map);
				render();
			});

			watch(() => props.orderGroups, render);
			watch(() => props.map, (newValue) => {
				loadMap(newValue);
				render();
			});
		}
	}
</script>

<style>
	.canvas-container {
		margin-left: auto;
		margin-right: auto;
		width: 100% !important;
		max-width: 800px !important;
		height: unset !important;
		aspect-ratio: 1 / 1;
	}

	.lower-canvas, .upper-canvas {
		width: 100% !important;
		height: 100% !important;
		max-height: 800px !important;
	}
</style>