<template>
	<div class="d-xl-flex">
		<div class="min-w-0 flex-xl-fill mb-5">
			<div class="container-fluid h-100">
				<cashbox-detail-index :cashbox="cashbox" />
				<div guide="cashbox-detail-index-summary" class="row mt-10">
					<div class="col-12 col-md-6 mb-10 mb-md-0">
						<cashbox-detail-payments :cashbox="cashbox" />
					</div>
					<div class="col-12 col-md-6">
						<cashbox-detail-flows :cashbox="cashbox" />
					</div>
					<div class="col-12 mt-8">
						<div class="alert text-warning border-warning" role="alert">
							<strong>Importante:</strong> La configuración de la caja podrá cambiarse desde el <b>panel de partners</b>.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-xl-none col-xl-4 h-xl-calc mb-5" style="--x-h-xl: 4.5rem;">
			<div class="container-fluid h-100">
				<cashbox-close :cashbox="cashbox" @reload="$emit('reload')" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['reload'],
		props: {
			cashbox: Object
		}
	}
</script>