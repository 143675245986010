<template>
	<table class="table table-sm table-hover">
		<thead>
			<tr>
				<th scope="col">Movimientos de dinero</th>
				<th class="text-end" scope="col">Monto</th>
			</tr>
		</thead>
		<tbody>
			<tr v-if="cashbox.balance.cashbox_flows.length == 0">
				<td colspan="2">No hay elementos.</td>
			</tr>
			<tr v-for="flow in cashbox.balance.cashbox_flows" v-bind:key="flow.id">
				<td>
					<div class="h5">{{ flow.type_description }}</div>
					<div><b>Descripción:</b> {{ flow.description }}</div>
					<div>Creado a las <b>{{ createdAtFormatted(flow.created_at) }}</b> por <b>{{ flow.user.fullname }}</b></div>
				</td>
				<td class="text-end">{{ vueNumberFormat(flow.amount, $personalizer.currency.value) }}</td>
			</tr>
		</tbody>
		<tfoot>
			<tr>
				<th>Total</th>
				<th class="text-end">{{ vueNumberFormat(cashboxFlowsTotals, $personalizer.currency.value) }}</th>
			</tr>
		</tfoot>
	</table>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';
	import _ from 'lodash';

	export default {
		props: {
			cashbox: Object
		},
		setup(props) {
			const methodDescription = (method) => {
				return (_.has(props.cashbox.payment_methods, method))
					? props.cashbox.payment_methods[method].title
					: '-';
			}

			const createdAtFormatted = (created_at) => moment(created_at).format('HH:mm[Hs]');
			const cashboxFlowsTotals = computed(() => _.sumBy(props.cashbox.balance.cashbox_flows, 'amount'));

			return { methodDescription, createdAtFormatted, cashboxFlowsTotals };
		}
	}
</script>