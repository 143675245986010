import _ from 'lodash';

const DEFAULT = {
	store: null,
	list: []
};

export default {
	namespaced: true,
	state: DEFAULT,
	getters: {
		isDefined(state) {
			return !!state.store;
		},
		isEmpty(state) {
			return (state.list.length === 0);
		},
		store(state) {
			return state.store;
		},
		list(state) {
			return state.list;
		}
	},
	actions: {
		destroy({ commit }) {
			commit('destroy');
		},
		setStore({ commit }, store) {
			commit('setStore', store);
		},
		setList({ commit }, list) {
			commit('setList', list);
		},
		sync({ dispatch, state }, payout) {
			var list = _.clone(state.list);
			var hasPayout = false;

			_.forEach(list, (o, key) => {
				if(o.id == payout.id) {
					hasPayout = true;
					list[key] = payout;
				}
			});

			if(!hasPayout) list.push(payout);
			dispatch('setList', list);
		}
	},
	mutations: {
		destroy(state) {
			Object.assign(state, DEFAULT);
		},
		setStore(state, store) {
			state.store = store;
		},
		setList(state, list) {
			state.list = list;
		}
	}
}