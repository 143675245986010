<template>
	<teleport to="body">
		<div id="permission-install" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
			<div class="offcanvas-body container">
				<div class="row justify-content-center align-items-center">
					<div class="col-lg-8 text-center">
						<h2 class="fw-bolder text-dark mb-3">¿Usás Wantti periódicamente?</h2>
						<p class="lead text-muted mb-8">Instalá nuestra aplicación progresiva y obtené una mejor experiencia.</p>
						<div class="d-grid gap-2 col-6 mx-auto">
							<button type="button" class="btn btn-dark mb-2" @click="accept">Instalar</button>
							<a href="javascript:void(0)" class="text-muted" @click="dismiss">Ahora no</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
	import { useCookie } from 'vue-cookie-next';
	import { Offcanvas } from 'bootstrap';

	export default {
		setup() {
			const { setCookie, isCookieAvailable } = useCookie();
			const deferredPrompt = ref(null);
			const timeout = ref(null);

			window.addEventListener('appinstalled', () => {
				deferredPrompt.value = null;
			});

			window.addEventListener('beforeinstallprompt', (e) => {
				e.preventDefault();
				if(!isCookieAvailable('add-to-home-screen')) deferredPrompt.value = e;
			});

			const dismiss = async () => {
				setCookie('add-to-home-screen', null, { expire: '10d' });
				deferredPrompt.value = null;
				permissionInstallHide();
			}

			const accept = async () => {
				permissionInstallHide();
				deferredPrompt.value.prompt();
			}

			const permissionInstall = ref(null);
			onMounted(() => permissionInstall.value = new Offcanvas(document.getElementById('permission-install')));
			onBeforeUnmount(() => { if(timeout.value) clearTimeout(timeout.value); });
			const permissionInstallShow = () => permissionInstall.value.show();
			const permissionInstallHide = () => permissionInstall.value.hide();

			const previousShow = () => {
				if(document.querySelector('.modal.show') || document.querySelector('.offcanvas.show')) timeout.value = setTimeout(previousShow, 5000);
				else permissionInstallShow();
			}

			watch(deferredPrompt, (newValue) => {
				if(newValue) timeout.value = setTimeout(previousShow, 60000);
			});

			return { dismiss, accept };
		}
	}
</script>

<style scoped>
	.btn {
		border-radius: 25px !important;
	}
</style>