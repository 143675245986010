import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import auth from './modules/auth';
import config from './modules/config';
import store from './modules/store';
import cart from './modules/cart';
import orders from './modules/orders';
import orderGroups from './modules/orderGroups';
import tickets from './modules/tickets';
import payouts from './modules/payouts';
import redirect from './modules/redirect';

const vuexLocalStorage = new VuexPersistence({
	storage: window.localStorage,
	modules: ['auth', 'config']
});

const vuexSessionStorage = new VuexPersistence({
	storage: window.sessionStorage,
	modules: ['redirect', 'store', 'orders', 'orderGroups', 'tickets', 'payouts']
});

export default createStore({
	plugins: [
		vuexLocalStorage.plugin,
		vuexSessionStorage.plugin
	],
	modules: {
		auth: auth,
		config: config,
		store: store,
		cart: cart,
		orders: orders,
		orderGroups: orderGroups,
		tickets: tickets,
		payouts: payouts,
		redirect: redirect
	}
})
