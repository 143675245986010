<template>
	<div class="row g-6">
		<div class="col-12" v-if="searchTerm.length > 0 && filtered.length == 0">
			<common-message-error class="col-12" text="No se encontraron resultados." />
		</div>
		<catalog-item-card v-for="item in filtered" :key="item.id" :item="item" :submitting="submitting" @select="selectItem" ref="itemRefs"></catalog-item-card>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import composableStore from '@/composables/store';
	import _ from 'lodash';

	export default {
		emits: ['selectItem'],
		props: {
			sections: Array,
			searchTerm: String,
			submitting: Boolean
		},
		setup(props, { emit }) {
			const { catalog } = composableStore();

			const items = computed(() => {
				var sectionIds = _.flatMap(props.sections, (row) => { return row.id; });
				var items = _.filter(catalog.value.items, (row) => { return _.indexOf(sectionIds, row.section_id) !== -1; });

				return _.sortBy(_.uniqBy(items, 'id'), 'name');
			});

			const filtered = computed(() => {
				if(props.searchTerm.length == 0) return [];
				else return _.filter(items.value, (row) => {
					var pass = true;

					props.searchTerm.toLowerCase().split(' ').forEach((term) => {
						if(!row.name.toLowerCase().includes(term) && !(row.description ?? '').toLowerCase().includes(term)) pass = false;
					});

					return pass;
				});
			});

			const itemRefs = ref([]);

			const addFirst = () => {
				var first = _.first(itemRefs.value);
				if(first) first.add();
			}

			const subFirst = () => {
				var first = _.first(itemRefs.value);
				if(first) first.sub();
			}

			const selectItem = (data) => emit('selectItem', data);

			return { filtered, itemRefs, addFirst, subFirst, selectItem };
		}
	}
</script>