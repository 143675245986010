<template>
	<teleport to="body">
		<div class="modal" id="ordergroup-modal-view" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content shadow-3" v-if="ordergroup">
					<div class="modal-header">
						<div>
							<h4 class="modal-title">Detalle de <u>{{ ordergroup.code.description }}</u></h4>
							<small class="d-block">Creado el {{ getCreatedAtFormatted }}</small>
						</div>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body pt-0">
						<div class="row">
							<div class="col-12 px-0">
								<div class="list-group list-group-flush">
									<div class="list-group-item px-6 py-2 d-flex justify-content-between align-items-center" v-if="ordergroup.status == 'OPENED'">
										<button type="button" class="btn btn-sm w-100 btn-primary" @click="selectCode(ordergroup.code)">Agregar pedido</button>
									</div>
									<div class="list-group-item px-6 py-0 d-flex justify-content-between align-items-center" v-if="ordergroup.seller">
										<div class="fw-semibold py-3">Vendedor</div>
										<div class="ms-auto text-end text-sm text-muted">{{ ordergroup.seller.fullname }}</div>
									</div>
									<div class="list-group-item px-6 py-0 d-flex justify-content-between align-items-center" v-if="ordergroup.data?.people">
										<div class="fw-semibold py-3">Cantidad de personas</div>
										<div class="ms-auto text-end text-sm text-muted">{{ ordergroup.data?.people }}</div>
									</div>
									<div class="list-group-item px-6 py-0 d-flex justify-content-between align-items-center" v-if="ordergroup.data?.childrens">
										<div class="fw-semibold py-3">Hay niños</div>
										<div class="ms-auto text-end text-sm text-muted">Sí</div>
									</div>
								</div>
							</div>
							<div class="col-12 mt-5">
								<h5>Detalle del pedido</h5>
							</div>
							<div class="col-12">
								<div class="list-group list-group-flush mt-1">
									<ordergroup-item v-for="(item, index) in ordergroup.items" v-bind:key="item.id" :item="item" :index="index" @delete="itemRemoveSelect" />
									<div class="list-group-item px-0 py-3 d-flex border-bottom-0 text-warning" v-if="ordergroup.items.length == 0">
										No hay productos.
									</div>
									<div class="list-group-item px-0 pb-0 fw-semibold">
										<div class="d-flex justify-content-between mb-2" v-if="subtotal != total">
											<div class="progress-text mb-0 d-block">Subtotal</div>
											<div class="text-end">{{ vueNumberFormat(subtotal, $personalizer.currency.value) }}</div>
										</div>
										<div class="d-flex justify-content-between mb-2" v-if="discount > 0">
											<div class="text-danger progress-text mb-0 d-block">Descuento</div>
											<div class="text-end text-danger">{{ vueNumberFormat(discount, $personalizer.currency.value) }}</div>
										</div>
										<div class="d-flex justify-content-between mb-2" v-if="ordergroup.type == 'DELIVERY'">
											<div class="progress-text mb-0 d-block">Envío</div>
											<div class="text-end">{{ vueNumberFormat(delivery_amount, $personalizer.currency.value) }}</div>
										</div>
										<div class="d-flex justify-content-between h5">
											<div class="progress-text mb-0 d-block">Total</div>
											<div class="text-end">{{ vueNumberFormat(total, $personalizer.currency.value) }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 mt-5" v-if="ordergroup.total > 0 || ordergroup.payments.length > 0">
								<h5>Forma de pago</h5>
							</div>
							<div class="col-12 mt-3" v-if="ordergroup.total > 0 || ordergroup.payments.length > 0">
								<payment-block :total="ordergroup.total" :saved="ordergroup.payments" @setPayments="setPayments" />
							</div>
						</div>
					</div>
					<div class="modal-footer d-flex justify-content-between">
						<div class="dropup">
							<button class="btn btn-sm btn-warning dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								Opciones
							</button>
							<ul class="dropdown-menu">
								<li v-if="!isPlanFree"><a href="javascript:void(0);" class="dropdown-item" @click="printSummary('ordergroup', ordergroup.id)"><i class="bi bi-printer pe-2"></i>Imprimir resumen</a></li>
								<li v-if="isPlanPremium && (billing && ordergroup.billings_count > 0)"><a href="javascript:void(0);" class="dropdown-item" @click="printInvoice('ordergroup', ordergroup.id)"><i class="bi bi-printer pe-2"></i>Imprimir factura</a></li>
								<hr class="dropdown-divider my-2" />
								<li><a href="javascript:void(0);" class="dropdown-item" @click="selectChange"><i class="bi bi-arrow-left-right pe-2"></i>Cambiar código</a></li>
								<li v-if="isPlanPremium && (billing && ordergroup.billings_count == 0 && ordergroup.status == 'COMPLETE')"><router-link :to="{ name: 'store.orderGroup.billing.invoice', params: { store_id: current_store.id, id: ordergroup.id } }" class="dropdown-item"><i class="bi bi-receipt-cutoff pe-2"></i>Facturar</router-link></li>
								<li><a href="javascript:void(0);" class="dropdown-item text-warning" @click="modalOrderTotalShow"><i class="bi bi-percent pe-2"></i>Aplicar descuento</a></li>
							</ul>
						</div>
						<div class="btn-group btn-group-sm dropup">
							<button type="button" class="btn btn-dark" :disabled="submitting" @click="submit()">Guardar</button>
							<button type="button" class="btn btn-dark border-start dropdown-toggle dropdown-toggle-split" :disabled="submitting" data-bs-toggle="dropdown" aria-expanded="false">
								<span class="visually-hidden">Toggle Dropstart</span>
							</button>
							<ul class="dropdown-menu">
								<li v-if="ordergroup.status != 'COMPLETE'"><a href="javascript:void(0);" class="dropdown-item" @click="submit('COMPLETE')">Finalizar</a></li>
								<hr class="dropdown-divider" v-if="ordergroup.status != 'REJECTED'" />
								<li><a href="javascript:void(0);" class="dropdown-item text-danger" @click="reject"><i class="bi bi-trash pe-2"></i>Rechazar</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>

	<common-order-total :subtotal="ordergroup.subtotal" @setDiscount="setDiscount" v-if="ordergroup" />
</template>

<script>
	import { ref, computed, watch, onMounted } from 'vue';
	import { useToast } from 'vue-toastification';
	import { Modal, Offcanvas } from 'bootstrap';
	import store from '@/store';
	import router from '@/router';
	import _ from 'lodash';
	import generalDelete from '@/components/common/notification/general/delete';
	import OrderGroupsRepository from '@/repositories/OrderGroupsRepository';
	import OrdersRepository from '@/repositories/OrdersRepository';
	import composableStore from '@/composables/store';
	import composableOrder from '@/composables/order';
	import composableCart from '@/composables/cart';
	import composableForm from '@/composables/form';
	import { printSummary, printInvoice } from '@/helpers/Printpoint';

	export default {
		emits: ['select', 'selectChange'],
		props: {
			id: Number
		},
		setup(props, { emit }) {
			const { current_store, isPlanFree, isPlanPremium, gateways } = composableStore();
			const { reload, subtotal, discount, delivery_amount, total, setDiscount, typeInfo, getCreatedAtFormatted, getReadyByFormatted, shippingInfo } = composableOrder();
			const { selectCode } = composableCart();
			const { submitting, catchError } = composableForm();
			const toast = useToast();
			const modalView = ref(null);
			const orderGroups = computed(() => store.getters['orderGroups/list']);
			const ordergroup = computed(() => _.find(orderGroups.value, { id: props.id }));
			const billing = computed(() => _.find(gateways.value, { type: 'billing' }));

			const payments = ref([]);
			const setPayments = (value) => payments.value = value;

			const items = computed(() => {
				var items_assoc = [],
					items = [];

				_.forEach(ordergroup.value.order_detail, function(item) {
					if(item.ref == null) {
						items_assoc[item.id] = item;
						items_assoc[item.id].additionals = [];
					}else items_assoc[item.ref].additionals.push(item);
				});

				_.forEach(items_assoc, function(item) {
					items.push(item);
				});

				return items;
			});

			onMounted(() => {
				modalView.value = new Modal(document.getElementById('ordergroup-modal-view'));

				document.getElementById('ordergroup-modal-view').addEventListener('hidden.bs.modal', () => {
					emit('select', null);
				});
			});

			watch(() => ordergroup.value, (newValue) => {
				if(newValue) reload(ordergroup.value);
			}, { deep: true });

			watch(() => props.id, (newValue) => {
				if(newValue) modalView.value.show();
			});

			const submit = async (status = null) => {
				var data = {
					status: status,
					payments: payments.value,
					subtotal: subtotal.value,
					discount: discount.value,
					delivery_amount: delivery_amount.value,
					total: total.value
				}

				if(submitting.value) return false;

				submitting.value = true;
				await OrderGroupsRepository.update(current_store.value.id, ordergroup.value.id, data)
					.then((response) => {
						if(response.data.status == 'PAYING') router.push({ name: 'paying', params: { store_id: current_store.value.id, type: 'orderGroup', id: response.data.id } });
						else if(isPlanPremium.value && billing.value && ordergroup.value.billings_count == 0 && response.data.status == 'COMPLETE') router.push({ name: 'store.orderGroup.billing.invoice', params: { store_id: current_store.value.id, id: ordergroup.value.id } });
						modalView.value.hide();
					})
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			const selectChange = () => {
				emit('selectChange', ordergroup.value);
				modalView.value.hide();
			}

			const reject = () => {
				toast.error({
					component: generalDelete,
					props: {
						title: 'Eliminar pedidos',
						description: `¿Realmente deseas eliminar los pedidos de <u>${ordergroup.value.code.description}</u>?`,
						target: null
					},
					listeners: {
						destroy: () => {
							modalView.value.hide();

							OrderGroupsRepository.destroy(current_store.value.id, ordergroup.value.id).then(() => {
								toast.success(`Los pedidos fueron eliminados.`);
							}).catch(catchError);
						}
					}
				}, { position: 'top-center', transition: 'Vue-Toastification__fade', timeout: 5000 });
			}

			const modalOrderTotalShow = () => {
				if(submitting.value) return;
				Offcanvas.getInstance(document.getElementById('common-order-total')).show();
			}

			const itemRemoveSelect = (item) => {
				toast.error({
					component: generalDelete,
					props: {
						title: 'Eliminar item',
						description: `¿Realmente deseas eliminar el item <u>${item.name}</u>?`,
						target: item
					},
					listeners: {
						destroy: (item) => {
							OrdersRepository.destroyItem(current_store.value.id, item.order.id, item.id).then(() => {
								toast.success(`El item fue eliminado.`);
							}).catch(catchError);
						}
					}
				}, { position: 'top-center', transition: 'Vue-Toastification__fade', timeout: 5000 });
			}

			return { ordergroup, current_store, isPlanFree, isPlanPremium, billing, items, subtotal, discount, delivery_amount, total, typeInfo, getCreatedAtFormatted, getReadyByFormatted, shippingInfo, submitting, submit, selectChange, reject, modalOrderTotalShow, setDiscount, payments, setPayments, selectCode, printSummary, printInvoice, itemRemoveSelect };
		}
	}
</script>