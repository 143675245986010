import { ref } from 'vue';
import { Modal } from 'bootstrap';

export default function() {
	const time = 5 * 60 * 1000;

	const show = () => {
		if(document.getElementById('common-modal-screensaver') && window.innerWidth >= 768 && !document.querySelector('.modal.show') && !document.querySelector('.offcanvas.show')) {
			var modalScreenSaver = Modal.getInstance(document.getElementById('common-modal-screensaver'));
			modalScreenSaver.show();
		}
	}

	const hide = () => {
		if(document.getElementById('common-modal-screensaver')) {
			var modalScreenSaver = Modal.getInstance(document.getElementById('common-modal-screensaver'));
			modalScreenSaver.hide();
		}
	}

	const timeout = ref(setTimeout(show, time));
	const hasActivity = () => {
		if(timeout.value) {
			clearTimeout(timeout.value);
			timeout.value = null;
		}

		timeout.value = setTimeout(show, time);
		hide();
	}

	['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'].forEach((event) => document.addEventListener(event, hasActivity, true));
}
