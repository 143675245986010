<template>
	<div class="d-flex justify-content-between align-items-center">
		<div>Pedido recibido Nro. {{ order.number }}</div>
		<a href="javascript:void(0);" class="btn btn-sm btn-primary text-uppercase px-2 py-1 ms-3" @click="accept">Aceptar</a>
	</div>
</template>

<script>
	import store from '@/store';

	export default {
		props: {
			order: Object
		},
		setup(props) {
			const accept = () => store.dispatch('orders/updateStatus', { id: props.order.id, newStatus: 'ACCEPTED' });

			return { accept };
		}
	}
</script>