<template>
	<div class="container-fluid mw-screen-sm vstack gap-3 min-h-full">
		<form class="card" @submit.prevent="submit">
			<div class="card-header h4 fw-bolder">Abrir caja</div>
			<div class="card-body">
				<div class="row">
					<div guide="cashbox-open-user" class="col-12 mb-5">
						<label class="form-label">Encargado</label>
						<user-card-main :user="user" />
					</div>
					<div guide="cashbox-open-input-amount" class="col-12">
						<label for="amount" class="form-label">Saldo inicial</label>
						<VueNumberFormat id="amount" :options="Object.assign({ acceptNegative: false }, $personalizer.currency.value)" v-model:value="amount" class="form-control" :class="{'is-invalid': validation.amount.$error}" :disabled="submitting" v-focus></VueNumberFormat>
						<span class="mt-1 invalid-feedback" v-for="error of validation.amount.$errors" :key="error.$uid">{{ error.$message }}</span>
					</div>
					<div class="col-12 mt-5 text-center" v-if="validationExternal.length > 0">
						<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
					</div>
				</div>
			</div>
			<div class="card-footer" :class="{ 'd-flex justify-content-between': cashbox.cashbox_last_id, 'text-end': !cashbox.cashbox_last_id }">
				<button type="button" guide="cashbox-open-button-last" class="btn btn-warning" :disabled="submitting" @click="printCashbox(cashbox.cashbox_last_id)" v-if="cashbox.cashbox_last_id"><i class="bi bi-printer me-2"></i>Última caja</button>
				<button type="submit" guide="cashbox-open-button-submit" class="btn btn-primary" :disabled="submitting">Abrir caja</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { reactive, toRefs, computed } from 'vue';
	import store from '@/store';
	import useVuelidate from '@vuelidate/core';
	import { required, minValue } from '@/helpers/i18n/validators';
	import composableRedirect from '@/composables/redirect';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import CashboxRepository from '@/repositories/CashboxRepository';
	import { printCashbox } from '@/helpers/Printpoint';

	export default {
		props: {
			cashbox: Object
		},
		setup() {
			const { redirectBack } = composableRedirect();
			const { current_store } = composableStore();
			const { submitting, validationExternal, catchError } = composableForm();
			const user = computed(() => store.getters['auth/user']);

			const form = reactive({
				amount: 0
			});

			const validation = useVuelidate({
				amount: {
					required,
					minValue: minValue(0)
				}
			}, form);

			const submit = async () => {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await CashboxRepository.store(current_store.value.id, form)
					.then(redirectBack)
					.catch(catchError);
				submitting.value = false;
			}

			return { ...toRefs(form), user, validation, validationExternal, submitting, submit, printCashbox };
		}
	}
</script>