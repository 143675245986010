<template>
	<footer class="footer mt-auto py-12 bg-dark text-light">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="mb-8">
					<h2 class="h2 text-white fw-semibold mb-2">¿Necesitás Wantti?</h2>
					<h3 class="h3 text-white fw-semibold mb-5">¡Todo es muy simple! En pocos minutos podés vender con Wantti en tu negocio.</h3>
					<a href="mailto:info@wantti.com" class="h3 text-warning">
						info@wantti.com
						<span class="svg-icon svg-align-baseline ms-3">
							<i class="bi bi-arrow-right"></i>
						</span>
					</a>
				</div>
				<div>
					<a href="/web" class="btn btn-primary" target="_blank">Conocé más</a>
				</div>
			</div>
		</div>
	</div>
</footer>
</template>