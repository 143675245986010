<template>
	<div class="modal" id="common-modals-confirm" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content shadow-3">
				<div class="modal-header" v-if="title">
					<h4 class="modal-title">{{ title }}</h4>
				</div>
				<div class="modal-body" v-if="description">
					<p class="text-break" v-html="description"></p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cancelar</button>
					<button type="button" class="btn btn-sm btn-dark" @click="confirm">Confirmar</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import { Modal } from 'bootstrap';

	export default {
		emits: ['confirm'],
		props: {
			title: String,
			description: String
		},
		setup(props, { emit }) {
			const modal = ref(null);
			onMounted(() => {
				modal.value = new Modal(document.getElementById('common-modals-confirm'));
			});

			const confirm = () => {
				emit('confirm');
				modal.value.hide();
			}

			return { confirm };
		}
	}
</script>