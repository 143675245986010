<template>
	<div class="modal" id="modal-timetable" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-sm">
			<div class="modal-content shadow-3">
				<div class="modal-header">
					<h5 class="modal-title">Horarios de la tienda</h5>
					<div class="text-xs ms-auto">
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
				</div>
				<div class="modal-body py-0">
					<div class="list-group list-group-flush" v-if="hasHours">
						<div class="list-group-item d-flex px-0" v-for="(hour, day) in hours" v-bind:key="day">
							<div class="flex-fill">
								<!-- Title -->
								<div class="d-block h6 mb-1" :class="{'text-success': dayCurrent(hour.day_idx), 'text-dark-50': !dayCurrent(hour.day_idx)}">{{ dayName(hour.day_idx) }}</div>
								<!-- Status -->
							</div>
							<div class="">
								<p class="p-0 m-0" :class="{'text-success': dayCurrent(hour.day_idx), 'text-dark-50': !dayCurrent(hour.day_idx)}" v-for="(time, key) in hour.hours" v-bind:key="key">De {{ time.from.substring(0, 5) }} a {{ time.to.substring(0, 5) }} hs</p>
							</div>
						</div>
					</div>
					<p class="py-5 text-warning" v-if="!hasHours">El comercio no posee horarios cargados</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	const DAYS = {
		'1': 'Lunes',
		'2': 'Martes',
		'3': 'Miércoles',
		'4': 'Jueves',
		'5': 'Viernes',
		'6': 'Sábado',
		'7': 'Domingo'
	}

	export default {
		props: {
			hours: Array
		},
		setup(props) {
			const hasHours = computed(() => {
				return props.hours.length > 0;
			});

			function dayCurrent(day) {
				var current = new Date();
				if (day == current.getDay())
					return true;

				return false;
			}

			function dayName(day) {
				if (_.has(DAYS, day))
					return DAYS[day];

				return null;
			}

			return { hasHours, dayCurrent, dayName };
		}
	}
</script>