<template>
	<cashbox-header-settings @reload="getData" />
	<!-- Main -->
	<main class="py-5 flex-grow-1">
		<!-- Container -->
		<div class="container-fluid mw-screen-md vstack gap-6 min-h-full">
			<div class="alert text-warning border-warning" role="alert">
				<strong>Importante:</strong> Esta configuración puede realizarse sólo una vez desde el punto de venta, en caso de querer modificarla deberá hacerlo desde el <b>panel de partners</b>.
			</div>
			<div class="alert text-dark border-dark" role="alert">
				<strong class="d-block">¿Cómo se contabilizan los pagos?</strong>
				Para los pedidos que sean creados por los clientes, los pagos serán contabilizados en caja cuando el pedido sea finalizado. Cuando los pedidos sean creados por el comercio, los pagos serán contabilizados al momento de ser registrados.
			</div>
			<form class="card" @submit.prevent="submit">
				<div class="card-header h4 fw-bolder">Configuración general</div>
				<div guide="views-cashbox-settings-inputs" class="card-body">
					<div class="row">
						<div class="col-12">
							<div class="form-check">
								<input type="checkbox" id="payouts" v-model="payouts" class="form-check-input">
								<label class="form-check-label" for="payouts">
									Contabilizar rendiciones de dinero.
									<small class="d-block text-muted">Que sean recibidas cuando exista una caja abierta.</small>
								</label>
							</div>
							<div class="form-check mt-2">
								<input type="checkbox" id="block" v-model="block" class="form-check-input">
								<label class="form-check-label" for="block">
									Bloquear la carga o modificación de pedidos cuando la caja se encuentre cerrada.
									<small class="d-block text-muted">Esta opción es conveniente para llevar un mayor control de su comercio.</small>
								</label>
							</div>
						</div>
						<div class="col-12 mt-5 text-center" v-if="validationExternal.length > 0">
							<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<button type="button" class="btn btn-secondary" :disabled="submitting" @click="redirectBack">Cancelar</button>
					<button guide="views-cashbox-settings-button-submit" type="submit" class="btn btn-primary" :disabled="submitting">Guardar</button>
				</div>
			</form>
		</div>
	</main>
</template>

<script>
	import { reactive, toRefs, computed } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { useToast } from 'vue-toastification';
	import composableRedirect from '@/composables/redirect';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import CashboxRepository from '@/repositories/CashboxRepository';

	export default {
		setup() {
			const { redirectBack } = composableRedirect();
			const { current_store } = composableStore();
			const { submitting, validationExternal, catchError } = composableForm();
			const toast = useToast();
			const user = computed(() => store.getters['auth/user']);

			const form = reactive({
				payouts: false,
				block: false
			});

			const submit = async () => {
				validationExternal.value = [];

				submitting.value = true;
				await CashboxRepository.config(current_store.value.id, form)
					.then(() => {
						toast.success(`La caja fue configurada correctamente.`);
						router.push({ name: 'store.cashbox.index', params: { store_id: current_store.value.id } });
					})
					.catch(catchError);
				submitting.value = false;
			}

			return { redirectBack, current_store, ...toRefs(form), user, validationExternal, submitting, submit };
		}
	}
</script>