<template>
	<div class="input-group input-group-sm shadow-none">
		<button type="button" class="btn" v-if="value > 0" v-bind:class="{'disabled': !can_decrement}" @click="decrease" aria-label="Quitar">-</button>
		<input type="text" class="form-control border-dark text-center fw-semibold" v-model="value" v-if="value > 0" readonly="">
		<button type="button" class="btn" v-bind:class="{'disabled': !can_increment}" @click="increase" aria-label="Agregar">+</button>
	</div>
</template>

<script>
	import { ref, computed, watch } from 'vue';

	export default {
		props: {
			min: {
				type: Number,
				default: 0
			},
			max: {
				type: Number,
				default: 0
			},
			remaining: {
				type: Number,
				default: 0
			},
			initial: {
				type: Number,
				default: 0
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		setup(props, {emit}) {
			const value = ref(props.initial);

			const decrease = () => {
				if(!can_decrement.value) return;
				if(props.disabled) return;

				value.value-= 1;
			}

			const increase = () => {
				if(!can_increment.value) return;
				if(props.disabled) return;

				value.value++;
			}

			const can_decrement = computed(() => {
				return value.value > 0;
			});

			const can_increment = computed(() => {
				return props.remaining > 0;
			});

			watch(value, (newValue) => emit('updated', newValue));

			return { value, decrease, increase, can_decrement, can_increment };
		}
	}
</script>

<style scoped>
	.input-group {
		width: 80px;
		margin-right: 0px;
		margin-left: auto;
		justify-content: flex-end;
	}

	.input-group input {
		height: 1.72em !important;
		padding: .1rem .30rem !important;
		border: 1px solid rgba(0,0,0,.25) !important;
	}

	.input-group button {
		width: 24px;
		height: 24px;
		padding: .094rem .30rem !important;
		font-weight: bolder;
		font-size: 14px;
		border: 1px solid rgba(0,0,0,.25) !important;
	}

	.input-group .form-control:read-only {
		background-color: #ffffff !important;
	}
</style>