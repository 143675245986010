<template>
	<!-- Navbar -->
	<header class="position-sticky top-0 z-2 bg-light border-bottom">
		<div class="container-fluid py-4 d-flex align-items-center">
			<div class="row g-0">
				<div class="col-12 col-md">
					<h1 class="h4 ls-tight">Códigos</h1>
				</div>
			</div>
		</div>
	</header>
</template>