<template>
	<teleport to="body">
		<div id="permission-notifications" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
			<div class="offcanvas-body container">
				<div class="row justify-content-center align-items-center">
					<div class="col-lg-8 text-center">
						<h2 class="fw-bolder text-dark mb-3">¿Querés recibir notificaciones?</h2>
						<p class="lead text-muted mb-8">
							Recibí notificaciones de cambios o cancelaciones en los pedidos.
						</p>
						<div class="d-grid gap-2 col-6 mx-auto">
							<button type="button" class="btn btn-dark mb-2" @click="accept">Aceptar</button>
							<a href="javascript:void(0)" class="text-muted" @click="dismiss">Ahora no</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
	import store from '@/store';
	import { useToast } from 'vue-toastification';
	import { useCookie } from 'vue-cookie-next';
	import { Offcanvas } from 'bootstrap';
	import * as PusherPushNotifications from '@pusher/push-notifications-web';

	export default {
		setup() {
			const { setCookie, isCookieAvailable } = useCookie();
			const toast = useToast();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const token = computed(() => store.getters['auth/token']);
			const user = computed(() => store.getters['auth/user']);
			const beamsClient = ref(null);
			const timeout = ref(null);

			const dismiss = async () => {
				setCookie('notifications-allowed', null, { expire: '10d' });
				permissionNotificationsHide();
			}

			const accept = async () => {
				permissionNotificationsHide();
				const tokenProvider = new PusherPushNotifications.TokenProvider({
					url: `${window.location.origin}/api/me/pusher/beams-auth`,
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Cache-Control': 'no-cache',
						'Authorization': `${token.value.token_type} ${token.value.access_token}`
					},
				});

				beamsClient.value.start()
					.then(() => beamsClient.value.setUserId(String(user.value.id), tokenProvider))
					.then(() => console.log('Successfully registered and subscribed!'))
					.catch(console.error);
			}

			const permissionNotifications = ref(null);
			onMounted(() => {
				permissionNotifications.value = new Offcanvas(document.getElementById('permission-notifications'));
				clientStart();
			});
			onBeforeUnmount(() => { if(timeout.value) clearTimeout(timeout.value); });
			const permissionNotificationsShow = () => permissionNotifications.value.show();
			const permissionNotificationsHide = () => permissionNotifications.value.hide();

			const previousShow = () => {
				if(!hasUser.value || isCookieAvailable('notifications-allowed')) return false;
				else if(document.querySelector('.modal.show') || document.querySelector('.offcanvas.show')) timeout.value = setTimeout(previousShow, 5000);
				else permissionNotificationsShow();
			}

			const clientStart = () => {
				if(hasUser.value && process.env.NODE_ENV === 'production') {
					window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
						beamsClient.value = new PusherPushNotifications.Client({ instanceId: process.env.VUE_APP_BEAMS_INSTANCE_ID, serviceWorkerRegistration: serviceWorkerRegistration });
						beamsClient.value.getRegistrationState()
							.then((state) => {
								let states = PusherPushNotifications.RegistrationState;

								switch(state) {
									case states.PERMISSION_DENIED:
										toast.error('Las notificaciones están desactivadas en tu dispositivo.');
										break;
									case states.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS:
									case states.PERMISSION_PROMPT_REQUIRED:
										timeout.value = setTimeout(previousShow, 5000);
										break;
								}
							});
					});
				}
			}

			watch(hasUser, clientStart);

			return { dismiss, accept };
		}
	}
</script>

<style scoped>
	.btn {
		border-radius: 25px !important;
	}
</style>