import * as validators from '@vuelidate/validators';
import { createI18nMessage } from '@vuelidate/validators';
import i18n from '@/helpers/i18n/init.js';

const { t } = i18n.global;
const withI18nMessage = createI18nMessage({ t });

export const required = withI18nMessage(validators.required);
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });
export const minValue = withI18nMessage(validators.minValue, { withArguments: true });
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true });
export const email = withI18nMessage(validators.email, { withArguments: true });
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });