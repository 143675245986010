<template>
	<div class="d-flex justify-content-between align-items-center">
		<div>
			<a href="javascript:void(0)" class="text-dark fw-bold stretched-link" @click="select">
				Pedido N°: {{ order.number }}
				<span class="ms-2 text-muted" v-if="order?.code">En {{ order?.code?.description }}</span>
			</a>
			<div class="hstack align-items-center gap-2 text-xs">
				<div>{{ typeInfo.title }}</div>
				<div class="vr opacity-20 my-1"></div>
				<div :class="`text-${getStatusClass}`"><i class="bi bi-coin me-1" v-if="order.status == 'PAYING'"></i>{{ getStatusTitle }}</div>
				<div class="vr opacity-20 my-1"></div>
				<div :class="{'text-dark': ((payments.length ?? 0) > 0), 'text-danger': ((payments.length ?? 0) == 0)}">{{ paymentsInfo }}</div>
			</div>
		</div>
		<div class="d-flex align-items-center text-end h6">
			{{ vueNumberFormat(order.total, $personalizer.currency.value) }}
		</div>
	</div>
</template>

<script>
	import { ref, computed, watch } from 'vue';
	import store from '@/store';
	import _ from 'lodash';
	import composableOrder from '@/composables/order';

	export default {
		emits: ['select'],
		props: {
			order: Object
		},
		setup(props, { emit }) {
			const orderGroup = computed(() => _.find(store.getters['orderGroups/list'], { id: Number(props.order.order_group_id) }));
			const orderRef = ref(props.order);
			const { PAYMENT_METHODS, reload, typeInfo, getStatusTitle, getStatusClass } = composableOrder(orderRef);

			const payments = computed(() => ((orderGroup.value) ? orderGroup.value.payments : props.order.payments));
			const paymentsInfo = computed(() => {
				var info = null;

				switch(payments.value.length ?? 0) {
					case 0:
						info = 'Sin pagos';
						break;
					case 1:
						info = (_.has(PAYMENT_METHODS, payments.value[0].method)) ? PAYMENT_METHODS[payments.value[0].method] : '-';
						break;
					default:
						info = 'Pagos múltiples';
						break;
				}

				return info;
			});

			watch(() => props.order, (newValue) => {
				if(newValue) reload(newValue);
			});

			const select = () => emit('select', props.order);

			return { orderGroup, payments, paymentsInfo, typeInfo, getStatusTitle, getStatusClass, select };
		}
	}
</script>