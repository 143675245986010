<template>
	<div class="d-flex align-items-center">
		<user-status-avatar :user="user" size="sm" />
		<div class="ps-2">
			<span class="h5 d-block mb-0">{{ user.fullname }}</span>
			<span class="text-sm text-muted d-block" @click="logout">{{ user.email }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			user: Object
		}
	}
</script>