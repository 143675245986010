<template>
	<div class="d-flex">
		<div class="pe-2 h5">{{ item.quantity }}x</div>
		<div class="flex-fill">
			<div class="d-flex h5">
				<div class="flex-fill">{{ item.name }}</div>
				<div class="flex-fill text-nowrap text-end ps-2">{{ vueNumberFormat(subtotal, $personalizer.currency.value) }}</div>
			</div>
			<div class="d-flex">
				<div class="flex-fill">
					<p class="text-muted">{{ additionalsAsString }}</p>
					<p class="text-warning">{{ item.comments }}</p>
				</div>
				<div class="flex-fill text-nowrap text-end ps-5">
					<small class="text-danger" v-if="item.discount > 0">{{ vueNumberFormat(discountPercentage(), { prefix: '', suffix: '% OFF' }) }}</small>
					<a href="javascript:void(0);" class="text-danger fw-semibold ms-3" @click="deleteItem" v-if="canDelete">Quitar</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';
	import _ from 'lodash';

	export default {
		props: {
			item: Object,
			index: Number,
			canDelete: Boolean
		},
		setup(props) {
			const additionalsAsString = computed(() => {
				var all = [];
				_.forEach(props.item.additionals, (additional) => { all.push(`${additional.name} x ${additional.quantity.toString()}`); });
				return all.join(', ');
			});

			const subtotal = computed(() => store.getters['cart/itemTotalByIndex'](props.index));

			const discountPercentage = () => {
				var item_total = props.item.unit_price * props.item.quantity;
				_.forEach(props.item.additionals, (additional) => { item_total+= additional.unit_price * additional.quantity * props.item.quantity; });

				return ('discount' in props.item) ? Math.round((props.item.discount * 100 / item_total) * 100) / 100 : 0;
			}

			const deleteItem = () => store.dispatch('cart/deleteItem', props.index);

			return { additionalsAsString, subtotal, discountPercentage, deleteItem };
		}
	}
</script>

<style scoped="">
</style>