<template>
	<teleport to="body">
		<div class="modal" id="ordergroup-modal-change" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content shadow-3" v-if="orderGroup">
					<div class="modal-header">
						<div>
							<h4 class="modal-title">Cambiar <u>{{ orderGroup.code.description }}</u></h4>
							<small class="d-block">Seleccionar el código por el que desea cambiarlo</small>
						</div>
					</div>
					<div class="modal-body">
						<div class="row g-4" v-if="codesFiltered.length > 0">
							<div class="col-12" v-for="code in codesFiltered" v-bind:key="code.id">
								<code-card :code="code" @select="submit" />
							</div>
						</div>
						<div class="row" v-if="codesFiltered.length == 0">
							<div class="col-12">
								<common-message-error text="No se encontraron códigos." />
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal" :disabled="submitting">Cancelar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, watch, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';
	import OrderGroupsRepository from '@/repositories/OrderGroupsRepository';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';

	export default {
		emits: ['selectChange'],
		props: {
			orderGroup: Object
		},
		setup(props, { emit }) {
			const { current_store, codes } = composableStore();
			const { submitting, catchError } = composableForm();
			const modalChangeView = ref(null);

			var codesFiltered = computed(() => _.filter(codes.value, (code) => {
				var orderGroupExists = _.find(store.getters['orderGroups/list'], (data) => { return (data.code_id == code.id && _.indexOf(['OPENED', 'PAYING'], data.status) !== -1); });

				return (code.group && !orderGroupExists);
			}));

			onMounted(() => {
				modalChangeView.value = new Modal(document.getElementById('ordergroup-modal-change'));

				document.getElementById('ordergroup-modal-change').addEventListener('hidden.bs.modal', () => {
					emit('selectChange', null);
				});
			});

			watch(() => props.orderGroup, (newValue) => {
				if(newValue) modalChangeView.value.show();
			});

			const submit = async (code) => {
				if(submitting.value) return false;

				submitting.value = true;
				await OrderGroupsRepository.change(current_store.value.id, props.orderGroup.id, { code_id: code.id })
					.then(() => modalChangeView.value.hide())
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { codesFiltered, submitting, submit };
		}
	}
</script>