<template>
	<div class="align-items-center">
		<div class="d-block">
			<h5 class="text-white" v-html="title" v-if="title"></h5>
			<p v-html="description" v-if="description"></p>
		</div>
		<div class="d-flex justify-content-between mt-2">
			<button type="button" class="btn btn-sm btn-neutral px-2 py-1">Cancelar</button>
			<button type="button" class="btn btn-sm btn-dark px-2 py-1" @click="destroy">Sí, eliminar</button>
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['destroy'],
		props: {
			title: String,
			description: String,
			target: Object
		},
		setup(props, { emit }) {
			const destroy = () => emit('destroy', props.target);

			return { destroy };
		}
	}
</script>