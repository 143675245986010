<template>
	<div class="card border border-2 shadow" :class="cardClass">
		<!-- Card Body -->
		<div class="card-body px-4 py-3 d-flex justify-content-between align-items-center">
			<div>
				<a href="javascript:void(0)" class="h5 fw-semibold link-dark stretched-link d-block" @click="select">{{ code.description }}</a>
				<small class="text-muted d-block" v-if="orderGroup?.status != 'OPENED' && orderGroup?.status != 'PAYING'">{{ code.code }}</small>
				<small class="text-muted d-block" v-if="orderGroup?.status == 'OPENED' && orderGroup.waiting_count == 0">Sin pendientes</small>
				<small class="text-danger d-block" v-if="orderGroup?.waiting_count == 1">1 Pendiente</small>
				<small class="text-danger d-block" v-if="orderGroup?.waiting_count > 1">{{ orderGroup.waiting_count }} Pendiente</small>
				<small class="text-info d-block" v-if="orderGroup?.status == 'PAYING' && orderGroup.waiting_count == 0">Pagando...</small>
			</div>
			<div class="text-dark fw-bold" v-if="orderGroup?.status == 'OPENED'">
				{{ vueNumberFormat(orderGroup.total, $personalizer.currency.value) }}
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['select'],
		props: {
			code: Object,
			orderGroups: Array
		},
		setup(props, { emit }) {
			const select = () => emit('select', props.code);
			const orderGroup = computed(() => _.find(props.orderGroups, (data) => { return (data.code.id == props.code.id && _.indexOf(['OPENED', 'PAYING'], data.status) !== -1); }));
			//const orderGroup = computed(() => _.find(props.orderGroups, { code: { id: Number(props.code.id) } }));
			const cardClass = computed(() => {
				if(orderGroup.value?.waiting_count > 0) return 'border-danger';
				else if(orderGroup.value?.status == 'PAYING') return 'border-info';
				else if(orderGroup.value?.status == 'OPENED') return 'border-success';
				else return 'border-lighter';
				//:class="{ 'border-success': (orderGroup?.status == 'OPENED' && orderGroup.waiting_count == 0), 'border-warning': (orderGroup?.status == 'OPENED' && orderGroup.waiting_count > 0), 'border-lighter': (orderGroup?.status != 'OPENED') }"
			});

			return { select, orderGroup, cardClass };
		}
	}
</script>