<template>
	<teleport to="body">
		<div class="modal" id="orders-modal-massive" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md">
				<div class="modal-content shadow-3">
					<div class="modal-header">
						<h4 class="modal-title">Modificación masiva de pedidos</h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body p-0">
						<div class="row">
							<div class="col-12">
								<div class="list-group list-group-flush">
									<div class="list-group-item p-0">
										<div class="d-flex px-5 py-3 border-start border-5">
											<div class="flex-fill">
												<div class="d-block h6 fw-semibold">Pedidos pendientes</div>
												<p class="text-sm text-muted" v-if="list_pending.length == 0">No hay pedidos pendientes</p>
												<p class="text-sm text-muted" v-if="list_pending.length == 1">Hay 1 pedido pendiente</p>
												<p class="text-sm text-muted" v-if="list_pending.length >= 2">Hay {{ list_pending.length }} pedidos pendientes</p>
											</div>
											<div class="ms-auto text-end align-self-center" v-if="list_pending.length > 0">
												<button type="button" class="btn btn-sm btn-warning" :disabled="submitting" @click="massiveAccept">Aceptar todos</button>
											</div>
										</div>
									</div>
									<div class="list-group-item p-0" v-if="typeLocal || list_local.length > 0">
										<div class="d-flex px-5 py-3 border-start border-5 border-warning">
											<div class="flex-fill">
												<div class="d-block h6 fw-semibold">Pedidos en local</div>
												<p class="text-sm text-muted" v-if="list_local.length == 0">No hay pedidos para finalizar</p>
												<p class="text-sm text-muted" v-if="list_local.length == 1">Hay 1 pedido para finalizar</p>
												<p class="text-sm text-muted" v-if="list_local.length >= 2">Hay {{ list_local.length }} pedidos para finalizar</p>
											</div>
											<div class="ms-auto text-end align-self-center" v-if="list_local.length > 0">
												<button type="button" class="btn btn-sm btn-primary" :disabled="submitting" @click="massiveFinishLocal(list_local, 'Los pedidos en local fueron finalizados.')">Finalizar todos</button>
											</div>
										</div>
									</div>
									<div class="list-group-item p-0" v-if="typeTakeaway || list_takeaway.length > 0">
										<div class="d-flex px-5 py-3 border-start border-5 border-dark">
											<div class="flex-fill">
												<div class="d-block h6 fw-semibold">Pedidos para take away</div>
												<p class="text-sm text-muted" v-if="list_takeaway.length == 0">No hay pedidos para finalizar</p>
												<p class="text-sm text-muted" v-if="list_takeaway.length == 1">Hay 1 pedido para finalizar</p>
												<p class="text-sm text-muted" v-if="list_takeaway.length >= 2">Hay {{ list_takeaway.length }} pedidos para finalizar</p>
											</div>
											<div class="ms-auto text-end align-self-center" v-if="list_takeaway.length > 0">
												<button type="button" class="btn btn-sm btn-primary" :disabled="submitting" @click="massiveFinishLocal(list_takeaway, 'Los pedidos para take away fueron finalizados.')">Finalizar todos</button>
											</div>
										</div>
									</div>
									<div class="list-group-item p-0" v-if="typeDelivery || list_delivery.length > 0">
										<div class="d-flex px-5 py-3 border-start border-5 border-danger">
											<div class="flex-fill">
												<div class="d-block h6 fw-semibold">Pedidos para delivery</div>
												<p class="text-sm text-muted" v-if="list_delivery.length == 0">No hay pedidos para finalizar</p>
												<p class="text-sm text-muted" v-if="list_delivery.length == 1">Hay 1 pedido para finalizar</p>
												<p class="text-sm text-muted" v-if="list_delivery.length >= 2">Hay {{ list_delivery.length }} pedidos para finalizar</p>
											</div>
											<div class="ms-auto text-end align-self-center" v-if="list_delivery.length > 0">
												<button type="button" class="btn btn-sm btn-primary" :disabled="submitting" @click="massiveFinishLocal(list_delivery, 'Los pedidos para delivery fueron finalizados.')">Finalizar todos</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { computed, onMounted } from 'vue';
	import { useToast } from 'vue-toastification';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';
	import composableStore from '@/composables/store';
	import OrdersRepository from '@/repositories/OrdersRepository';
	import composableForm from '@/composables/form';

	export default {
		props: {
			orders: Array
		},
		setup(props) {
			const { current_store, status } = composableStore();
			const { submitting, catchError } = composableForm();
			const toast = useToast();

			const services = computed(() => (status.value?.type?.inmediate?.services ?? []));
			const typeLocal = computed(() => ('LOCAL' in services.value));
			const typeTakeaway = computed(() => ('TAKEAWAY' in services.value));
			const typeDelivery = computed(() => ('DELIVERY' in services.value));

			const list_pending = computed(() => _.filter(props.orders, { status: 'PENDING' }));
			const list_local = computed(() => _.filter(props.orders, (order) => { return (order.type == 'LOCAL' && _.indexOf(['ACCEPTED', 'DELIVER'], order.status) !== -1); }));
			const list_takeaway = computed(() => _.filter(props.orders, (order) => { return (order.type == 'TAKEAWAY' && _.indexOf(['ACCEPTED', 'DELIVER'], order.status) !== -1); }));
			const list_delivery = computed(() => _.filter(props.orders, (order) => { return (order.type == 'DELIVERY' && _.indexOf(['ACCEPTED', 'DELIVER'], order.status) !== -1); }));

			const massiveAccept = async () => {
				var data = {
					status: 'ACCEPTED',
					ids: _.map(list_pending.value, 'id')
				}

				if(submitting.value) return false;

				submitting.value = true;
				await OrdersRepository.updateMassive(current_store.value.id, data)
					.then(() => toast.success(`Los pedidos fueron aceptados.`))
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			const massiveFinishLocal = async (list, message) => {
				var data = {
					status: 'COMPLETE',
					ids: _.map(list, 'id')
				}

				if(submitting.value) return false;

				submitting.value = true;
				await OrdersRepository.updateMassive(current_store.value.id, data)
					.then(() => toast.success(message))
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			onMounted(() => new Modal(document.getElementById('orders-modal-massive')));

			return { typeLocal, typeTakeaway, typeDelivery, list_pending, list_local, list_takeaway, list_delivery, submitting, massiveAccept, massiveFinishLocal };
		}
	}
</script>