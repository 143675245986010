import { ref, computed, watch } from 'vue';
import store from '@/store';
import _ from 'lodash';
import composableStore from '@/composables/store';
import { printCommander, printRejected, printSummary } from '@/helpers/Printpoint';

export default function() {
	const { current_store } = composableStore();

	const sound = computed(() => store.getters['config/sound']);
	const orders = computed(() => store.getters['orders/list']);
	const printers = computed(() => store.getters['config/printers'](current_store.value.id));
	const printings = computed(() => store.getters['config/printings']);
	const printeds = computed(() => store.getters['config/printeds']);
	const interval = ref(null);
	const audio = new Audio(require(`@/assets/sound/bell.mp3`));

	const checkSound = (newPending = false) => {
		if(interval.value) {
			clearInterval(interval.value);
			interval.value = null;
		}

		switch(sound.value) {
			case 'always':
				if(_.filter(orders.value, { status: 'PENDING' }).length > 0) {
					audio.play();
					interval.value = setInterval(() => audio.play(), 10000);
				}
				break;
			case 'once':
				if(newPending) audio.play();
				break;
		}
	}

	const checkCommander = () => {
		var type = 'commander',
			printer = printers.value[type];

		if(!printer || printer?.method != 'local' || !printer?.automatic) return;

		_.forEach(_.filter(orders.value, (order) => { return (!order?.synchronizing && _.indexOf(['ACCEPTED', 'DELIVER'], order.status) !== -1); }), (order) => {
			var printed = _.find(printeds.value, { id: order.id });
			var printing = _.find(printings.value, { id: order.id });

			if(_.indexOf((printed?.types ?? []), type) === -1 && _.indexOf((printing?.types ?? []), type) === -1) {
				store.dispatch('config/setPrinting', { id: order.id, type: type });
				setTimeout(() => printCommander(order.id), 1500);
			}
		});
	}

	const checkRejecteds = () => {
		var type = 'rejected',
			printer = printers.value['commander'];

		if(!printer || printer?.method != 'local') return;

		_.forEach(_.filter(orders.value, (order) => { return (!order?.synchronizing && order.status == 'REJECTED'); }), (order) => {
			var printed = _.find(printeds.value, { id: order.id });
			var printing = _.find(printings.value, { id: order.id });

			if(_.indexOf((printed?.types ?? []), 'commander') !== -1 && _.indexOf((printed?.types ?? []), type) === -1 && _.indexOf((printing?.types ?? []), type) === -1) {
				store.dispatch('config/setPrinting', { id: order.id, type: type });
				setTimeout(() => printRejected(order.id), 1500);
			}
		});
	}

	const checkSummary = () => {
		var type = 'summary',
			printer = printers.value[type];

		if(printer?.method != 'local' || !printer?.automatic) return;

		_.forEach(_.filter(orders.value, (order) => { return (!order?.synchronizing && _.indexOf(['DELIVER', 'COMPLETE'], order.status) !== -1); }), (order) => {
			var printed = _.find(printeds.value, { id: order.id });
			var printing = _.find(printings.value, { id: order.id });

			if(!order?.code?.group && _.indexOf((printed?.types ?? []), type) === -1 && _.indexOf((printing?.types ?? []), type) === -1) {
				store.dispatch('config/setPrinting', { id: order.id, type: type });
				setTimeout(() => printSummary('order', order.id), 1500);
			}
		});
	}

	checkSound();
	watch(sound, () => checkSound());
	watch(orders, (newValue, oldValue) => {
		var newOrders = _.cloneDeep(_.filter(newValue, { status: 'PENDING' })),
			oldOrders = _.cloneDeep(_.filter(oldValue, { status: 'PENDING' })),
			newPending = (_.differenceBy(newOrders, oldOrders, 'id').length > 0);

		checkSound(newPending);
		checkCommander();
		checkRejecteds();
		checkSummary();
	});
}
