<template>
	<div class="d-flex align-items-center position-relative">
		<div class="col-auto">
			<a @click="doRedirect" class="btn btn-sm btn-light stretched-link"><i class="bi bi-chevron-left"></i></a>
		</div>
		<div class="ms-3">
			<!-- Surtitle -->
			<h6 class="text-sm fw-semibold text-muted">Volver</h6>
			<h4 class="h5 ls-tight" v-show="!!description">{{ description }}</h4>
			<Skeletor width="100" height="19" class="h4 ls-tight" v-show="!description" />
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import router from '@/router';
	import composableRedirect from '@/composables/redirect';

	export default {
		props: {
			redirect: Object
		},
		setup(props) {
			const { redirectBackInfo, redirectBack } = composableRedirect();

			const description = computed(() => {
				if(props.redirect) return props.redirect.description;
				else return redirectBackInfo.value.description;
			});

			const doRedirect = () => {
				if(props.redirect) router.push({ path: props.redirect.path });
				else redirectBack();
			}

			return { description, doRedirect };
		}
	}
</script>