import Repository from './Repository';

const resource = '/store';

export default {
	index(store_id) {
		return Repository.get(`${resource}/${store_id}/cashbox`);
	},
	config(store_id, payload) {
		return Repository.post(`${resource}/${store_id}/cashbox/config`, payload);
	},
	store(store_id, payload) {
		return Repository.post(`${resource}/${store_id}/cashbox`, payload);
	},
	update(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/cashbox/${id}`, payload);
	},
	print(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/cashbox/${id}/print`, payload);
	}
}