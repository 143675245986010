<template>
	<div class="avatar-parent-child" v-show="imageReady">
		<img :src="avatar" @load="imageReady = true" alt="Avatar" class="avatar rounded-circle" :class="`avatar-${size}`">
		<span class="avatar-child avatar-badge bg-success"></span>
	</div>
	<Skeletor :width="imageSize" :height="imageSize" class="avatar rounded-circle text-white ms-0" :class="`avatar-${size}`" v-show="!imageReady" />
</template>

<script>
	import { ref, computed } from 'vue';
	import store from '@/store';

	export default {
		props: {
			user: Object,
			size: String
		},
		setup(props) {
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const imageReady = ref(false);

			const imageSize = computed(() => {
				if(props.size == 'xs') return 28;
				else if(props.size == 'sm') return 36;
				else if(props.size == 'lg') return 56;
				else return 200;
			});

			const avatar = computed(() => {
				if(user.value.avatar) return user.value.avatar;
				return require('@/assets/img/icons/avatar.jpg');
			});

			return { hasUser, avatar, imageReady, imageSize };
		}
	}
</script>