<template>
	<div class="row" v-if="!obtained">
		<common-message-loading class="col-12" text="Cargando tiendas" />
	</div>
	<div class="row" v-if="obtained && list.length > 0">
		<div class="col-12 col-md-6 col-lg-4 mb-5" v-for="store in list" v-bind:key="store.id">
			<store-card :store="store" />
		</div>
	</div>
	<div class="row" v-if="obtained && list.length == 0">
		<common-message-error class="col-12" text="No se encontraron tiendas para tu ubicación." />
	</div>
</template>

<script>
	import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
	import store from '@/store';
	import StoresRepository from '@/repositories/StoresRepository';
	import composableForm from '@/composables/form';
	import moment from 'moment';
	import _ from 'lodash';

	export default {
		setup() {
			const address = computed(() => store.getters['location/address']);
			const obtained = computed(() => store.getters['stores/obtained']);
			const stores = computed(() => store.getters['stores/list']);
			const expires_in = computed(() => store.getters['stores/expires_in']);
			const { catchError } = composableForm();
			const timeout = ref(null);

			const list = computed(() => {
				if(stores.value.length > 0) return _.orderBy(stores.value, ['open', 'random'], ['desc', 'asc']);
				else return [];
			});

			const getStores = async () => {
				if(obtained.value) {
					if(stores.value.length > 0) {
						await StoresRepository.getStatus(_.map(stores.value, 'id'))
							.then((response) => {
								store.dispatch('stores/setStatus', response.data);
							}).catch((e) => {
								catchError(e);
							});
					}
				}else{
					await StoresRepository.getList({ type: 'by_coordinates', geolocation: address.value.geolocation })
						.then((response) => {
							store.dispatch('stores/setStores', response.data);
						}).catch((e) => {
							catchError(e);
						});
				}
			}

			const checkStores = async () => {
				if(!obtained.value || moment().diff(expires_in.value, 'seconds') >= 0) await getStores();
				timeout.value = setTimeout(checkStores, (expires_in.value) ? Math.abs(moment().diff(expires_in.value, 'milliseconds')) : 20000);
			}

			onMounted(checkStores);

			onBeforeUnmount(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			return { obtained, list };
		}
	}
</script>