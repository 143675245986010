<template>
	<div class="align-items-center">
		<div class="d-block" v-html="timerText"></div>
		<div class="hstack gap-2 mt-2">
			<button type="button" class="btn btn-sm btn-neutral text-uppercase px-2 py-1" @click="addMinutes(15)" :disabled="submitting">+15 Minutos</button>
			<button type="button" class="btn btn-sm btn-neutral text-uppercase px-2 py-1" @click="addMinutes(60)" :disabled="submitting">+1 Hora</button>
			<button type="button" class="btn btn-sm btn-warning text-uppercase px-2 py-1" @click="addMinutes(0)" :disabled="submitting">Aceptar</button>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted, onUnmounted } from 'vue';
	import moment from 'moment';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import StoreRepository from '@/repositories/StoreRepository';

	export default {
		props: {
			data: Object
		},
		setup(props) {
			const { current_store } = composableStore();
			const { submitting, catchError } = composableForm();
			const interval = ref(null);
			const diff_will_close = ref(0);

			const timerText = computed(() => {
				return (diff_will_close.value < 0) ? `Cerrando la tienda...` : `La tienda se cerrará en <b>${moment.duration(diff_will_close.value).locale('es').humanize(false)}</b>.`;
			});

			const updateDiff = () => {
				diff_will_close.value = moment().diff(props.data.autoclose) * -1;
			}

			const addMinutes = (minutes) => {
				submitting.value = true;

				StoreRepository.updateAutoclose(current_store.value.id, { minutes: minutes }).catch(() => {
					catchError();
					submitting.value = false;
				});
			}

			onMounted(() => {
				updateDiff();
				interval.value = setInterval(updateDiff, (20 * 1000));
			});

			onUnmounted(() => {
				if(interval.value) clearInterval(interval.value);
			});

			return { submitting, timerText, addMinutes };
		}
	}
</script>