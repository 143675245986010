<template>
	<div class="hstack gap-3" v-if="hasUser">
		<user-status-avatar :user="user" size="sm" />
		<div class="overflow-hidden">
			<div class="h4 text-truncate">{{ user.fullname }}</div>
			<a href="javascript:void(0)" class="text-sm text-muted d-block text-truncate" @click="logout">Cerrar sesión</a>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);

			const logout = () => store.dispatch('auth/logout');

			return { hasUser, user, logout };
		}
	}
</script>