<template>
	<teleport to="body">
		<div class="modal" :id="`config-printers-${type}`" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md modal-dialog-centered">
				<div class="modal-content shadow-3">
					<div class="modal-header d-block">
						<h4 class="modal-title">Configuración de la comandera</h4>
						<small class="d-block text-muted">Seleccioná el tipo de impresión</small>
					</div>
					<div class="modal-body py-0">
						<config-printers-list :type="type" :method="form.method" :value="form.value" :status="status" :printers="printers" :printpoints="printpoints" :printpoint="printpoint" @select="select" />
						<div class="row border-top" v-if="form.method == 'local'">
							<div class="col-12 py-5 border-top-1 hstack gap-1 align-items-stretch">
								<div class="form-check form-switch">
									<input type="checkbox" :id="`automatic_${type}`" v-model="form.automatic" class="form-check-input">
								</div>
								<label :for="`automatic_${type}`">
									<h5>Impresión automática</h5>
									<p class="text-sm text-muted">Se imprimirá cuando el pedido esté <b>aceptado</b> o <b>por entregar</b>.</p>
								</label>
							</div>
							<div class="col-12 pb-3" v-for="data in assemblies" v-bind:key="data.id">
								<label :for="`assembly_${data.id}`" class="form-label">Punto <b>{{ data.name }}</b></label>
								<select :id="`assembly_${data.id}`" v-model="form.assemblies[data.id]" class="form-select">
									<option value="">Sin seleccionar</option>
									<option v-for="(name, index) in printers" v-bind:key="index" v-bind:value="name">{{ name }}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="modal-footer d-flex justify-content-between">
						<div>
							<button type="button" class="btn btn-sm btn-danger" :disabled="submitting" @click="deletePrinter" v-if="form.method">Eliminar</button>
						</div>
						<div class="hstack gap-3">
							<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal" :disabled="submitting">Cancelar</button>
							<button type="button" class="btn btn-sm btn-primary" :disabled="submitting" @click="submit">Guardar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, reactive, computed, watch, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';

	export default {
		props: {
			status: String,
			printers: Array,
			printpoints: Array,
			assemblies: Array
		},
		setup(props) {
			const type = 'commander';
			const { current_store } = composableStore();
			const { submitting, catchError } = composableForm();
			const printpoint = computed(() => store.getters['config/printpoint'](current_store.value.id));
			const printers_store = computed(() => store.getters['config/printers'](current_store.value.id));

			const modalEdit = ref(null);
			onMounted(() => {
				modalEdit.value = new Modal(document.getElementById(`config-printers-${type}`));

				document.getElementById(`config-printers-${type}`).addEventListener('shown.bs.modal', () => {
					if(type in printers_store.value) {
						form.method = printers_store.value.commander.method;
						form.value = printers_store.value.commander.value;
						form.automatic = printers_store.value.commander.automatic;
						_.forEach(printers_store.value.commander.assemblies, (value, key) => form.assemblies[key] = value);
					}else{
						form.method = null;
						form.value = null;
						form.automatic = false;
						form.assemblies = {};

						_.forEach(props.assemblies, (assembly) => form.assemblies[assembly.id] = '');
					}
				});
			});

			const form = reactive({
				method: null,
				value: null,
				automatic: false,
				assemblies: {}
			});

			const select = (method, value) => {
				form.method = method;
				form.value = value;
			}

			const submit = async () => {
				submitting.value = true;
				await store.dispatch('config/setPrinter', { store_id: current_store.value.id, type: type, config: form }).then(() => {
					modalEdit.value.hide();
				}).catch(catchError);
				submitting.value = false;
			}

			const deletePrinter = async () => {
				submitting.value = true;
				await store.dispatch('config/deletePrinter', { store_id: current_store.value.id, type: type }).then(() => {
					modalEdit.value.hide();
				}).catch(catchError);
				submitting.value = false;
			}

			watch(() => props.assemblies, (newValue) => {
				_.forEach(newValue, (assembly) => {
					if(!(assembly.id in form.assemblies)) form.assemblies[assembly.id] = '';
				});
			});

			return { type, form, select, printpoint, submitting, submit, deletePrinter, current_store };
		}
	}
</script>