<template>
	<div id="payment-menu-cash" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header container container-offcanvas">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">¿Con cuánto pagará?</h5>
			<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<form class="offcanvas-body container container-offcanvas pt-0" @submit.prevent="submit">
			<div class="mb-7">
				<div class="py-4 cursor-pointer" @click="select('exact')">
					<div class="form-check">
						<input type="radio" v-model="option" value="exact" class="form-check-input">
						<div class="form-check-label text-dark d-block ms-1">
							<div class="d-flex justify-content-between">
								<b>Importe exacto</b>
								<span>Con {{ vueNumberFormat(total, $personalizer.currency.value) }}</span>
							</div>
						</div>
					</div>
				</div>
				<hr class="my-0" />
				<div class="py-4 cursor-pointer" @click="select('other')">
					<div class="form-check">
						<input type="radio" v-model="option" value="other" class="form-check-input">
						<div class="form-check-label text-dark d-block ms-1">
							<div class="d-flex justify-content-between">
								<b>Otro importe</b>
								<span>Superior a {{ vueNumberFormat(total, $personalizer.currency.value) }}</span>
							</div>
							<div class="mt-4" v-if="option == 'other'">
								<VueNumberFormat :options="Object.assign({ acceptNegative: false }, $personalizer.currency.value)" v-model:value="value" class="form-control" v-focus></VueNumberFormat>
								<div class="text-danger mt-1" v-for="error of validation.value.$errors" :key="error.$uid">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="validation.option.$errors.length > 0">
					<hr class="my-0" />
					<div class="text-danger px-8 my-3" v-for="error of validation.option.$errors" :key="error.$uid">{{ error.$message }}</div>
				</div>
			</div>
			<div class="d-grid gap-4 d-md-flex justify-content-md-end">
				<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="offcanvas">Cancelar</button>
				<button type="submit" class="btn btn-sm btn-dark" :disabled="submitting">Seleccionar</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { reactive, watch, toRefs } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import composableForm from '@/composables/form';

	export default {
		emits: ['setCash'],
		props: {
			total: Number
		},
		setup(props, { emit }) {
			const { submitting } = composableForm();

			const form = reactive({
				option: null,
				value: 0,
			});

			watch(() => form.option, () => form.value = 0);

			const validateOption = (value) => {
				return (value != null);
			}

			const validateValue = (value) => {
				if(form.option == 'other') return (value > props.total);
				else return true;
			}

			const validation = useVuelidate({
				option: {
					validateOption: helpers.withMessage('Debe seleccionar un tipo', validateOption)
				},
				value: {
					validateValue: helpers.withMessage(`Ingresá un importe superior a $ ${props.total}`, validateValue)
				}
			}, form);

			const submit = async () => {
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				emit('setCash', {
					id: null,
					method: 'cash',
					amount: null,
					option: form.option,
					value: (form.option == 'other') ? form.value : null,
					can_modify: true
				});
				submitting.value = false;
			}

			const select = async (option) => form.option = option;

			return { ...toRefs(form), validation, submitting, submit, select };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>