<template>
	<div id="menu-cash" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header container container-offcanvas">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">¿Con cuánto pagará?</h5>
			<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<form class="offcanvas-body container container-offcanvas pt-0" @submit.prevent="submit">
			<div class="mb-7">
				<div class="py-4 cursor-pointer" @click="select('exact')">
					<div class="form-check">
						<input type="radio" v-model="type" value="exact" class="form-check-input">
						<div class="form-check-label text-dark d-block ms-1">
							<div class="d-flex justify-content-between">
								<b>Importe exacto</b>
								<span>Con {{ vueNumberFormat(total, $personalizer.currency.value) }}</span>
							</div>
						</div>
					</div>
				</div>
				<hr class="my-0" />
				<div class="py-4 cursor-pointer" @click="select('other')">
					<div class="form-check">
						<input type="radio" v-model="type" value="other" class="form-check-input">
						<div class="form-check-label text-dark d-block ms-1">
							<div class="d-flex justify-content-between">
								<b>Otro importe</b>
								<span>Superior a {{ vueNumberFormat(total, $personalizer.currency.value) }}</span>
							</div>
							<div class="mt-4" v-if="type == 'other'">
								<VueNumberFormat :options="Object.assign({ acceptNegative: false }, $personalizer.currency.value)" v-model:value="amount" class="form-control" v-focus></VueNumberFormat>
								<div class="text-danger mt-1" v-for="error of validation.amount.$errors" :key="error.$uid">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="validation.type.$errors.length > 0">
					<hr class="my-0" />
					<div class="text-danger px-8 my-3" v-for="error of validation.type.$errors" :key="error.$uid">{{ error.$message }}</div>
				</div>
			</div>
			<div class="d-grid gap-4 d-md-flex justify-content-md-end">
				<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="offcanvas">Cancelar</button>
				<button type="submit" class="btn btn-sm btn-dark" :disabled="submitting">Seleccionar</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { reactive, computed, watch, toRefs } from 'vue';
	import store from '@/store';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import composableForm from '@/composables/form';

	export default {
		emits: ['setCash'],
		setup(props, { emit }) {
			const { submitting } = composableForm();
			const total = computed(() => store.getters['cart/total']);

			const form = reactive({
				type: null,
				amount: 0,
			});

			watch(() => form.type, () => form.amount = 0);

			const validateType = (value) => {
				return (value != null);
			}

			const validateAmount = (value) => {
				if(form.type == 'other') return (value > total.value);
				else return true;
			}

			const validation = useVuelidate({
				type: {
					validateType: helpers.withMessage('Debe seleccionar un tipo', validateType)
				},
				amount: {
					validateAmount: helpers.withMessage(`Ingresá un importe superior a $ ${total.value}`, validateAmount)
				}
			}, form);

			const submit = async () => {
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				emit('setCash', {
					option: 'cash',
					type: form.type,
					amount: (form.type == 'other') ? form.amount : null
				});
				submitting.value = false;
			}

			const select = async (type) => form.type = type;

			return { ...toRefs(form), total, validation, submitting, submit, select };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>