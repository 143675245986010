<template>
	<router-view v-slot="{ Component }">
		<component :is="Component" />
	</router-view>
	<permissions-install />
	<permissions-notifications />
</template>

<style>
	html,
	body {
		height: 100%;
		background-color: var(--bs-body-bg) !important;
	}

	#app {
		flex-direction: column !important;
		display: flex !important;
		width: 100%;
		height: 100%;
	}

	.route-enter-from { opacity: 0; }
	.route-enter-to { opacity: 1; }

	.route-leave-from { opacity: 1; }
	.route-leave-to { opacity: 0; }

	.route-enter-active, .route-leave-active { transition: all .2s ease; }
</style>