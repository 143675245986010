<template>
	<div class="card border">
		<div class="card-body d-flex align-items-center justify-content-between p-5">
			<a href="javascript:void(0);" class="h4 modal-title text-reset stretched-link" @click="redirectCode"><i class="bi bi-qr-code pe-2"></i>Códigos</a>
			<div>
				<button type="button" class="btn btn-neutral btn-sm btn-square me-3 stretched-link position-relative d-inline-block d-md-none" @click="redirectCamera"><i class="bi bi-camera text-md"></i></button>
				<button type="button" class="btn btn-neutral btn-sm btn-square"><i class="bi bi-chevron-right text-md"></i></button>
			</div>
		</div>
	</div>
</template>

<script>
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { redirectCode, redirectCamera } = composableCart();

			return { redirectCode, redirectCamera };
		}
	}
</script>