import _ from 'lodash';
import composableForm from '@/composables/form';
import PrintpointRepository from '@/repositories/PrintpointRepository';

const { catchError } = composableForm();

export default {
	namespaced: true,
	state: {
		sound: null,
		guide: false,
		printpoints: {},
		printers: {},
		printings: [],
		printeds: []
	},
	getters: {
		sound: (state) => {
			return state.sound;
		},
		guide: (state) => {
			return state.guide;
		},
		printpoint: (state) => (store_id) => {
			return state.printpoints[store_id] ?? null;
		},
		printers: (state) => (store_id) => {
			return state.printers[store_id] ?? {};
		},
		printings: (state) => {
			return state.printings;
		},
		printeds: (state) => {
			return state.printeds;
		}
	},
	actions: {
		setSound({ commit }, sound) {
			commit('setSound', sound);
		},
		setGuide({ commit }, guide) {
			commit('setGuide', guide);
		},
		async setPrintpoint({ state, rootGetters, commit }, printpoint) {
			var current_store = rootGetters['store/info'];
			var printpoints = _.cloneDeep(state.printpoints);

			if(printpoint.id) {
				await PrintpointRepository.update(current_store.id, printpoint.id, printpoint).catch((response) => {
					return Promise.reject(response);
				});
			}else{
				await PrintpointRepository.store(current_store.id, printpoint).then((response) => printpoint.id = response.data.id).catch((response) => {
					catchError(response);
					return Promise.reject();
				});
			}

			printpoints[current_store.id] = printpoint;
			commit('setPrintpoints', printpoints);
		},
		async setPrinter({ state, dispatch, commit }, data) {
			var printers = _.cloneDeep(state.printers),
				printers_store = printers[data.store_id] ?? {},
				printpoint = (data.store_id in state.printpoints) ? _.cloneDeep(state.printpoints[data.store_id]) : false;

			printers_store[data.type] = data.config;
			printers[data.store_id] = printers_store;

			if(printpoint) {
				switch(data.config.method) {
					case 'local':
						if(printpoint.types.indexOf(data.type) === -1) printpoint.types.push(data.type);
						break;
					case 'remote':
						var index = printpoint.types.indexOf(data.type);
						if(index !== -1) printpoint.types.splice(index, 1);
						break;
				}

				await dispatch('setPrintpoint', printpoint).catch((response) => {
					return Promise.reject(response);
				});
			}

			commit('setPrinters', printers);
		},
		async deletePrinter({ state, dispatch, commit }, data) {
			var printers = _.cloneDeep(state.printers),
				printers_store = printers[data.store_id] ?? {},
				printpoint = (data.store_id in state.printpoints) ? _.cloneDeep(state.printpoints[data.store_id]) : false;

			if(data.type in printers_store) delete printers_store[data.type];
			printers[data.store_id] = printers_store;

			if(printpoint) {
				var index = printpoint.types.indexOf(data.type);
				if(index !== -1) printpoint.types.splice(index, 1);

				await dispatch('setPrintpoint', printpoint).catch((response) => {
					return Promise.reject(response);
				});
			}

			commit('setPrinters', printers);
		},
		setPrinting({ state, commit }, data) {
			var printings = _.cloneDeep(state.printings),
				index = _.findIndex(printings, { 'id': data.id }),
				printing = (index !== -1) ? printings[index] : { id: data.id, types: [] };

			if(printing.types.indexOf(data.type) === -1) printing.types.push(data.type);

			if(index !== -1) printings[index] = printing;
			else printings.push(printing);

			if(printings.length > 1000) printings.shift();
			commit('setPrintings', printings);
		},
		setPrinted({ state, commit }, data) {
			var printeds = _.cloneDeep(state.printeds),
				index = _.findIndex(printeds, { 'id': data.id }),
				printed = (index !== -1) ? printeds[index] : { id: data.id, types: [], printers: [] };

			if(printed.types.indexOf(data.type) === -1) printed.types.push(data.type);
			_.forEach(data.printers, (printer_name) => { if(printed.printers.indexOf(printer_name) === -1) printed.printers.push(printer_name); });

			if(index !== -1) printeds[index] = printed;
			else printeds.push(printed);

			if(printeds.length > 1000) printeds.shift();
			commit('setPrinteds', printeds);
		}
	},
	mutations: {
		setSound(state, sound) {
			state.sound = sound;
		},
		setGuide(state, guide) {
			state.guide = guide;
		},
		setPrintpoints(state, printpoints) {
			state.printpoints = printpoints;
		},
		setPrinters(state, printers) {
			state.printers = printers;
		},
		setPrintings(state, printings) {
			state.printings = printings;
		},
		setPrinteds(state, printeds) {
			state.printeds = printeds;
		}
	}
}