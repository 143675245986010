import Repository from './Repository';

const resource = '/store';

export default {
	get(store_id, id) {
		return Repository.get(`${resource}/${store_id}/orderGroups/${id}`);
	},
	store(store_id, payload) {
		return Repository.post(`${resource}/${store_id}/orderGroups`, payload);
	},
	update(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orderGroups/${id}`, payload);
	},
	change(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orderGroups/${id}/change`, payload);
	},
	destroy(store_id, id) {
		return Repository.delete(`${resource}/${store_id}/orderGroups/${id}`);
	},
	paying(store_id, id) {
		return Repository.get(`${resource}/${store_id}/orderGroups/${id}/paying`);
	},
	payingCancel(store_id, id) {
		return Repository.delete(`${resource}/${store_id}/orderGroups/${id}/paying`);
	},
	invoice(store_id, id, payload) {
		return Repository.post(`${resource}/${store_id}/orderGroups/${id}/billing/invoice`, payload);
	},
	printSummary(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orderGroups/${id}/print/summary`, payload);
	},
	printInvoice(store_id, id, payload) {
		return Repository.patch(`${resource}/${store_id}/orderGroups/${id}/print/invoice`, payload);
	}
}