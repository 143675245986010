<template>
	<div class="flex-shrink-0 flex-fill d-flex flex-column min-h-full">
		<div class="px-3">
			<span class="badge badge-lg badge-dot text-heading fw-semibold">
				<i :class="`bg-${theme}`"></i>{{ title }}
			</span>
		</div>
		<div class="flex-grow-1 overflow-y-auto px-3 vstack gap-3 py-3 mt-2" :class="backgroundClass" @drop="drop" @dragover="dragover" @dragleave="dragleave">
			<orders-kanban-card v-for="order in list" :key="order.id" :order="order" @setdrag="setdrag" @select="select" />
			<!--<div class="alert opacity-40 alert-warning alert-dismissible" v-if="list.length == 0">-->
			<div class="border border-2 border-dashed border-warning rounded-2 p-5 text-warning fw-bold text-center opacity-50" v-if="list.length == 0">
				Sin pedidos {{ title.toLowerCase() }}
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import store from '@/store';
	import _ from 'lodash';

	export default {
		emits: ['setdrag', 'select'],
		props: {
			orders: Array,
			filters: Object,
			status: String,
			theme: String,
			title: String
		},
		setup(props, { emit }) {
			const list = computed(() => {
				var filters = { status: props.status };
				if(props.filters.type) filters.type = props.filters.type;

				return _.sortBy(_.filter(props.orders, filters), ['ready_by', 'id']);
			});

			const backgroundClass = computed(() => (hover.value) ? `rounded bg-secondary bg-opacity-50` : '');
			const hover = ref(false);

			const drop = (e) => {
				e.preventDefault();
				hover.value = false;
				setdrag(false);
				var id = e.dataTransfer.getData('id');
				var status = e.dataTransfer.getData('status');

				if(status != props.status) store.dispatch('orders/updateStatus', { id: id, newStatus: props.status });
			}

			const dragover = (e) => {
				e.preventDefault();
				hover.value = true;
			}

			const dragleave = (e) => {
				e.preventDefault();
				hover.value = false;
			}

			const setdrag = (data) => emit('setdrag', data);
			const select = (data) => emit('select', data);

			return { list, drop, hover, dragover, dragleave, setdrag, select, backgroundClass }
		}
	}
</script>