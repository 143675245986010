<template>
	<paying-header :submitting="submitting" />
	<!-- Main -->
	<main class="py-5 flex-grow-1">
		<layouts-loading text="Obteniendo detalle" v-if="loading" />
		<div class="container-fluid mw-screen-sm min-h-full d-flex flex-column justify-content-center" v-if="!loading">
			<div class="card">
				<div class="card-header h4 fw-bolder text-center">Esperando pago por <b class="text-monospace">{{ vueNumberFormat(payment.amount, $personalizer.currency.value) }}</b></div>
				<div class="card-body">
					<div class="row gy-5">
						<div class="col-12" v-if="payment?.image">
							<div class="img-qr mx-auto">
								<img :src="payment.image" alt="logo" @load="imageReady = true" v-show="imageReady">
								<Skeletor width="100%" height="100%" v-show="!imageReady" />
							</div>
						</div>
						<div class="col-12 text-center text-warning" v-if="payment?.image">
							<i class="bi bi-phone me-2"></i>Pedile al cliente que escanee el código
						</div>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<button type="button" class="btn btn-secondary" :disabled="submitting" @click="redirectBack">Volver</button>
					<button type="button" class="btn btn-danger" :disabled="submitting" @click="cancel">Cancelar pago</button>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import OrdersRepository from '@/repositories/OrdersRepository';
	import OrderGroupsRepository from '@/repositories/OrderGroupsRepository';
	import composableRedirect from '@/composables/redirect';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { params: { type, id } } = useRoute();
			const { redirectBack } = composableRedirect();
			const { current_store } = composableStore();
			const { submitting, catchError } = composableForm();
			const loading = ref(true);
			const payment = ref(null);
			const imageReady = ref(false);
			const repository = ref(null);

			onMounted(() => {
				switch(type) {
					case 'order':
						repository.value = OrdersRepository;
						break;
					case 'orderGroup':
						repository.value = OrderGroupsRepository;
						break;
				}

				repository.value.paying(current_store.value.id, id).then((response) => {
					loading.value = false;
					payment.value = response.data;
				}).catch(redirectBack);
			});

			const cancel = async () => {
				if(submitting.value) return false;

				submitting.value = true;
				await repository.value.payingCancel(current_store.value.id, id).catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { redirectBack, loading, payment, imageReady, submitting, cancel };
		}
	}
</script>

<style scoped>
	.img-qr {
		aspect-ratio: 1 / 1;
		min-height: 300px;
		max-height: calc(100vh - 370px);
	}
</style>