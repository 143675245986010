import { computed } from 'vue';
import store from '@/store';
import router from '@/router';
import composableStore from '@/composables/store';

export default function() {
	const { current_store } = composableStore();
	const redirectBackInfo = computed(() => store.getters['redirect/back']);

	const redirectBack = () => {
		if(!redirectBackInfo.value && !current_store.value?.id) return router.push({ name: 'select' });
		else if(!redirectBackInfo.value && current_store.value?.id) router.push({ name: 'store.home', params: { store_id: current_store.value.id } });
		else if(redirectBackInfo.value == router.options.history.state.back) return router.go(-1);
		else return router.push({ path: redirectBackInfo.value });
	}

	return { redirectBackInfo, redirectBack };
}