<template>
	<div class="container-fluid mw-screen-sm vstack gap-3 min-h-full">
		<form class="card" @submit.prevent="submit">
			<div class="card-header h4 fw-bolder">Total: {{ vueNumberFormat(total, $personalizer.currency.value) }}</div>
			<div class="card-body">
				<div class="row g-5">
					<div class="col-12">
						<label class="form-label">1. Tipo de cliente</label>
						<div class="card shadow-1 border rounded-2">
							<div class="checkout-option d-flex justify-content-between position-relative">
								<div class="form-check">
									<input type="radio" id="customerType-final" value="final" v-model="customerType" class="form-check-input">
									<div class="form-check-label text-dark d-block ms-1">
										<label for="customerType-final" class="stretched-link text-dark">Consumidor final</label>
									</div>
								</div>
							</div>
							<div class="checkout-option d-flex justify-content-between position-relative">
								<div class="form-check">
									<input type="radio" id="customerType-other" value="other" v-model="customerType" class="form-check-input">
									<div class="form-check-label text-dark d-block ms-1">
										<label for="customerType-other" class="stretched-link text-dark">Especificar</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12" v-if="customerType == 'other'">
						<label class="form-label">2. Datos del cliente</label>
						<div class="row">
							<div class="col-12 mb-3">
								<label class="form-label" for="customerName">Nombre o razón social:</label>
								<input type="text" id="customerName" v-model="customerName" class="form-control" placeholder="Ingrese el nombre o razón social del cliente" v-focus>
							</div>
							<div class="col-12 mb-3">
								<label class="form-label" for="vatCategory">Categoría:</label>
								<select id="vatCategory" v-model="vatCategory" class="form-select">
									<option value="0">Consumidor final</option>
									<option value="1">Empresa</option>
									<option value="2">Exento</option>
								</select>
							</div>
							<div class="col-12">
								<label class="form-label" for="customerIdType">Documento del cliente:</label>
								<div class="row gx-4">
									<div class="col-5">
										<select id="customerIdType" v-model="customerIdType" class="form-select">
											<option value="CI">CI</option>
											<option value="RUC">RUC</option>
										</select>
									</div>
									<div class="col-7">
										<input type="tel" v-model="customerIdNumber" class="form-control" placeholder="Ingrese el número" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer d-flex justify-content-between">
				<button type="button" class="btn btn-secondary" :disabled="submitting" @click="redirectBack">Más tarde</button>
				<button type="submit" class="btn btn-primary" :disabled="submitting">Facturar</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { reactive, toRefs } from 'vue';
	import composableRedirect from '@/composables/redirect';

	export default {
		emits: ['submit'],
		props: {
			billing: Object,
			total: Number,
			submitting: Boolean
		},
		setup(props, { emit }) {
			const { redirectBack } = composableRedirect();

			const form = reactive({
				customerType: 'final',
				customerName: null,
				vatCategory: 0,
				customerIdType: 'CI',
				customerIdNumber: null
			});

			const submit = () => emit('submit', form);

			return { redirectBack, ...toRefs(form), submit };
		}
	}
</script>