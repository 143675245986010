<template>
	<div class="hstack gap-3">
		<user-status-avatar :user="user" size="lg" />
		<div class="flex-fill overflow-hidden">
			<!-- Title -->
			<div class="h4 d-block">{{ user.fullname }}</div>
			<!-- Time -->
			<div class="d-flex justify-content-between text-sm text-muted mb-1">
				<div class="fw-bold text-muted text-truncate">{{ user.email }}</div>
				<div class="hstack gap-3 ms-2" style="cursor: help;">
					<div class="text-success" data-bs-toggle="tooltip" title="Compras exitosas">{{ user.orders_resume.success.count }}<i class="bi bi-hand-thumbs-up-fill ms-1"></i></div>
					<div class="text-danger" data-bs-toggle="tooltip" title="Compras falsas">{{ user.orders_resume.false_order.count }}<i class="bi bi-hand-thumbs-down-fill ms-1"></i></div>
				</div>
			</div>
			<!-- Progress -->
			<div class="progress progress-sm mb-1">
				<div class="progress-bar" :class="barClass" role="progressbar" :style="{ width: `${ user.confidence * 10 }%` }" :aria-valuenow="user.confidence * 10" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, onMounted } from 'vue';
	import { Tooltip } from 'bootstrap';

	export default {
		props: {
			user: Object
		},
		setup(props) {
			const barClass = computed(() => {
				if(props.user.confidence >= 7) return 'bg-success';
				else if(props.user.confidence >= 5) return 'bg-warning';
				else return 'bg-danger';
			});

			onMounted(() => {
				var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
				tooltipTriggerList.map(function (tooltipTriggerEl) { return new Tooltip(tooltipTriggerEl) });
			});

			return { barClass };
		}
	}
</script>