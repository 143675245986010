<template>
	<div class="overlay position-absolute w-100 h-100 rounded-3">
		<div class="d-flex justify-content-center h-100">  
			<div class="spinner-grow text-primary m-auto" role="status"></div>
		</div>
	</div>
</template>

<style scoped>
	.overlay {
		z-index: 1;
		background-color:rgba(0, 0, 0, 0.5);
	}

	.spinner-grow {
		width: 3rem;
		height: 3rem;
	}
</style>