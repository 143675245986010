<template>
	<cashbox-header-index @reload="getData" />
	<!-- Main -->
	<main class="py-5 flex-grow-1">
		<layouts-loading text="Obteniendo detalle" v-if="loading" />
		<!-- Container -->
		<component :is="viewMain" :cashbox="cashbox" @reload="getData" v-if="!loading" />
	</main>
</template>

<script>
	import { ref, computed, onMounted, onUnmounted } from 'vue';
	import router from '@/router';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import CashboxRepository from '@/repositories/CashboxRepository';

	export default {
		setup() {
			const { current_store } = composableStore();
			const { catchError } = composableForm();

			const interval = ref(null);
			const loading = ref(true);
			const cashbox = ref(null);

			const getData = async () => {
				loading.value = true;

				await CashboxRepository.index(current_store.value.id)
					.then((data) => {
						loading.value = false;
						cashbox.value = data.data;
					})
					.catch((e) => {
						catchError(e, true);
						if(e.response.status == 307) router.push({ name: 'store.cashbox.settings', params: { store_id: current_store.value.id } });
					});
			}

			const viewMain = computed(() => {
				if(cashbox.value?.id) return 'cashbox-detail';
				else return 'cashbox-open';
			});

			onMounted(() => {
				getData();
				interval.value = setInterval(getData, (600 * 1000));
			});

			onUnmounted(() => {
				if(interval.value) clearInterval(interval.value);
			});

			return { loading, cashbox, getData, viewMain };
		}
	}
</script>