<template>
	<!-- Vertical Bar -->
	<div class="navbar bg-dark d-none d-lg-flex flex-column flex-nowrap align-items-center h-100 p-2 gap-2">
		<!-- Brand -->
		<a href="javascript:void(0);" class="d-none d-md-block pb-3" data-bs-toggle="offcanvas" data-bs-target="#common-menu-store">
			<div class="avatar w-100 h-auto border border-2 rounded-circle" :class="{ 'bg-primary border-primary': open, 'bg-danger border-danger': !open }" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Estado de la tienda">
				<img :src="logo_default" class="border border-2 rounded-circle border-dark" @load="imageReady = true" alt="Avatar" v-show="imageReady">
				<Skeletor width="60" height="60" class="rounded-circle" v-show="!imageReady" />
			</div>
		</a>
		<ul class="nav w-100 flex-row flex-md-column flex-none justify-content-between justify-content-md-center gap-2 text-center py-3 py-lg-0">
			<li class="nav-item">
				<router-link :to="{ name: 'store.home', params: { store_id: current_store.id } }" class="nav-link" :class="{'active': (route.name == 'store.home')}" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Inicio">
					<i class="bi bi-speedometer"></i>
				</router-link>
			</li>
			<li guide="common-menu-shortcuts-button-orders-kanban" class="nav-item" v-if="isAdmin">
				<router-link :to="{ name: 'store.orders.kanban', params: { store_id: current_store.id } }" class="nav-link" :class="{'active': (route.name == 'store.orders.kanban'), 'pulse': (stepCurrent?.id == 'common-menu-shortcuts-button-orders-kanban')}" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Tablero de pedidos">
					<i class="bi bi-kanban"></i>
				</router-link>
			</li>
		</ul>
		<ul guide="common-menu-shortcuts-block-codes" class="nav w-100 flex-row flex-md-column flex-none justify-content-between justify-content-md-center gap-2 text-center py-3 py-lg-0 mt-auto" v-if="!isPlanFree && (maps.length > 0 || codes.length > 0)">
			<li class="nav-item" v-if="!isPlanFree && maps.length > 0">
				<router-link :to="{ name: 'store.maps.index', params: { store_id: current_store.id } }" class="nav-link" :class="{'active': (route.name == 'store.maps.index'), 'pulse': (stepCurrent?.id == 'common-menu-shortcuts-block-codes')}" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Mapas">
					<i class="bi bi-map"></i>
				</router-link>
			</li>
			<li class="nav-item" v-if="!isPlanFree && codes.length > 0">
				<router-link :to="{ name: 'store.codes.index', params: { store_id: current_store.id } }" class="nav-link" :class="{'active': (route.name == 'store.codes.index'), 'pulse': (stepCurrent?.id == 'common-menu-shortcuts-block-codes')}" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Códigos">
					<i class="bi bi-qr-code"></i>
				</router-link>
			</li>
		</ul>
		<ul guide="common-menu-shortcuts-block-order" class="nav w-100 flex-row flex-md-column flex-none justify-content-between justify-content-md-center gap-2 text-center py-3 py-lg-0 mt-auto" v-if="!isPlanFree">
			<li class="nav-item" v-if="codeDefault && typeLocal">
				<a href="javascript:void(0)" class="nav-link" :class="{'active': (route.meta?.needCart && type?.option == 'LOCAL' && !!code && code?.id == codeDefault?.id), 'pulse': (stepCurrent?.id == 'common-menu-shortcuts-block-order')}" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Pedido en el local" @click="selectCodeDefault">
					<i class="bi bi-shop"></i>
				</a>
			</li>
			<li class="nav-item" v-if="typeTakeaway">
				<a href="javascript:void(0)" class="nav-link" :class="{'active': (route.meta?.needCart && type?.option == 'TAKEAWAY'), 'pulse': (stepCurrent?.id == 'common-menu-shortcuts-block-order')}" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Pedido take away" @click="selectTakeaway">
					<i class="bi bi-handbag"></i>
				</a>
			</li>
			<li class="nav-item" v-if="typeDelivery">
				<a href="javascript:void(0)" class="nav-link" :class="{'active': (route.name == 'store.cart.address' || (route.meta?.needCart && type?.option == 'DELIVERY')), 'pulse': (stepCurrent?.id == 'common-menu-shortcuts-block-order')}" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Pedido delivery" @click="redirectDelivery">
					<i class="bi bi-bicycle"></i>
				</a>
			</li>
		</ul>
		<!-- Push content down -->
		<div class="mt-auto"></div>
		<ul class="nav w-100 flex-row flex-md-column flex-none justify-content-between justify-content-md-center gap-2 text-center py-3 py-lg-0">
			<li guide="common-menu-shortcuts-button-guide" class="nav-item">
				<a href="javascript:void(0);" class="nav-link" :class="{'pulse': (stepCurrent?.id == 'common-menu-shortcuts-button-guide')}" @click="guide.guideModal();" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Guía en pantalla">
					<i class="bi bi-info-circle"></i>
				</a>
			</li>
			<li guide="common-menu-shortcuts-button-cashbox" class="nav-item" v-if="!isPlanFree && isAdmin">
				<router-link :to="{ name: 'store.cashbox.index', params: { store_id: current_store.id } }" class="nav-link" :class="{'active': (route.name == 'store.cashbox.index'), 'pulse': (stepCurrent?.id == 'common-menu-shortcuts-button-cashbox')}" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Caja">
					<i class="bi bi-inbox"></i>
				</router-link>
			</li>
			<li guide="common-menu-shortcuts-button-config" class="nav-item" v-if="isAdmin">
				<router-link :to="{ name: 'store.config.index', params: { store_id: current_store.id } }" class="nav-link" :class="{'active': (route.name == 'store.config.index'), 'pulse': (stepCurrent?.id == 'common-menu-shortcuts-button-config')}" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Configuración">
					<i class="bi bi-gear"></i>
				</router-link>
			</li>
			<li class="nav-item">
				<a href="javascript:void(0);" class="nav-link" @click="userMenu" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-title="Usuario">
					<i class="bi bi-person"></i>
				</a>
			</li>
		</ul>
	</div>

	<guide-index ref="guide" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import { Modal, Tooltip } from 'bootstrap';
	import { stepCurrent } from '@/helpers/Guide';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { current_store, logo_default, isPlanFree, isAdmin, status, codes, codeDefault, maps } = composableStore();
			const { type, code, selectCodeDefault, selectTakeaway, redirectDelivery, redirectCamera, redirectCode, redirectMap } = composableCart();
			const route = useRoute();

			const imageReady = ref(false);
			const guide = ref(null);

			const open = computed(() => status.value?.open);

			const services = computed(() => (status.value?.type?.inmediate?.services ?? []));
			const typeLocal = computed(() => ('LOCAL' in services.value));
			const typeTakeaway = computed(() => ('TAKEAWAY' in services.value));
			const typeDelivery = computed(() => ('DELIVERY' in services.value));

			const userMenu = () => {
				var modalScreenSaver = Modal.getInstance(document.getElementById('common-menu-user'));
				modalScreenSaver.show();
			}

			onMounted(() => {
				var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
				tooltipTriggerList.map(function (tooltipTriggerEl) { return new Tooltip(tooltipTriggerEl) });
			});

			return { current_store, logo_default, isPlanFree, isAdmin, imageReady, open, route, codes, maps, codeDefault, type, code, typeLocal, typeTakeaway, typeDelivery, selectCodeDefault, selectTakeaway, redirectDelivery, redirectCamera, redirectCode, redirectMap, userMenu, guide, stepCurrent };
		}
	}
</script>

<style scoped>
	.navbar {
		min-width: 5rem;
		width: 5rem;
		overflow-x: hidden;
	}

	.navbar::-webkit-scrollbar {
		width: 0px;
	}

	.navbar-brand img {
		height: 2rem !important;
	}

	.nav-link {
		font-size: 1.25rem !important;
		color: white;
	}

	.nav-item:has(.active) {
		background-color: #00ff00;
		border-radius: 0.375rem !important;
	}

	.nav-item:has(.active) .nav-link {
		color: #000;
	}

	.pulse i {
		animation: pulse 1s infinite alternate; /* Se aplica la animación */
	}

	@keyframes pulse {
		0% {
			color: inherit; /* Color inicial */
		}

		100% {
			color: #FF8C00; /* Color final */
		}
	}
</style>
