<template>
	<div class="bg-white d-flex align-items-center position-relative p-4 rounded-3 shadow fw-semibold text-heading" role="alert">
		<div class="d-flex align-items-center">
			<div class="icon icon-sm icon-shape bg-danger text-white rounded-circle">
				<i class="bi bi-exclamation-lg"></i>
			</div>
			<div class="ms-3">
				<div class="d-block">{{ text }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			text: String
		}
	}
</script>