<template>
	<teleport to="body">
		<div class="modal" id="common-menu-user" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-sm modal-dialog-centered">
				<div class="modal-content shadow-3">
					<div class="modal-header d-block px-5">
						<user-card-main :user="user" />
					</div>
					<div class="modal-body p-0 list-group list-group-flush">
						<router-link :to="{ name: 'store.user.settings.general', params: { store_id: current_store.id } }" class="list-group-item fw-bold text-center link-dark">Editar perfil</router-link>
						<router-link :to="{ name: 'select' }" class="list-group-item fw-bold text-center link-dark">Listado de tiendas</router-link>
						<a href="javascript:void(0)" class="list-group-item text-center link-danger" @click="logout">Cerrar sesión</a>
						<button type="button" class="list-group-item link-warning" data-bs-dismiss="modal">Cancelar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import composableStore from '@/composables/store';

	export default {
		props: {
			user: Object
		},
		setup() {
			const { current_store } = composableStore();
			const logout = () => {
				modalMenu.value.hide();
				store.dispatch('auth/logout');
			}

			const modalMenu = ref(null);
			onMounted(() => {
				modalMenu.value = new Modal(document.getElementById('common-menu-user'));
			});

			return { current_store, logout };
		}
	}
</script>