<template>
	<teleport to="body">
		<header class="header fixed-top border-bottom border-2 border-dashed border-dark bg-primary" :class="{ 'bg-primary': hover, 'bg-light bg-opacity-50': !hover }">
			<div class="container-fluid p-2">
				<div class="py-6 text-center" @drop="drop" @dragover="dragover" @dragleave="dragleave">
					<span class="fw-semibold h3" :class="{ 'text-white': hover, 'text-dark': !hover }">Arrastrar aquí para finalizar</span>
				</div>
			</div>
		</header>
	</teleport>
</template>

<script>
	import { ref } from 'vue';
	import store from '@/store';

	export default {
		emits: ['setdrag'],
		setup(props, { emit }) {
			const hover = ref(false);

			const drop = (e) => {
				e.preventDefault();
				hover.value = false;
				setdrag(false);
				var id = e.dataTransfer.getData('id');

				store.dispatch('orders/updateStatus', { id: id, newStatus: 'COMPLETE' });
			}

			const dragover = (e) => {
				e.preventDefault();
				hover.value = true;
			}

			const dragleave = (e) => {
				e.preventDefault();
				hover.value = false;
			}

			const setdrag = (data) => emit('setdrag', data);

			return { drop, hover, dragover, dragleave, setdrag }
		}
	}
</script>