<template>
	<teleport to="body">
		<div class="modal" id="payouts-modal-index" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md">
				<div class="modal-content shadow-3">
					<div class="modal-header">
						<h4 class="modal-title">Rendiciones de dinero</h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body py-0">
						<common-message-error class="col-12 my-5" text="No se encontraron rendiciones de dinero." v-if="list.length == 0" />
						<div class="row">
							<div class="col-12 px-0">
								<div class="list-group list-group-flush">
									<payouts-row v-for="payout in list" v-bind:key="payout.id" :payout="payout" />
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { computed, onMounted } from 'vue';
	import _ from 'lodash';
	import store from '@/store';
	import { Modal } from 'bootstrap';

	export default {
		setup() {
			const list = computed(() => _.filter(store.getters['payouts/list'], { status: 'PENDING' }));

			onMounted(() => new Modal(document.getElementById('payouts-modal-index')));

			return { list };
		}
	}
</script>