<template>
	<layouts-basic>
		<div class="row">
			<div class="col-12 mb-5">
				<user-card-logged />
			</div>
		</div>
		<div class="row">
			<div class="col-12 mb-5">
				<hr class="border-top border-dashed my-0" />
			</div>
		</div>
		<div class="row" v-if="loading">
			<div class="col-12 mb-5">
				<Skeletor height="78" class="card border-1 border-lighter shadow" />
			</div>
			<div class="col-12 mb-5">
				<Skeletor height="78" class="card border-1 border-lighter shadow" />
			</div>
		</div>
		<div class="row" v-if="!loading && invitations.length > 0">
			<div class="col-12 mb-5" v-for="invitation in invitations" v-bind:key="invitation.id">
				<invitation-card :invitation="invitation" @loadStores="loadStores" />
			</div>
		</div>
		<div class="row" v-if="!loading && stores.length > 0">
			<div class="col-12 mb-5" v-for="store in stores" v-bind:key="store.id">
				<store-card :store="store" />
			</div>
		</div>
		<div class="row" v-if="!loading && stores.length == 0">
			<div class="col-12">
				<common-message-error text="No se encontraron tiendas." />
			</div>
		</div>
	</layouts-basic>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import composableForm from '@/composables/form';
	import StoresRepository from '@/repositories/StoresRepository';

	export default {
		setup() {
			const { catchError } = composableForm();
			const loading = ref(true);
			const invitations = ref([]);
			const stores = ref([]);

			const loadStores = () => {
				loading.value = true;
				StoresRepository.index().then((response) => {
					loading.value = false;
					invitations.value = response.data.invitations;
					stores.value = response.data.stores;
				}).catch(catchError);
			}

			onMounted(() => loadStores());

			return { loadStores, loading, invitations, stores };
		}
	}
</script>