<template>
	<label class="form-label d-flex justify-content-between">
		Forma de pago
		<a href="javascript:void(0)" class="text-danger" @click="setPayments(null)" v-if="payment && !needPayment">Quitar pagos</a>
	</label>
	<div class="card shadow-1 border rounded-2">
		<div class="checkout-option d-flex justify-content-between position-relative" v-for="payment_method in current_store.payment_methods" v-bind:key="payment_method.type">
			<div class="form-check">
				<input type="radio" v-model="option" :value="payment_method.type" class="form-check-input" :disabled="submitting">
				<div class="form-check-label text-dark d-block ms-1">
					<a href="javascript:void(0)" class="stretched-link text-dark" @click="selectOption(payment_method)">{{ payment_method.title }}</a>
					<small class="d-block" v-if="option == payment_method.type && description" v-html="description"></small>
				</div>
			</div>
			<a href="javascript:void(0)" class="text-dark stretched-link position-relative" v-if="option == payment_method.type && option == 'cash'" @click="menuCashShow">Editar</a>
		</div>
	</div>

	<cart-checkout-menu-cash @setCash="setCash" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import composableStore from '@/composables/store';
	import composableCart from '@/composables/cart';
	import { Offcanvas } from 'bootstrap';

	export default {
		props: {
			submitting: Boolean
		},
		setup(props) {
			const { current_store } = composableStore();
			const { payment, setPayments, needPayment } = composableCart();

			const option = computed(() => {
				if(!payment.value) return null;
				else return payment.value.option;
			});

			const selectOption = (payment_method) => {
				if(props.submitting) return;
				var data = { option: payment_method.type };

				switch(payment_method.type) {
					case 'cash':
						data.type = 'exact';
				}

				setPayments(data);
			}

			const description = computed(() => {
				var description = null;

				switch(payment.value.option) {
					case 'cash':
						switch(payment.value?.type) {
							case 'exact': description = `Paga <b>con importe exacto</b>`; break;
							case 'other': description = `Paga <b>con $ ${payment.value.amount}</b>`; break;
						}
						break;
				}

				return description;
			});

			const menuCash = ref(null);
			onMounted(() => menuCash.value = new Offcanvas(document.getElementById('menu-cash')));

			const menuCashShow = () => {
				if(props.submitting) return;
				menuCash.value.show();
			}

			const setCash = (data) => {
				setPayments(data);
				menuCash.value.hide();
			}

			return { current_store, payment, option, setPayments, selectOption, needPayment, description, menuCashShow, setCash };
		}
	}
</script>