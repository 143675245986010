<template>
	<div guide="config-sound-card" class="card">
		<div class="card-body pb-0">
			<div class="mb-3 d-flex justify-content-between align-items-center">
				<div>
					<h4>Alertas sonoras</h4>
					<p class="text-sm text-muted">¿Querés recibir las alertas sonoras?</p>
				</div>
				<div class="icon icon-shape bg-secondary text-white text-lg rounded-circle">
					<i class="bi bi-bell"></i>
				</div>
			</div>
			<div class="list-group list-group-flush">
				<div class="list-group-item d-flex align-items-center px-0">
					<div class="flex-fill">
						<span class="d-block h6 fw-bold mb-1"><i class="bi bi-volume-mute me-1"></i>Nunca</span>
						<span class="d-block text-sm">No se emitirán alertas sonoras</span>
					</div>
					<div class="ms-auto text-end ps-5">
						<a href="javascript:void(0)" class="btn btn-sm" :class="{ 'btn-neutral': (sound != null), 'btn-primary': (sound == null) }" @click="setSound(null)">{{ (sound == null) ? 'Seleccionado' : 'Seleccionar' }}</a>
					</div>
				</div>
				<div class="list-group-item d-flex align-items-center px-0">
					<div class="flex-fill">
						<span class="d-block h6 fw-bold mb-1"><i class="bi bi-volume-up me-1"></i>Sólo una vez</span>
						<span class="d-block text-sm">Sonará una vez cuando exista un pedido pendiente de aprobación</span>
					</div>
					<div class="ms-auto text-end ps-5">
						<a href="javascript:void(0)" class="btn btn-sm" :class="{ 'btn-neutral': (sound != 'once'), 'btn-primary': (sound == 'once') }" @click="setSound('once')">{{ (sound == 'once') ? 'Seleccionado' : 'Seleccionar' }}</a>
					</div>
				</div>
				<div class="list-group-item d-flex align-items-center px-0">
					<div class="flex-fill">
						<span class="d-block h6 fw-bold mb-1"><i class="bi bi-volume-up-fill me-1"></i>Constante</span>
						<span class="d-block text-sm">Sonará constantemente cuando existan pedidos pendientes</span>
					</div>
					<div class="ms-auto text-end ps-5">
						<a href="javascript:void(0)" class="btn btn-sm" :class="{ 'btn-neutral': (sound != 'always'), 'btn-primary': (sound == 'always') }" @click="setSound('always')">{{ (sound == 'always') ? 'Seleccionado' : 'Seleccionar' }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const sound = computed(() => store.getters['config/sound']);
			const setSound = (data) => store.dispatch('config/setSound', data);

			return { sound, setSound };
		}
	}
</script>